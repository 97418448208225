import React from "react";

import { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch, HashRouter } from "react-router-dom";
const supportsHistory = "pushState" in window.history;

import Login from "../pages/login";
import ChangePwd from "../pages/login/changepwd";
import Modulos from "../pages/modulos";

import PrivateRoute from "./PrivateRoute";

const Main = () => {
    return (
        <Router forceRefresh={!supportsHistory}>
            <Fragment>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route path="/login" component={Login} />
                    <Route path="/changepwd" component={ChangePwd} />
                    <PrivateRoute component={Modulos} />
                </Switch>
            </Fragment>
        </Router>
    );
};

export default Main;
