import React from "react";

const MainContainer = (props) => {
    return (
        <div className="app-main">
            { props.children }
        </div>
    );
};

MainContainer.displayName = "MainContainer";

export default MainContainer;
