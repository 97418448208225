import React, { Fragment as div } from "react";
import moment from 'moment';
import { NavLink } from "../../../../../lib/controls";

const Columns = (onEditar, onEliminar) => {

  const defColumns = [
    {
      name: 'Codigo',
      selector: 'codEmpresa',
      sortable: true,
    },
    {
      name: 'Descripción',
      selector: 'descripEmpresa',
      sortable: true,
    },
    {
      name: 'Perfil Web',
      selector: 'codPerfilweb',
      cell: row => {
          if(row.codPerfilweb==="ED") {
            return <div className="mb-2 mr-2 badge ">{'Gestor'}</div>
          } else if(row.codPerfilweb==="VE") {
            return <div className="mb-2 mr-2 badge badge-">{'Gestor Externo'}</div>
          } else if(row.codPerfilweb==="PA") {
            return <div className="mb-2 mr-2 badge badge-">{'Participante Autorizado'}</div>
          }
      },      
      sortable: true,
    },
    {
      name: 'Acción',
      allowOverflow: true,
      cell: (row) =>  {
        return ( //ActionButton()
          <div>
            <NavLink link='/gestor/cfg/pa/editor' 
                      icon='fa fa-edit' 
                      success sm
                      data={{ id:row.codEmpresa, action: 'E' }}> Editar</NavLink>{' '}

            {/* <Button onClick={ () => {
              if (onEliminar){
                onEliminar(row.codEmpresa, row);
              }
            }} icon='fa fa-trash' sm danger noshadow> eliminar</Button> */}
          </div> 
        );
      },
      button: false,
    }    
  ];

  return defColumns;
}

export default Columns;

//<Button icon="fa fa-edit"  sm noshadow onClick={() => onEditar(row.codUnico)}></Button>{' '}
//<Button icon="fa fa-trash" sm noshadow danger onClick={() => onEliminar(row.codUnico)}></Button>
