import React from "react";
import { useRef } from "react";
import SearchContainer from "./searchcontainer";
import DropdownList from "./dropdownlist";
import Button from '../../button';

const Search = props => {
    const { searchList, onSearch } = props;
    const _selectedSearch = useRef();
    const _textSearch = useRef();

    const _onButtonSearch = () => {
        const selected = _selectedSearch.current.getSelected();
        if (onSearch){
            onSearch(selected.id, selected.text, _textSearch.current.value);
            _textSearch.current.value = "";
        }
    };

    const _onKeyPress = (e) => {
        if (event.which == 13 || event.keyCode == 13) {
            _onButtonSearch();
        }
        return true;
    };

    return ( 
        <SearchContainer>
                <div className="input-group-prepend">
                    <DropdownList list={searchList} ref={ _selectedSearch }></DropdownList>
                </div>
                <input type="text" 
                       placeholder="Buscar ..." 
                       className="form-control" 
                       onKeyPress={ _onKeyPress }
                       ref={ _textSearch }></input>
                <div className="input-group-append">
                    <Button icon="fa fa-search" 
                            tooltip="Buscar"
                            onClick={ _onButtonSearch }></Button>
                </div>
        </SearchContainer>
    );
};

Search.displayName = "Search";

export default Search;