import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

const NotFound = (props) => {
    let location = useLocation();

    return (
        <Fragment>
            <h4>Página no existe</h4>
            <Link to="/">Ir al Inicio</Link>
        </Fragment>
    );
};
// Add al final de todos los privateroute. (Status, componente que muestra que la url no existe)
//<PrivateRoute path="*" component={Status} />

NotFound.displayName = "NotFound";

export default NotFound;
