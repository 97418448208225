import React, { Fragment, useState, useEffect, useRef } from "react";
import { PageHeader, PageContent, PanelToolbar, NavLinkAdd, NavLink,
         PanelBodyList, Search, Button, DataTable, Confirm} from '../../../../../lib/controls';
import Action   from '../../../../../lib/flux/actions/actionusuario';
import DataStore from '../../../flux/storeusuario';
import Columns from './columns';
import moment from 'moment';
import { FORMAT_INPUTDATE } from '../../../../../config';
import { getAuthInfo }      from '../../../../../lib/storage';

import { Col, Row, Form } from 'reactstrap';
import { EditInput, ValidateBlock, SelectInput, RowCol } from '../../../../../lib/controls';

const getStateFromStore = () => ({
               List : DataStore.getList(),
             result : DataStore.getResult(),
            loading : DataStore.getLoading(),
               exit : DataStore.getExit()
});

const ConfiguracionUsuarioIndex = (props) => {
    const [store, setStore] = useState( getStateFromStore() );
    const {perfil, perfil_web, codempresa, nomempresa, iduser} = getAuthInfo();

    //Suscribe evento luego de montar todos los componentes.
    useEffect(() => {
       DataStore.addChangeListener( _onStoreChange );
        return () => {
            DataStore.clear();
            DataStore.removeChangeListener( _onStoreChange ) ;
        } 
    }, []);

    //Carga de clientes luego de montar todos los componentes.
    useEffect(() => {
      _onLoadData();
    }, []);    

    //Luego de montar y actualizar el componente, resetea mensaje sólo si estos cambian.
    useEffect(() => {
        store.result.iserror = false;
        store.result.message = null;
    }, [store.result.message, store.result.iserror]);

    const _onStoreChange = () => {
        setStore( getStateFromStore() );
    };

    const _onLoadData = () => {
        Action.search();
        Action.list();
    };

    const _pageName = "Usuarios";
    return (
        <Fragment>
            <PageHeader title={_pageName} 
                        subtitle=""
                        icon="pe-7s-users"
                        breadcrumb={[
                            {url:'/gestor/dashboard',text:'Inicio', icon:'fa fa-home'},
                            {text:'Usuario'}
                        ]}>
                <PageContent pageName= { _pageName }
                             loading={store.loading} 
                             message={store.result.message} 
                             iserror={store.result.iserror}>
                    <PanelToolbar>

                        <div className="pane-left">
                        </div>
                        <div className="pane-right">
                            <Row>
                                <Col>
                                    <NavLink link='/gestor/cfg/usuario/editor' 
                                             icon='fa fa-plus' 
                                             info sm addClass="btn-block"
                                             data={{ id:null }}> Agregar</NavLink>

                                </Col>
                            </Row>
                        </div> 

                    </PanelToolbar>
                    <PanelBodyList>
                        <DataTable columns={ Columns() } 
                                    data={store.List} className="shadow">
                        </DataTable>
                    </PanelBodyList>
                </PageContent>
            </PageHeader>
        </Fragment>
    );
};

ConfiguracionUsuarioIndex.displayName = "ConfiguracionUsuarioIndex";

export default ConfiguracionUsuarioIndex;