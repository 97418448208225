import React, { Fragment, useRef, useState,  useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ShowMessage = (props) => {
    const { title, children, textok, colorok, 
            onClickOk, className, onClosed } = props;
    const [modal, setModal] = useState(true);
    const toggle = () => setModal(!modal);

    const onOk = () => {
        toggle();
        if (onClickOk) {
            onClickOk();
        }
    };

    const _onClosed = () => {
        setModal(false);
        if (onClosed) {
            onClosed();
        }
    };

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} className={className} onClosed={ _onClosed }>
                <ModalHeader toggle={toggle}>{ title }</ModalHeader>
                <ModalBody>
                        { children }                    
                </ModalBody>
                <ModalFooter>
                    <Button color={colorok}   onClick={onOk}>{ textok }</Button>
                </ModalFooter>
            </Modal>
        </div>
    );

};


ShowMessage.propTypes = {
    textok : PropTypes.string,
    colorok: PropTypes.string,
};

ShowMessage.defaultProps = {
    textok: 'Cerrar',
    colorok: 'primary',
};

ShowMessage.displayName = "ShowMessage";

export default ShowMessage;