import EventEmitter  from 'events';
import { setAuthInfo, setAllFondos } from '../../../lib/storage';
import AppDispatcher from '../../../lib/flux/dispatcher';
import { ActionTypes }   from '../../../lib/flux/types/actiontypes_login';
import { ResultType, ResultOk } from '../../../lib/flux/types/resulttype'; 
import ApiSeguridad  from './api';

const CHANGE_EVENT = 'change';

let _Fondos = [];
let _usuarioSistema = null;
let _administradora = null;
let _parametrosGenerales = null
let _result = Object.assign({}, ResultType);
let _loading = false;
let _authenticated = false;
let _changed = false;
let _perfilWeb = ''; //AP, ED, VE

class storeLogin extends EventEmitter {
    constructor() {
        super();
        AppDispatcher.register(this.registerActions.bind(this));
    }

    registerActions(action) {
        switch (action.type) {

            case ActionTypes.CLEAR:
                this.setResult(false, null);
                this.emitChange();
                break;

            case ActionTypes.SEARCH:
                this.setResult(false, null);
                this.setLoading(true);
                this.emitChange();
                break;

            case ActionTypes.CHNGPWD:
                console.log(action.data);
                const { username0, password0 } = action.data;

                ApiSeguridad.setChangePwd(username0, password0, (response) =>{
                    if (response.code !== "0") {
                        //error in parameters (Code > 0)
                        this.setResult(true, response.errorCode + " - " + response.errorMessage);
                    } else {
                        this.setChanged(true);
                        this.setResult(true, response.data);
                    }
                }, (error) => {
                    //error
                    this.setResult(true, error);
                }, () => {
                    //always
                    this.setLoading(false);
                    this.emitChange();
                });
                break;
            case ActionTypes.LOGIN:
                const { username, password } = action.data;

                //ApiSeguridad.getAuthorization(username, password, (response) =>{
                ApiSeguridad.getAuthorization(username, password, (response) =>{
                    //success
                    console.log(response);
                    if (response.code !== "0") {
                        //error in parameters (Code > 0)
                        this.setResult(true, response.errorCode + " - " + response.errorMessage);
                    } else {
                        //const {usuarioSistema, administradora, listFondo, ParametrosGenerales} = response.data;                

                        this.setAuthenticated(true);
                        this.setUsuarioSistema(response.data);
                        //this.setFondos(listFondo);
                        //this.setAdministradora(administradora);
                        //this.setParametrosGenerales(ParametrosGenerales);
                        
                        this.setResult(false, ResultOk);
                    }
                }, (error) => {
                    //error
                    this.setResult(true, error);
                }, () => {
                    //always
                    this.setLoading(false);
                    this.emitChange();
                });
                break;
        }

        return true;
    }

    emitChange() {
        this.emit(CHANGE_EVENT);
    }
    
    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    setResult(iserror, message) { 
        _result = {
            iserror: iserror, 
            message: message
        } 
    }
    getResult() { return _result; }
      
    setLoading(loading) { 
        _loading = loading; 
    }
    getLoading() { 
        return _loading; 
    }

    // setFondos(fondos) {
    //     const onlyNames = fondos.map(({CodFondo, DescripFondo}) => ({ CodFondo, DescripFondo}));
    //     setAllFondos(onlyNames);
    //     _Fondos = fondos;
    // }
    // getFondos() {
    //     return _Fondos;
    // }

    setUsuarioSistema(usrInfo) {
        const {codUsuario, username, nombre, token, codPerfil, correo, codPerfilweb, codEmpresa, descripEmpresa} = usrInfo;
        console.info(usrInfo);
        this.setPerfilWeb(codPerfilweb);

        setAuthInfo(codUsuario,
                    username,
                    nombre, 
                    this.getAuthenticated(),
                    token, 
                    null,
                    null,
                    0,
                    codPerfil,
                    correo,
                    codPerfilweb,
                    codEmpresa,
                    descripEmpresa)
        _usuarioSistema = usrInfo;
    }
    getUsuarioSistema() {
        return _usuarioSistema;
    }

    // setAdministradora(administradora) {
    //     _administradora = administradora;
    // }
    // getAdministradora() {
    //     return _administradora;
    // }

    // setParametrosGenerales(parametrosgenerales) {
    //     _parametrosGenerales = parametrosgenerales;
    // }
    // getParametrosGenerales() {
    //     return _parametrosGenerales;
    // }

    setAuthenticated(auth) {
        _authenticated = auth;
    }
    getAuthenticated() {
        return _authenticated;
    }

    setChanged(change) {
        _changed = change;
    }

    getChanged() {
        return _changed;
    }

    setPerfilWeb(perfil) {
        _perfilWeb = perfil;
    }
    getPerfilWeb() {
        return _perfilWeb;
    }
}

export default new storeLogin();