import React from "react";
import PropTypes from "prop-types";
import { useState, useEffect, Fragment } from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";

const NavHeaderMenu = (props) => {
    const [active, setActive] = useState(false);

    const _onClick = () => {
        let _active = !active;
        setActive(_active);
        if(props.onClick) {
            props.onClick(_active);
        }
    }

    let _class = "btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav" + (active? " active": "");
    return (
            <div className="app-header__menu">
                <span>
                    <button type="button" 
                            className={ _class }
                            onClick={ _onClick }>
                        <span className="btn-icon-wrapper">
                            <i className="fa fa-ellipsis-v fa-w-6"></i>
                        </span>
                    </button>
                </span>
            </div>
    );
};

NavHeaderMenu.displayName = "NavHeaderMenu";

export default NavHeaderMenu;
