import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router";
import { LoginContainer, Dialog, HeaderPwd, FooterPwd } from './logincontainer';
import Action   from '../../lib/flux/actions/actionlogin';
import Store from './flux/store';

const getStateFromStore = () => ({
             Changed : Store.getChanged(), 
             result  : Store.getResult(),
            loading  : Store.getLoading()
});

const ChangePwd = () => {
    const [state, setState] = useState( getStateFromStore() );
    const [regresar, setRegresar] = useState(false);
    const _username = useRef();
    const _password = useRef();

    useEffect(() => {
        Store.addChangeListener( _onStoreChange );
 
         return () => {
             Store.removeChangeListener( _onStoreChange ) ;
         } 
     }, []);    

    const _onStoreChange = () => {
        setState( getStateFromStore() );
    };

    const _onClickRegresar = () => {
        setRegresar(true);
    };

    const _onClickChange = (e) => {
        Action.search();
        let dataLogin = {
            username0: _username.current.value,
            password0: _password.current.value
        }
        Action.changepwd(dataLogin);
    };

    if (regresar) {
        return <Redirect to={'/login'} />;
    };

    if (state.Changed) {
        _username.current.value = "";
        _password.current.value = "";        
    }

    return (  
        <LoginContainer>
                <Dialog loading={state.loading}
                        iserror={state.result.iserror}
                        message={state.result.message}>
                    <div className="modal-body mx-4">
                        <HeaderPwd></HeaderPwd>

                        <div className="form-row pt-3">
                            <div className="col-md-12">
                                <div className="position-relative form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="btn btn-info"><i className="fa fa-user"></i></span>
                                        </div>
                                        <input ref={_username} name="user" id="user" placeholder="usuario..." type="text" 
                                            className="form-control" style={{"backgroundColor" : "#F9FEA4"}} maxLength="15" autoComplete="off"></input>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="position-relative form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="btn btn-info"><i className="fa fa-lock"></i></span>
                                        </div>
                                        <input ref={_password} name="password" id="password" placeholder="contraseña actual" type="password" 
                                            className="form-control" style={{"backgroundColor" : "#F9FEA4"}} maxLength="15" autoComplete="off"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="divider row"></div>
                        <div className="position-relative form-check">
                            {/* <input name="check" id="check" type="checkbox" className="form-check-input"></input>
                            <label className="form-check-label"><small>Mantenerme conectado</small></label> */}
                        </div>
                        
                    </div>
                    <FooterPwd onClickChange={ _onClickChange } onClickRegresar={ _onClickRegresar } changed={state.Changed}>
                    </FooterPwd>
                </Dialog>

        </LoginContainer>
  );
};

export default ChangePwd;