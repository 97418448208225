const _actionTypes = {
    SEARCH  : 'SEARCH_USUARIO',   //Search button
    LIST    : 'LIST_USUARIO',     //load list
    ADD     : 'ADD_USUARIO',      //button add
    EDIT    : 'EDIT_USUARIO',     //button edit
    TABLES  : 'TABLES_USUARIO',   //load configuration tables
    DELETE  : 'DELETE_USUARIO',   //button delete
    INSERTDB: 'INSERT_USUARIO',   //insert db
    UPDATEDB: 'UPDATE_USUARIO',   //update db
};
export const ActionTypes = _actionTypes;
