import React from "react";
import PropTypes from "prop-types";
import { useState, Fragment } from "react";
import { forwardRef, useImperativeHandle } from "react";
import DropdownItem from "./dropdownitem";

const DropdownList = forwardRef((props, ref) => {
    const {list} = props;
    const firstItem = (list.length > 0) ? list[0]: {id:null, text:"seleccione"};
    const [selected, setSelected] = useState(firstItem);

    useImperativeHandle(ref, () => ({
        getSelected() {
            return selected;
        }
    }));
    
    const onSelectedItem = (_id, _text) => {
        setSelected({id: _id, text: _text});
    };

    const items = list.map((item, key) =>
        <DropdownItem key={item.id} id={item.id} text={item.text} onClick={onSelectedItem}></DropdownItem>
    );

    return (
            <Fragment>
                <button type="button" 
                        data-toggle="dropdown" 
                        aria-haspopup="true" 
                        aria-expanded="false" 
                        className="dropdown-toggle btn-shadow btn btn-info">{ selected.text }</button>
                <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                    <h6 tabIndex="-1" className="dropdown-header">Seleccionar busqueda..</h6>
                    { items }
                </div>
            </Fragment>
    );
});

DropdownList.propTypes = {
    list : PropTypes.arrayOf(PropTypes.exact({id: PropTypes.string, text: PropTypes.string})),
};

DropdownList.defaultProps = {
    list: []
};

export default DropdownList;
