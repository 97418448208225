import React, { Fragment as div } from "react";
import { NavLink, NavLinkEdit} from '../../../../lib/controls/navlink';
import moment from 'moment';
import { ActionTypes } from '../../../../lib/flux/types/actiontypes';
import { Button } from "../../../../lib/controls";
import { FormatNumber, FormatDecimal } from '../../../../lib/utils/formatnumber'

const Columns = (onConfirmar, onRechazar, onViewMessage) => {

  const defColumns = [
    {
      name: 'Núm. Orden',
      selector: row => row.codEmpresa + "-" + row.numOrden,
      cell: row => row.codEmpresa + "-" + row.numOrden,
      sortable: true,
      width: '90px',
    },
    {
      name: <div style={{textAlign:"center"}}>Día de Operación</div>,
      selector: 'fechaOrden',
      format: row => { return moment(row.fechaOrden).format('DD/MM/YYYY')},
      sortable: true,
      width: '100px',
      hide: 'sm'
    },
    {
      name: <div style={{textAlign:"center"}}> Hora Registrada </div>,
      selector: 'horaOrden',
      sortable: true,
      width: '80px',
    },
    {
        name: 'Tipo de Orden',
        selector: 'descOrden',
        sortable: true,
        width: '95px',
        format: row => {
          if(row.descOrden==="Creación") {
            return <div className="mb-2 mr-2 text-info">{row.descOrden}</div>
          } else if(row.descOrden==="Redención") {
            return <div className="mb-2 mr-2 text-danger">{row.descOrden}</div>
          }
      },
    },
    {
      name: <div style={{textAlign:"center"}}> Hora de Confirmación Máxima </div>,
      selector: 'horaConfirmacion',
      sortable: true,
      width: '90px',
      hide: 'sm'
    },
    {
      name: <div style={{textAlign:"center"}}> Estado de Orden </div>,
      selector: 'descEstado',
      width: '120px',
      sortable: true,
      cell: row => {
          if(row.descEstado==="Registrada") {
            return <div className="mb-2 mr-2 badge badge-warning">{row.descEstado}</div>
          } else if(row.descEstado==="Confirmada") {
            return <div className="mb-2 mr-2 badge badge-success">{row.descEstado}</div>
          } else if(row.descEstado==="Rechazada") {
            return <div className="mb-2 mr-2 badge badge-danger">{row.descEstado}</div>
          } else if(row.descEstado==="Vencida") {
            return <div className="mb-2 mr-2 badge badge-danger">{row.descEstado}</div>
          }
      },
      hide: 'sm'
    },
    {
      name: 'Número Unidades de Creación',
      selector: 'numUnidades',
      sortable: true,
      width: '80px',
      hide: 'sm'
    },
    {
      name: 'Valor Estimado',
      selector: 'valorEstimado',
      format: row => {
        return (
          <div> <small>{row.codMoneda}</small>{' ' + FormatNumber(FormatDecimal(row.valorEstimado,2))}</div>
        );
      },
      sortable: true,
      width: '125px',
      hide: 'sm'
    },
    {
      name: 'Valor Final',
      selector: 'valorFinal',
      format: row => {
        return (
          <div> <small>{row.codMoneda}</small>{' ' + FormatNumber(FormatDecimal(row.valorFinal,2))}</div>
        );
      },
      sortable: true,
      width: '125px',
      hide: 'sm'      
    },
    {
      name: <div>Participante Autorizado<br></br>Realizado por (Teléfono)</div>,
      selector: 'descEmpresa',
      sortable: true,
      allowOverflow: true,
      width: '200px',
      cell: row => {
        return (
          <div>
            <span style={{fontWeight: 'bold'}}>{row.descEmpresa}</span><br></br>
            {row.descUsuario}
            {' ('}{row.numTelefono}{')'}
          </div>
        );
      }
    },
    {
      name: 'Acción',
      width: '180px',
      allowOverflow: true,
      cell: (row) =>  {
        if (row.descEstado==="Confirmada"){
          return ( //ActionButton()
             <div>
                    <Button onClick={ () => {
                      if (onRechazar){
                        onRechazar(row.codigo, row);
                      }
                    }} icon="fa fa-ban" sm danger> Rechazar</Button>
            </div> 
          );
        }
      },
      button: false,
    }    
  ];

  return defColumns;
}

export default Columns;

//<Button icon="fa fa-edit"  sm noshadow onClick={() => onEditar(row.codUnico)}></Button>{' '}
//<Button icon="fa fa-trash" sm noshadow danger onClick={() => onEliminar(row.codUnico)}></Button>
