const _resultType = {
    iserror: false, 
    message: null 
};

const ErrorType = {
    list: _resultType,
    edit: _resultType
};
  
export const ResultType = _resultType;

export const ResultOk = 'Datos cargados.'