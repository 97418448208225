import React, { Fragment as div } from "react";
import moment from 'moment';
import { NavLink } from "../../../../../lib/controls";

const Columns = (onEditar, onEliminar) => {

  const defColumns = [
    {
      name: 'Codigo',
      selector: 'codUsuario',
      sortable: true,
      width: '120px',
    },
    {
      name: 'Usuario',
      selector: 'username',
      sortable: true,
      width: '120px',
    },
    {
      name: 'Nombre',
      selector: 'nombre',
      sortable: true,
    },
    {
      name: 'Correo',
      selector: 'correo',
      sortable: true,
    },
    {
      name: 'Teléfono',
      selector: 'numTelefono',
      sortable: true,
    },
    {
      name: 'Empresa',
      selector: 'nombreEmpresa',
      sortable: true,
    },
    {
      name: 'Acción',
      allowOverflow: true,
      width: '120px',
      cell: (row) =>  {
        return ( //ActionButton()
          <div>
            <NavLink link='/gestor/cfg/usuario/editor' 
                      icon='fa fa-edit' 
                      success sm
                      data={{ id:row.codUsuario, action: 'E' }}> Editar</NavLink>{' '}
          </div> 
        );
      },
      button: false,
    }    
  ];

  return defColumns;
}

export default Columns;

//<Button icon="fa fa-edit"  sm noshadow onClick={() => onEditar(row.codUnico)}></Button>{' '}
//<Button icon="fa fa-trash" sm noshadow danger onClick={() => onEliminar(row.codUnico)}></Button>
