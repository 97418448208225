import React from "react";
import { BtnSetting } from '../button/buttonstyle';
import { Link } from 'react-router-dom';
import { ActionTypes } from '../../flux/types/actiontypes';

export const NavLink = (props) => {
    const { link, data, icon, children, disabled } = props;
    const _class = BtnSetting( props );
    const _icon = icon? <i className={ icon }></i>: '';

    const _disabled = disabled? { pointerEvents: 'none' }: {};
    
    const _to = {
        pathname: link,
        state: data
    };

    return (
        <Link className={ _class }
            to={_to}
            style={_disabled}>
            { _icon }{ children }
        </Link>
    );
};

export const NavLinkEdit = (props) => {
    const {link, data, children} = props;
    let _data = Object.assign({}, data);
    _data["action"] = ActionTypes.EDIT;
    return (
            <NavLink link={link}
                     data={_data} 
                     info sm noshadow
                     icon='fa fa-edit'>{children}
            </NavLink>
    );
};

export const NavLinkAdd = (props) => {
    const {link, data, children} = props;
    let _data = Object.assign({}, data);
    _data["action"] = ActionTypes.ADD;
    return (
            <NavLink link={link}
                     data={_data} 
                     info
                     icon='fa fa-plus'>{children}
            </NavLink>
    );
};

export const NavLinkDelete = (props) => {
    const {link, data, children} = props;
    let _data = Object.assign({}, data);
    _data["action"] = ActionTypes.DELETE;
    return (
            <NavLink link={link}
                     data={_data} 
                     danger sm noshadow
                     icon='fa fa-trash'>{children}
            </NavLink>
    );
};
