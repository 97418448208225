import React from "react";
import {withRouter, useRouteMatch, useParams, useLocation} from 'react-router-dom';
import MetisMenu from 'react-metismenu';
import MyRouterLink from './routerlink';
import { UpdateLocation } from './updatelocation';

import PerfectScrollbar from 'react-perfect-scrollbar';

const Sidebar = (props) => {
    let { path, url } = useRouteMatch();
    let { id } = useParams();
    let location = useLocation();

    // console.log('SIDEBAR->>');
    // console.log("path: ", path);
    // console.log("url: ", url);
    // console.log("id", id);
    // console.log("location",location);
    // console.log('<<-SIDEBAR');
    const _pathname = UpdateLocation(location.pathname);
    // console.log(_pathname);

    return (
        <PerfectScrollbar>
            <div className="app-sidebar__inner">
                <h5 className="app-sidebar__heading">{props.title}</h5>
                <MetisMenu content={props.menu} 
                           LinkComponent={MyRouterLink}
                           activeLinkTo={_pathname}
                           className="vertical-nav-menu" 
                           iconNamePrefix="" 
                           classNameStateIcon="pe-7s-angle-down"/>
            </div>
        </PerfectScrollbar>
    );
};

Sidebar.displayName = "Sidebar";

export default withRouter(Sidebar);
