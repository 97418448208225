import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { BtnSetting } from './buttonstyle';

const Button = props => {
    const { onClick, icon, tooltip, children, disabled } = props;
    let _class = BtnSetting( props );
    let _icon = icon? <i className={ icon }></i>: '';

    const _onClick = (e) => {
        if ( onClick ) {
            onClick(e);
        }
    };

    return (
        <Fragment>
            <button disabled={disabled} 
                    data-tip={tooltip}
                    className={ _class } 
                    onClick={ _onClick }>{ _icon }{ children }
            </button>
        </Fragment>
    );
    
};

Button.propTypes = {
    noshine : PropTypes.bool,
    noshadow: PropTypes.bool,
    sm      : PropTypes.bool,
    lg      : PropTypes.bool,
    onClick : PropTypes.func,
    disabled: PropTypes.bool,
    addClass: PropTypes.string,
    icon    : PropTypes.string,
    tooltip : PropTypes.string
};

Button.defaultProps = {
    noshine: false,
    noshadow: false,
    sm: false,
    lg: false,
    onClick: () => { console.log('onClick no definido.'); },
    disabled: false,
    addClass: '',
    icon: '',
    tooltip: ''
};

Button.displayName = "Button";

export default Button;