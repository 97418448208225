import React from "react";
import { useState, useEffect, Fragment } from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";

const NavMobile = forwardRef((props, ref) => {
    const [active, setActive] = useState(false);

    useImperativeHandle(ref, () => ({
        setActiveOff() {
            setActive(false);
        }
    }));

    const _onClick = () => {
        let _active = !active;
        setActive(_active);
        if(props.onClick) {
            props.onClick(_active);
        }
    }

    let _class = "hamburger hamburger--elastic mobile-toggle-nav" + (active? " is-active": "");
    return (
            <div className="app-header__mobile-menu">
                <div>
                    <button type="button" 
                            className={ _class } 
                            onClick={ _onClick }>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
    );
});

NavMobile.displayName = "NavMobile";

export default NavMobile;
