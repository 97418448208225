import React from "react";
import PropTypes from "prop-types";
import Breadcrumb from "./breadcrumb";

const PageHeader = props => {
    const { title, subtitle, icon, children, ismobile, breadcrumb } = props;
    let _icon = icon + " icon-gradient bg-mixed-hopes";

    const cls = "app-inner-layout" + (ismobile? " open-mobile-menu": "");
    return ( 
    <div className="app-main__inner p-0">
        <div className={cls}>
            <div className="app-inner-layout__header bg-heavy d-none d-lg-block">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                                <Breadcrumb title={ title } 
                                            path={breadcrumb}>
                                </Breadcrumb>
{/*                             <div>
                                <div class="page-title-head center-elem">
                                     <span class="d-inline-block pr-2">
                                        <i class="lnr-apartment opacity-6"></i>
                                    </span>
                                    <span class="d-inline-block">{ title }</span>
                                </div>
                                <div class="page-title-subheading opacity-10">
                                    <nav class="" aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item">
                                                <a>
                                                    <i aria-hidden="true" class="fa fa-home"></i>
                                                </a>
                                            </li>
                                            <li class="breadcrumb-item">
                                                <a>{ subtitle }</a>
                                            </li>
                                            <li class="active breadcrumb-item" aria-current="page">
                                                Lista
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
 */}
{/*                             <div className="page-title-icon d-none d-lg-block">
                                <i className={ _icon }></i>
                            </div>
                            <div> { title }
                                <div className="page-title-subheading d-none d-lg-block">{ subtitle }</div>
                            </div>
 */}                            
                        </div>
                        <div className="page-title-actions d-none d-lg-block">
                        </div>
                    </div>
                </div> 
            </div>
            <div className="app-inner-layout__wrapper">
                { children }
            </div>
        </div>
    </div>
    );
};

PageHeader.propTypes = {
    title   : PropTypes.string,
    subtitle: PropTypes.string,
    icon    : PropTypes.string
};

PageHeader.defaultProps = {
    title: 'Titulo',
    subtitle: 'subtitulo',
    icon: 'pe-7s-users'
};

PageHeader.displayName = "PageHeader";

export default PageHeader;