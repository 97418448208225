import EventEmitter  from 'events';
import AppDispatcher from '../../../lib/flux/dispatcher';
import { ActionTypes }   from '../../../lib/flux/types/actiontypes';
import { ResultType, ResultOk }    from '../../../lib/flux/types/resulttype'; 
import { ArrayMng } from '../../../lib/utils/arraymng';
import ApiETF  from './api';

const CHANGE_EVENT = 'change';

let _List = [];
let _selectedItem = null;
let _selectedIndex = null;
let _tables = ArrayMng.init();
let _result = Object.assign({}, ResultType);
let _loading = false;
let _exit = false;
let _fechaProceso = null;
let _fechaLiquidacion = null;

class StoreOrdenAP extends EventEmitter {
    constructor() {
        super();
        AppDispatcher.register(this.registerActions.bind(this));
    }

    registerActions(action) {
        switch (action.type) {
            
            case ActionTypes.SEARCH:
                this.clear();
                this.setResult(false, null);
                this.setLoading(true);
                this.emitChange();
                break;

            case ActionTypes.LIST:
                const _filter = action.data;
                //ApiETF.getByFilter(_filter, (response) =>{
                ApiETF.getFilterAllTables(_filter,
                (response) =>{
                    //success
                    _exit = false;

                    //Carga órdenes
                    //console.log("ORDENES: ", response[0].data)
                    this.setList(response[0].data);

                    //carga tabla
                    let newArray = response[1].data.map( ({codEmpresa, descripEmpresa, codPerfilweb}) => ({value: codEmpresa, text: descripEmpresa, perfil: codPerfilweb}));
                    let _newTable = newArray.filter( item => item.perfil === "PA");
                    _newTable.push({value: "999", text: "Ver Todo"});
                    
                    //console.log(response[1].table, _newTable)
                    _tables.add(response[1].table, _newTable);
                    
                    this.setResult(false, ResultOk);
                }, (error) => {
                    //error
                    this.setResult(true, error);
                }, () => {
                    //always
                    this.setLoading(false);
                    this.emitChange();
                });
                break;
            
            case ActionTypes.ADD:
                console.log("ADD ", action.id);
                this.setSelectedItem(null);
                this.LoadEditor();
                break;

            case ActionTypes.INSERTDB:
                const _orden = action.data;
                console.log(_orden);
                ApiETF.saveOrder(_orden, (response) =>{
                    //success
                    console.log(response.data);
                    _exit = true;
                    this.setResult(false, ResultOk);
                }, (error) => {
                    //error
                    this.setResult(true, error);
                }, () => {
                    //always
                    this.setLoading(false);
                    this.emitChange();
                });
                break;

            case ActionTypes.UPDATEDB:
                console.log(action.data);
                ApiETF.updateOrder(action.id, action.data, (response) =>{
                    //success
                    console.log(response.data);
                    _exit = true;
                    this.setResult(false, ResultOk);
                }, (error) => {
                    //error
                    console.log(error);
                    this.setResult(true, error);
                }, () => {
                    //always
                    this.setLoading(false);
                    this.emitChange();
                });
                break;

            case ActionTypes.EDIT:
                // console.log("EDIT ", action.id);
                // this.LoadEditor();
                break;

            case ActionTypes.DELETE:
                console.log("DELETE ", action.id);
                break;
    
            case ActionTypes.CANASTA:
                const _idCanasta = action.data;
                ApiETF.getCanastaTitulo(_idCanasta, (response) =>{
                    //success
                    console.log("Canasta - CanastaTitulo", response);
                    this.setSelectedItem(response[0].data);
                    this.setList(response[1].data)
                    this.setResult(false, ResultOk);
                }, (error) => {
                    //error
                    this.setResult(true, error);
                }, () => {
                    //always
                    this.setLoading(false);
                    this.emitChange();
                });
                break;                
            }

        return true;
    }

    emitChange() {
        this.emit(CHANGE_EVENT);
    }
    
    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    setList(list) { 
        _List = list; 
    }
    getList() { 
        return _List; 
    }

    setSelectedItem(selectedItem) { 
        _selectedItem = selectedItem; 
    }
    getSelectedItem() { 
        return _selectedItem; 
    }

    setTables(tables) { 
        _tables = tables; 
    }
    getTables() { 
        return _tables; 
    }

    setSelectedIndex(selectedIndex) { 
        _selectedIndex = selectedIndex; 
    }
    getSelectedIndex() { 
        return _selectedIndex; 
    }
    
    setResult(iserror, message) { 
        _result = {
            iserror: iserror, 
            message: message
        } 
    }
    getResult() { return _result; }
      
    setLoading(loading) { 
        _loading = loading; 
    }
    getLoading() { 
        return _loading; 
    }
    
    getExit() {
        return _exit;
    }

    getFechaProceso() {
        return _fechaProceso;
    }

    setFechaProceso(fecha) {
        _fechaProceso = fecha;
    }

    getFechaLiquidacion() {
        return _fechaLiquidacion;
    }

    setFechaLiquidacion(fecha) {
        _fechaLiquidacion = fecha;
    }

    clear(){
        _List = [];
        _selectedItem = null;
        _selectedIndex = null;
        _tables = ArrayMng.init();
        _result = Object.assign({}, ResultType);
        _loading = false;
        _exit = false;
        _fechaProceso = null;
        _fechaLiquidacion = null;
    }

    LoadEditor() {
        ApiETF.getCanastaById('001', 
            (response) =>{
                //success
                console.log(response);
                this.setSelectedItem(response.data);
                this.setFechaProceso(response.serverInfo.fechaProceso);
                this.setFechaLiquidacion(response.serverInfo.fechaLiquidacion);
                this.setResult(false, "");
            }, (error) => {
                //error
                console.log(error);
                this.setResult(true, error);
            }, () => {
                //always
                this.setLoading(false);
                this.emitChange();
            });
    }

}

export default new StoreOrdenAP();