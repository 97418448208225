export const MenuModuloGestor = [
    // {
    //     icon: 'pe-7s-home',
    //     label: 'Inicio',
    //     to: '/gestor/dashboard',
    // },    
    {
        icon: 'pe-7s-portfolio',
        label: 'Canasta',
        content: [
            {
                label: 'Ver Canasta',
                to: '/gestor/canasta',
            },
            {
                label: 'Cargar Canasta',
                to: '/gestor/canasta/carga',
            }
       ]
    },
    {
        icon: 'pe-7s-note2',
        label: 'Órdenes',
        content: [
            {
                label: 'Bandeja de Órdenes',
                to: '/gestor/orden',
            },
            {
                label: 'Rechazar Órdenes',
                to: '/gestor/rorden',
            },
        ],
    },
    {
        icon: 'pe-7s-config',
        label: 'Configuración',
        content: [
            {
                label: 'Participante Autorizado',
                to: '/gestor/cfg/pa',
            },
            {
                label: 'Usuarios',
                to: '/gestor/cfg/usuario',
            },
            // {
            //     label: 'Notificados',
            //     to: '/gestor/cfg/notificados',
            // },
            // {
            //     label: 'Plantillas',
            //     to: '/gestor/cfg/template',
            // },
            {
                label: 'Contactos',
                to: '/gestor/cfg/contacto',
            }
        ],
    },    
];
