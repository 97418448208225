import React, { Fragment, useState, useEffect } from "react";
import Button from '../../lib/controls/button'; 
import { PanelMessage } from '../../lib/controls/panel/panelmessage';
import LoadingOverlay from 'react-loading-overlay';
import LoadingStyle from '../../lib/controls/panel/loadingstyle';

export const LoginContainer = (props) => {

    return (
            <div className="app-container app-theme-white body-tabs-shadow">
                <div className="app-container">
                    <div className="h-100 bg-plum-plate bg-animation">
                        <div className="d-flex h-100 justify-content-center align-items-center">
                            <div className="mx-auto app-login-box col-md-8">
                                <div className="app-logo-inverse mx-auto mb-3"></div>
                                    {props.children}
                                <div className="text-center text-white opacity-8 mt-3">Copyright © CapitalTEC 2020</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};


export const Dialog = (props) => {
    const { children, loading, iserror, message } = props;
    const _message =  message? <PanelMessage iserror={iserror}
                                             message={message}>
                               </PanelMessage>: null;    
    return (
        <div className="modal-dialog w-100 mx-auto">
            <LoadingOverlay active={loading}
                            spinner
                            styles={LoadingStyle}>
            
                <div className="modal-content">
                    {_message}
                    {children}
                </div>
            </LoadingOverlay>            
        </div>
    );
};

export const Header = (props) => {
    return (
        <Fragment>
            <div className="h5 modal-title text-center">
                <h4 className="mt-2">
                    <div>Fondo Bursátil ETF Perú</div>
                    <span></span>
                </h4>
            </div>
            <div className="divider row"></div>
        </Fragment>
    );
};

export const HeaderPwd = (props) => {
    return (
        <Fragment>
            <div className="h5 modal-title text-center">
                <h4 className="mt-2">
                    <div>Solicitar Nueva Contraseña</div>
                    <span>Se enviará una nueva contraseña a su cuenta de correo</span>
                </h4>
            </div>
            <div className="divider row"></div>
        </Fragment>
    );
};

export const Footer = (props) => {
    const { onClickChangePwd, onClickLogin } = props
    return (
            <div style={{"padding": "25px"}}>
                <div className="float-left">
                    <Button lg link onClick={ onClickChangePwd }>Cambiar contraseña</Button>
                </div>
                <div className="float-right">
                    <Button lg onClick={ onClickLogin }> Ingresar</Button>
                </div>
            </div>
    );
};

export const FooterPwd = (props) => {
    const { onClickChange, onClickRegresar, changed } = props
    let link = null, button = null;
    if (changed){
        link = <Button lg onClick={ onClickRegresar }>Regresar</Button>;
        button = null;
    } else {
        link = <Button lg link onClick={ onClickRegresar }>Regresar</Button>
        button = <Button lg onClick={ onClickChange }> Solicitar Contraseña </Button>;
    }
    return (
            <div style={{"padding": "25px"}}>
                <div className="float-left">
                    { link }
                </div>
                <div className="float-right">
                    { button }
                </div>
            </div>
    );
};

