const formatNumber = (num, decimal) => {
    var _num = "0";
    if (num) {
        var _formarNum = num;
        if (decimal)
        {
            _formarNum = num.toFixed(decimal);
        }
        _num = _formarNum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } 
    return _num;
    
}
export const FormatNumber = formatNumber;

const formatDecimal = (num, decimal) => {
    var _num = "0." + "0".repeat(decimal);
    if (num) {
        var _formarNum = num;
        if (decimal)
        {
            _formarNum = num.toFixed(decimal);
        }
        _num = _formarNum;
    } 
    return _num;
    
}
export const FormatDecimal = formatDecimal;