import React from "react";
import ReactDOM from "react-dom";

import { AppContainer } from "react-hot-loader";
import 'bootstrap/dist/css/bootstrap.min.css';
import './../public/css/base.css';

const render = () => {
  const App = require("routes").default;
  ReactDOM.render(
    <AppContainer>
      <App />
    </AppContainer>,
    document.getElementById("app")
  );
};

render();

// migrate by this guide
// https://github.com/gaearon/react-hot-loader/tree/master/docs#migration-to-30
if (module.hot) {
  module.hot.accept("routes/", () => {
    render();
  });
}
