import React, { Fragment } from "react";
import { NavLink, NavLinkEdit} from '../../../../lib/controls/navlink';
import moment from 'moment';
import { ActionTypes } from '../../../../lib/flux/types/actiontypes';
import { Button } from "../../../../lib/controls";
import { FormatNumber, FormatDecimal } from '../../../../lib/utils/formatnumber'
import { curryRight } from "lodash";

const Columns = (onMessageView) => {

  const defColumns = [
    {
      name: 'Núm. Orden',
      selector: row => row.codEmpresa + "-" + row.numOrden,
      cell: row => row.codEmpresa + "-" + row.numOrden,
      sortable: true,
      width: '90px',
    },
    {
      name: <div style={{textAlign:"center"}}>Día de Operación</div>,
      selector: 'fechaOrden',
      format: row => { return moment(row.fechaOrden).format('DD/MM/YYYY')},
      sortable: true,
      width: '100px',
      hide: 'sm'
    },
    {
      name: <div style={{textAlign:"center"}}> Hora Registrada </div>,
      selector: 'horaOrden',
      sortable: true,
      width: '80px',
    },
    {
        name: 'Tipo de Orden',
        selector: 'descOrden',
        sortable: true,
        width: '95px',
        format: row => {
          if(row.descOrden==="Creación") {
            return <div className="mb-2 mr-2 text-info">{row.descOrden}</div>
          } else if(row.descOrden==="Redención") {
            return <div className="mb-2 mr-2 text-danger">{row.descOrden}</div>
          }
      },
    },
    {
      name: <div style={{textAlign:"center"}}> Hora de Confirmación Máxima </div>,
      selector: 'horaConfirmacion',
      sortable: true,
      width: '90px',
      hide: 'sm'
    },
    {
      name: <div style={{textAlign:"center"}}> Estado de Orden </div>,
      selector: 'descEstado',
      width: '120px',
      sortable: true,
      cell: row => {
          if(row.descEstado==="Registrada") {
            return <div className="mb-2 mr-2 badge badge-warning">{row.descEstado}</div>
          } else if(row.descEstado==="Confirmada") {
            return <div className="mb-2 mr-2 badge badge-success">{row.descEstado}</div>
          } else if(row.descEstado==="Rechazada") {
            return <div className="mb-2 mr-2 badge badge-danger">{row.descEstado}</div>
          } else if(row.descEstado==="Vencida") {
            return <div className="mb-2 mr-2 badge badge-danger">{row.descEstado}</div>
          }
      },
      hide: 'sm'
    },
    {
      name: 'Número Unidades de Creación',
      selector: 'numUnidades',
      sortable: true,
      width: '80px',
      hide: 'sm'
    },
    {
      name: 'Valor Estimado',
      selector: 'valorEstimado',
      format: row => {
        return (
          <div> <small>{row.codMoneda}</small>{' ' + FormatNumber(FormatDecimal(row.valorEstimado,2))}</div>
        );
      },
      right: true,
      sortable: true,
      width: '140px',
      hide: 'sm'
    },
    {
      name: <div style={{textAlign:"center"}}> Valor Final </div>,
      //name: 'Valor Final',
      selector: 'valorFinal',
      format: row => {
        return (
          <div> <small>{row.codMoneda}</small>{' ' + FormatNumber(FormatDecimal(row.valorFinal,2))} </div>
        );
      },
      right: true,
      sortable: true,
      width: '140px',
      hide: 'sm'
    },
    {
      name: 'RUT Inversionista',
      selector: 'rutInversionista',
      sortable: true,
      width: '100px',
      hide: 'sm'
    },    
    {
      name: <div>Participante<br></br>Autorizado<br></br>Nombre (Teléfono)</div>,
      selector: 'descEmpresa',
      sortable: true,
      allowOverflow: true,
      width: '150px',
      cell: row => {
        return (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}>
            <span style={{fontWeight: 'bold'}}>{row.descEmpresa}</span><br></br>
            {row.descUsuario}<br></br>
            {' ('}{row.numTelefono}{')'}
          </div>
        );
      }
    },
    {
      name: 'Observaciones',
      width: '160px',
      allowOverflow: true,
      cell: (row) =>  {
        if (row.descEstado==="Rechazada"){
          return ( //ActionButton()
             <div>
                    <Button onClick={ () => {
                      if (onMessageView){
                        onMessageView(row.codigo, row);
                      }
                    }} icon="fa fa-comment" sm light shadow> Ver Rechazo</Button>{' '}
            </div> 
          );
        }
      },
      button: false,
    },
    // {
    //   name: 'Teléfono',
    //   selector: 'numTelefono',
    //   sortable: true,
    //   hide: 'sm'
    // },

  ];

  return defColumns;
}

export default Columns;

//<Button icon="fa fa-edit"  sm noshadow onClick={() => onEditar(row.codUnico)}></Button>{' '}
//<Button icon="fa fa-trash" sm noshadow danger onClick={() => onEliminar(row.codUnico)}></Button>
