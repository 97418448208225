import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch, useRouteMatch} from "react-router-dom";
import { Redirect } from "react-router";
import PrivateRoute from "../../../routes/PrivateRoute";
import Workspace from '../../../lib/layout/workspace';

import NotFound    from "../notfound";
import Dashboard   from "./dashboard";
import Canasta     from "./canasta";
import Contacto    from "./contactos";
import Orden       from "./orden";
import OrdenEditor from "./orden/editor";
import { MenuModuloParticipe }  from "./menuitems";

const Modulos = (props) => {
    const { path, url } = useRouteMatch();

    return (
        <Fragment>
            <Workspace menu={ MenuModuloParticipe } titlemenu="AP">
                <Switch>
                    <Route exact path='/paraut' render = { () => <Redirect to="/paraut/dashboard" /> } />
                    <PrivateRoute exact path='/paraut/dashboard'    component={ Dashboard } />
                    <PrivateRoute exact path='/paraut/orden'        component={ Orden } />
                    <PrivateRoute exact path='/paraut/orden/editor' component={ OrdenEditor } />
                    <PrivateRoute exact path='/paraut/canasta'      component={ Canasta } />
                    <PrivateRoute exact path='/paraut/contacto'     component={ Contacto } />
                    <PrivateRoute path="*"                          component={ NotFound } />
                </Switch>            

            </Workspace>
        </Fragment>
    );
};

Modulos.displayName = "Modulos";

export default Modulos;
