const _get = (key) => {
    return localStorage.getItem(key)
};
export const Get = _get;

const _set = (key, value) => {
    localStorage.setItem(key, value);
};
export const Set = _set;

const _clear = () => {
    localStorage.clear();
};
export const Clear = _clear;
