import React, {useState, useEffect} from "react";
import { Alert } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from "prop-types";

export const PanelMessage = (props) => {
    const { iserror, message} = props;
    const [visible, setVisible] = useState(true);
    const _color = iserror? 'danger': 'primary';
    const _title = iserror? 'Error!': 'Información'

    useEffect(() => {
        if (!iserror) {
            window.setTimeout(() => {
                onDismiss();  
            }, 2000);
        }
    }, []);  

    const onDismiss = () => setVisible(false);   
    
    return (
                <Alert color={_color} isOpen={visible} toggle={onDismiss} fade={true}>
                        {message}
                </Alert>
    );
};


export const FloatMessage = (iserror, message) => {

    if (iserror) { 
        toast.error(message, {containerId:'error', className: 'bg-error', autoClose:7000 });
    } else {
        toast.info(message, {containerId:'success', className: 'float-message'});
    }
};
