import React, { Fragment, useState, useEffect, useRef } from "react";
import { PageHeader, PageContent, PanelToolbar, NavLinkAdd, NavLink,
         PanelBodyList, Search, Button, DataTable, Confirm} from '../../../../../lib/controls';
import { Redirect } from "react-router-dom";
import Action   from '../../../../../lib/flux/actions/actionempresa';
import DataStore from '../../../flux/storeparticipe';
import { getAuthInfo } from '../../../../../lib/storage';
import moment from 'moment';

import { Col, Row, Form } from 'reactstrap';
import { EditInput, ValidateBlock, SelectInput, RowCol } from '../../../../../lib/controls';

const getStateFromStore = () => ({
       SelectedItem : DataStore.getSelectedItem(),
             Tables : DataStore.getTables(),
             result : DataStore.getResult(),
            loading : DataStore.getLoading(),
               exit : DataStore.getExit()
});

const UsuarioEditor = (props) => {
    const [store, setStore] = useState( getStateFromStore() );
    const [confirm, setConfirm] = useState(false);
    const { location } = props;
    const { codempresa, nomempresa, nameUser, iduser, correo } = getAuthInfo();
    const _refDescripEmpresa = useRef();
    const _refRUC = useRef();
    const _refCodPerfilweb = useRef();

        //Validar si viene de Inversionista Index
    if (!location.state) {
          alert("No hay acceso");
          return <Redirect to={"/gestor/cfg/pa"} />;
    }

    //Suscribe evento luego de montar todos los componentes.
    useEffect(() => {
       DataStore.addChangeListener( _onStoreChange );
        return () => {
            DataStore.clear();
            DataStore.removeChangeListener( _onStoreChange ) ;
        } 
    }, []);

    //Carga data luego de montar todos los componentes.
    useEffect(() => {
        if (location.state.id) {
            _onLoadEdit(location.state.id);
            _refDescripEmpresa.current.setFocus();
        } else {
            _onLoadAdd();
        };
    }, []);    

    //Luego de montar y actualizar el componente, resetea mensaje sólo si estos cambian.
    useEffect(() => {
        store.result.iserror = false;
        store.result.message = null;
    }, [store.result.message, store.result.iserror]);

    const _onStoreChange = () => {
        setStore( getStateFromStore() );
    };

    const _onLoadEdit = (id) => {
        Action.search();
        if (id) {
            Action.edit(id);
        }
    };

    const _onLoadAdd = () => {
        Action.search();
        Action.add();
    };

    const enviarEmpresa = () => {
        //validar Camos
        if ( _refDescripEmpresa.current.Validate() &&
             _refRUC.current.Validate() &&
             _refCodPerfilweb.current.Validate()) {
                setConfirm(true);
        }        
    };

    const onClickCancel = () => {
        //setConfirm(false);
    };

    const onClickOk = () => {
        //Registrar la Orden
        //...
        const _emp = {
            CodEmpresa: location.state.id,
            DescripEmpresa: _refDescripEmpresa.current.getValue(),
            NumRuc: _refRUC.current.getValue(),
            CodPerfilweb: _refCodPerfilweb.current.getValue(),
        };

        if (location.state.id) {
            _emp.UsuarioEdicion = iduser;
            Action.update(_emp, location.state.id);
        } else {
            _emp.UsuarioCreacion = iduser;
            Action.insert(_emp);
        }
        
    };

    const onClosed = () => {
        setConfirm(false);
    };

    if (store.exit) {
        return <Redirect push to={"/gestor/cfg/pa"} />;
    }

    if (store.SelectedItem == null) {
        store.SelectedItem = {};
    }

    const _pageName = "Participantes";
    const action = location.state.id? "MODIFICAR": "NUEVO"
    const _confirm = confirm? <Confirm title="Confirmar Guardar"
                                       onClickOk={onClickOk} onClosed={onClosed}
                                       onClickCancel={onClickCancel}>
                                    <div>
                                        <div>Confirma guardar la información ?</div><br></br>
                                    </div>
                              </Confirm>: null;
    return (
        <Fragment>
            <PageHeader title={_pageName} 
                        subtitle=""
                        icon="pe-7s-users"
                        breadcrumb={[
                            {url:'/gestor/dashboard',text:'Inicio', icon:'fa fa-home'},
                            {url:'/gestor/cfg/pa',text:'Participantes'},
                            {text:'Editar'}
                        ]}>
                <PageContent pageName= { _pageName }
                             loading={store.loading} 
                             message={store.result.message} 
                             iserror={store.result.iserror}>
                    <PanelToolbar>

                        <div className="pane-left">
                            <div className="ml-2 badge badge-warning animated fadeIn">{ action }</div>
                        </div>
                        <div className="pane-right">
                        </div>

                    </PanelToolbar>
                    <PanelBodyList>
                        <Row>
                            <Col sm="6" md="3" lg="2">
                                <EditInput  id="codempresa" readonly
                                            defaultValue={ store.SelectedItem.codEmpresa }
                                            >Código </EditInput>

                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="6" lg="4">
                                <EditInput  id="descripempresa" required
                                        maxLength={100}
                                        defaultValue={ store.SelectedItem.descripEmpresa }
                                        ref = { _refDescripEmpresa }
                                        >Nombre Participante </EditInput>

                            </Col>

                        </Row>
                        <Row>
                            <Col sm="12" md="6" lg="4">
                                <EditInput  id="rucempresa" required
                                        maxLength={11}
                                        defaultValue={ store.SelectedItem.numRuc }
                                        ref = { _refRUC }
                                        >Número de RUC </EditInput>

                            </Col>

                        </Row>
                        <Row>
                            <Col sm="12" md="6" lg="4">
                                <SelectInput  id="codigoperfil" required
                                        options={ store.Tables.get("Perfilweb")}
                                        defaultValue={ store.SelectedItem.codPerfilweb }
                                        ref= { _refCodPerfilweb }
                                        required>Perfil Web</SelectInput>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br></br>
                                <Button icon='fa fa-check' info sm onClick={ enviarEmpresa }> Guardar</Button>{' '}
                                <NavLink link='/gestor/cfg/pa' 
                                             icon='fas fa-times-circle' 
                                             danger sm
                                             data={{ id:null }}> Cancelar</NavLink>

                            </Col>
                        </Row>
                        { _confirm }
                    </PanelBodyList>
                </PageContent>
            </PageHeader>
        </Fragment>
    );
};

UsuarioEditor.displayName = "UsuarioEditor";

export default UsuarioEditor;