import EventEmitter  from 'events';
import AppDispatcher from '../../../lib/flux/dispatcher';
import { ActionTypes }   from '../../../lib/flux/types/actiontypescanasta';
import { ResultType, ResultOk }    from '../../../lib/flux/types/resulttype'; 
import { ArrayMng } from '../../../lib/utils/arraymng';
import ApiETF  from './api';

const CHANGE_EVENT = 'change';

let _guardado =  false;
let _result = Object.assign({}, ResultType);
let _loading = false;
let _exit = false;

class StoreCargaCanasta extends EventEmitter {
    constructor() {
        super();
        AppDispatcher.register(this.registerActions.bind(this));
    }

    registerActions(action) {
        switch (action.type) {

            case ActionTypes.ADD:
                this.setGuardado(false);
                this.setLoading(false);
                this.emitChange();
                break;

            case ActionTypes.SEARCH:
                this.clear();
                this.setGuardado(false);
                this.setResult(false, null);
                this.setLoading(true);
                this.emitChange();
                break;

            case ActionTypes.UPDATEDB:
                const _orden = action.data;
                console.log(_orden);

                ApiETF.cargaWeb(_orden, (response) =>{
                    //success
                    console.log(response.data);
                    _exit = true;
                    _guardado = true;
                    this.setResult(false, ResultOk);
                }, (error) => {
                    //error
                    this.setResult(true, error);
                }, () => {
                    //always
                    this.setLoading(false);
                    this.emitChange();
                });
                break;
        }

        return true;
    }

    emitChange() {
        this.emit(CHANGE_EVENT);
    }
    
    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    setGuardado(v) { 
        _guardado = v; 
    }
    getGuardado() { 
        return _guardado; 
    }

    setResult(iserror, message) { 
        _result = {
            iserror: iserror, 
            message: message
        } 
    }
    getResult() { return _result; }
      
    setLoading(loading) { 
        _loading = loading; 
    }
    getLoading() { 
        return _loading; 
    }
    
    getExit() {
        return _exit;
    }

    clear(){
        _result = Object.assign({}, ResultType);
        _loading = false;
        _exit = false;
    }

}

export default new StoreCargaCanasta();