import React from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";

const MenuGridItem = (props) => {
    const {link, icon, text, selected, onClick} = props;

    const _onClick = (e) => {
        if (onClick) {
            onClick(link, text);
        }
    };

    let _selected = '';
    if (selected === text) {
        _selected = '-selected';
    };

    return (
        <div className="col-sm-6 col-xl-4">
            <a className={"btn-icon-vertical btn-square btn-transition btn btn-outline-link" + _selected}
                  onClick={ _onClick} >
                    <i className={ icon + " icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3" }></i>
                    { text }
            </a>
        </div>
    );
};

MenuGridItem.propTypes = {
    link: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
    selected: PropTypes.string,
    onClick: PropTypes.func
};

MenuGridItem.defaultProps = {
    link: '/home',
    icon: '',
    text: '',
    selected: '',
    onClick: () => { console.log('onClick() no implementado.');}
};

MenuGridItem.displayName = "MenuGridItem";

export default MenuGridItem;
