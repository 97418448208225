import EventEmitter  from 'events';
import AppDispatcher from '../../../lib/flux/dispatcher';
import { ActionTypes }   from '../../../lib/flux/types/actiontypesusuario';
import { ResultType, ResultOk }    from '../../../lib/flux/types/resulttype'; 
import { ArrayMng } from '../../../lib/utils/arraymng';
import ApiETF  from './api';

const CHANGE_EVENT = 'change';

let _List = [];
let _selectedItem = null;
let _selectedIndex = null;
let _tables = ArrayMng.init();
let _result = Object.assign({}, ResultType);
let _loading = false;
let _exit = false;

class StoreUsuario extends EventEmitter {
    constructor() {
        super();
        AppDispatcher.register(this.registerActionsB.bind(this));
    }

    registerActionsB(action) {
        switch (action.type) {
            
            case ActionTypes.SEARCH:
                this.clear();
                this.setResult(false, null);
                this.setLoading(true);
                this.emitChange();
                break;

            case ActionTypes.LIST:
                const _filter = action.data;
                ApiETF.getAllUsuarios( (response) =>{
                    //success
                    _exit = false;
                    console.log(response.data);
                    this.setList(response.data);
                    this.setResult(false, ResultOk);
                }, (error) => {
                    //error
                    this.setResult(true, error);
                }, () => {
                    //always
                    this.setLoading(false);
                    this.emitChange();
                });
                break;
            
            case ActionTypes.ADD:
                this.setSelectedItem(null);
                
                ApiETF.getAllEmpresa(
                    (response) =>{
                        //success
                        console.log(response);
                        let newArray = response.data.map( ({codEmpresa, descripEmpresa}) => ({value: codEmpresa, text: descripEmpresa}));
                        this.getTables().add("empresas", newArray);
                        this.setResult(false, "");
                    }, (error) => {
                        //error
                        console.log(error);
                        this.setResult(true, error);
                    }, () => {
                        //always
                        this.setLoading(false);
                        this.emitChange();
                    });                
                break;

            case ActionTypes.INSERTDB:
                const _user = action.data;
                console.log(_user);
                ApiETF.addUsuario(_user, (response) =>{
                    //success
                    console.log(response);
                    if (response.code === '0') {
                        _exit = true;
                        this.setResult(false, ResultOk);
                    } else {
                        const {errorDetail} = response;
                        this.setResult(true, errorDetail);
                    }
                }, (error) => {
                    //error
                    this.setResult(true, error);
                }, () => {
                    //always
                    this.setLoading(false);
                    this.emitChange();
                });
                break;

            case ActionTypes.EDIT:
                console.log("EDIT ", action.id);
                this.setSelectedItem(null);
                ApiETF.getUsuarioEditor(action.id, 
                    (response) =>{
                        //success
                        console.log(response);
                        let newArray = response[1].data.map( ({codEmpresa, descripEmpresa}) => ({value: codEmpresa, text: descripEmpresa}));
                        //_tables.add("empresas", newArray);
                        this.getTables().add("empresas", newArray);
                        this.setSelectedItem(response[0].data);
                        this.setResult(false, "Modificar Usuario");
                    }, (message, error) => {
                        //error
                        this.setResult(true, message);
                    }, () => {
                        //always
                        this.setLoading(false);
                        this.emitChange();
                    });
                break;

            case ActionTypes.UPDATEDB:
                console.log(action.data);
                ApiETF.editUsuario(action.id, action.data, (response) =>{
                    //success
                    console.log(response);
                    if (response.code === '0') {
                        _exit = true;
                        this.setResult(false, ResultOk);
                    } else {
                        const {errorDetail} = response;
                        this.setResult(true, errorDetail);
                    }

                }, (error) => {
                    //error
                    console.log(error);
                    this.setResult(true, error);
                }, () => {
                    //always
                    this.setLoading(false);
                    this.emitChange();
                });
                break;

            case ActionTypes.DELETE:
                console.log("DELETE ", action.id);
                break;
        
            }

        return true;
    }

    emitChange() {
        this.emit(CHANGE_EVENT);
    }
    
    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    setList(list) { 
        _List = list; 
    }
    getList() { 
        return _List; 
    }

    setSelectedItem(selectedItem) { 
        _selectedItem = selectedItem; 
    }
    getSelectedItem() { 
        return _selectedItem; 
    }

    setTables(tables) { 
        _tables = tables; 
    }
    getTables() { 
        return _tables; 
    }

    setSelectedIndex(selectedIndex) { 
        _selectedIndex = selectedIndex; 
    }
    getSelectedIndex() { 
        return _selectedIndex; 
    }
    
    setResult(iserror, message) { 
        _result = {
            iserror: iserror, 
            message: message
        } 
    }
    getResult() { return _result; }
      
    setLoading(loading) { 
        _loading = loading; 
    }
    getLoading() { 
        return _loading; 
    }
    
    getExit() {
        return _exit;
    }

    clear(){
        _List = [];
        _selectedItem = null;
        _selectedIndex = null;
        _tables = ArrayMng.init();
        _result = Object.assign({}, ResultType);
        _loading = false;
        _exit = false;
    }

}

export default new StoreUsuario();