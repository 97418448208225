import React from "react";

//Inicio _childsInversionista
//Esto permite seleccionar el link "base" en MetisMenu para los links que no están como menu.
// (Selecciona el padre en MetisMenu)
const _childsInversionista = {
    base : "/participe/inversionista",
    childs: [
        {id:"01", text: "Editar/Aregar Inversionista"        , url: "/editor"},
    ]
}
export const ChildsInversionista = _childsInversionista;
// Fin _childsInversionista

//Se debe agregar todos los demás childs
const _updateList = [
    _childsInversionista,
];

const _updateLocation = (location) => {
    let newLocation = location;
    _updateList.map( ({base, childs}) => childs.map( ({url}) => {
        if (location === base + url){
            newLocation = base;
        }
    }));

    return newLocation;
};
export const UpdateLocation = _updateLocation;