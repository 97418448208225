const _actionTypes = {
    SEARCH  : 'SEARCH_CONTACTO',   //Search button
    LIST    : 'LIST_CONTACTO',     //load list
    ADD     : 'ADD_CONTACTO',      //button add
    EDIT    : 'EDIT_CONTACTO',     //button edit
    TABLES  : 'TABLES_CONTACTO',   //load configuration tables
    DELETE  : 'DELETE_CONTACTO',   //button delete
    INSERTDB: 'INSERT_CONTACTO',   //insert db
    UPDATEDB: 'UPDATE_CONTACTO',   //update db
};
export const ActionTypes = _actionTypes;
