import React, { Fragment, useState, useEffect, useRef } from "react";
import { PageHeader, PageContent, PanelToolbar, NavLinkAdd, NavLink,
         PanelBodyList, Search, Button, DataTable, Confirm, ShowMessage} from '../../../../lib/controls';
import Action   from '../../../../lib/flux/actions';
import DataStore from '../../flux/store';
import Columns from './columns';
import moment from 'moment';
import { FORMAT_INPUTDATE } from '../../../../config';
import { getAuthInfo } from '../../../../lib/storage';

import { Col, Row, Form } from 'reactstrap';
import { EditInput, ValidateBlock, SelectInput, RowCol } from '../../../../lib/controls';

import exportExcell from '../../../../lib/utils/excelexport';

const getStateFromStore = () => ({
               List : DataStore.getList(),
             Tables : DataStore.getTables(),
             result : DataStore.getResult(),
            loading : DataStore.getLoading(),
               exit : DataStore.getExit()
});

const GestorIndexRechazar = (props) => {
    const [store, setStore] = useState( getStateFromStore() );
    const [confirm, setConfirm] = useState({
        confirmar: false,
        rechazar: false,
        orden: 0,
        row: null
    });
    const [viewMessage, setViewMessage] = useState({
        show: false,
        message: "",
        row: null
    });
    const _comentarioRechazo = useRef(null);
    const _refInicio = useRef();
    const _refFinal = useRef();
    const _refEstado = useRef();
    const _refEmpresa = useRef();
    const {perfil, perfil_web, codempresa, nomempresa, iduser} = getAuthInfo();

    //Suscribe evento luego de montar todos los componentes.
    useEffect(() => {
       DataStore.addChangeListener( _onStoreChange );
        return () => {
            DataStore.clear();
            DataStore.removeChangeListener( _onStoreChange ) ;
        } 
    }, []);

    //Carga de clientes luego de montar todos los componentes.
    useEffect(() => {
      _onLoadData();
    }, []);    

    //Luego de montar y actualizar el componente, resetea mensaje sólo si estos cambian.
    useEffect(() => {
        store.result.iserror = false;
        store.result.message = null;
    }, [store.result.message, store.result.iserror]);

    const _onStoreChange = () => {
        setStore( getStateFromStore() );
    };

    const _onLoadData = () => {
        Action.search();
        let _estado = _refEstado.current.getValue();
        let _empresa = _refEmpresa.current.getValue();
        let dataSearch = {
            FechaInicio: _refInicio.current.getValue(),
            FechaFin: _refFinal.current.getValue(),
            CodEstado: _estado === '05'? "": _estado,
            CodEmpresa: _empresa == '999' ? "": _empresa
        };
        Action.list(dataSearch);
    };

    if (store.exit) {
         _onLoadData();
    }

    const onConfirm = (id, row) => {
        setConfirm({
            confirmar: true,
            rechazar: false,
            orden: id,
            row: row            
        });
        console.log("CONFIRMAR", id, row);
    };

    const onReject = (id, row) => {
        setConfirm({
            confirmar: false,
            rechazar: true,
            orden: id,
            row: row            
        });
        console.log("RECHAZAR");
    };

    const onViewMessage = (id, row) => {
        setViewMessage({
            show: true,
            message: row.comentEstado,
            row: row
        });
        console.log('onViewMessage', id);
    };

    const onClosedConfirm = () => {
        setConfirm({confirmar: false});
    };

    const onClosedReject = () => {
        setConfirm({rechazar: false,});
    };

    const onClosedView = () => {
        setViewMessage({
            show: false,
            message: ""
        });
    };

    const onExportar = () => {
        let toExport = store.List.map( (item) => {
            return {
                NumeroDeOrden: "[" + item.codEmpresa + "-" + item.numOrden + "]",
                DiaDeOperacion: moment(item.fechaOrden).format('DD/MM/YYYY'),
                HoraRegistrada: item.horaOrden,
                TipoDeOrden: item.tipoOrden === "C"? "Creación": "Redención",
                HoraDeConfirmacionMaxima: item.horaConfirmacion,
                Estado: item.descEstado,
                NumeroDeUnidadesDeCreacion: item.numUnidades,
                ValorEstimado: item.valorEstimado,
                ValorFinal: 0,
                ParticipanteAutorizado: item.descEmpresa,
                RegistradoPor: item.descUsuario
            }
        });
        var xls = new exportExcell(toExport);
        xls.exportToXLS('export2017.xls');
    };

    const confirmOrder = () => {
        Action.search();
        let data = {
            ComentEstado: '',
            CodEstado: '02',
            CodUsuario: iduser
        };
        Action.update(data, confirm.orden);
    };

    const rejectOrder = () => {
        let _comentario = _comentarioRechazo.current.getValue();
        console.log(_comentario);
        Action.search();
        let data = {
            ComentEstado: _comentario,
            CodEstado: '03',
            CodUsuario: iduser
        };
        Action.update(data, confirm.orden);
     };

    const formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
      
    const _pageName = "Órdenes Confirmadas";
    const _hoy = moment().format(FORMAT_INPUTDATE);
    const _confirm = confirm.confirmar? <Confirm title={"Confirmar Órden " + confirm.row.codEmpresa + "-" + confirm.row.numOrden} colorok="success"
                                                onClickOk={confirmOrder} onClosed={onClosedConfirm}
                                                onClickCancel={null}>
                                                <div>
                                                    <div>Participe Autorizado: <span style={{fontWeight: 'bold', color:'red'}}> { confirm.row.descEmpresa } </span></div>
                                                    <div>Tipo Órden: <span style={{fontWeight: 'bold', color:'red'}}>{ confirm.row.descOrden }</span></div>
                                                    <div>Número de Unidades: <span style={{fontWeight: 'bold', color:'red'}}>{ confirm.row.numUnidades }</span></div>
                                                    <div>Valor Estimado: <span style={{fontWeight: 'bold', color:'red'}}>{ confirm.row.codMoneda + ' ' + formatNumber(confirm.row.valorEstimado) }</span></div>
                                                </div>
                                        </Confirm>: null;  

    const _reject = confirm.rechazar?   <Confirm title={"Rechazar Órden " + confirm.row.codEmpresa + "-" + confirm.row.numOrden} textok="Rechazar" colorok="danger"
                                                onClickOk={rejectOrder} onClosed={onClosedReject} onValidate={ () => _comentarioRechazo.current.Validate() }
                                                onClickCancel={null}> 
                                                <div>
                                                    <div>Participe Autorizado: <span style={{fontWeight: 'bold', color:'red'}}> { confirm.row.descEmpresa } </span></div>
                                                    <div>Tipo Órden: <span style={{fontWeight: 'bold', color:'red'}}>{ confirm.row.descOrden }</span></div>
                                                    <div>Número de Unidades: <span style={{fontWeight: 'bold', color:'red'}}>{ confirm.row.numUnidades }</span></div>
                                                    <div>Valor Estimado: <span style={{fontWeight: 'bold', color:'red'}}>{ confirm.row.codMoneda + ' ' + formatNumber(confirm.row.valorEstimado) }</span></div>
    
                                                    <EditInput  type="textarea" required maxLength={1000}
                                                                id="rechazo"
                                                                ref={_comentarioRechazo}>Comentario de Rechazo</EditInput>

                                                </div>
                                        </Confirm>: null;  
    const _viewMessage = viewMessage.show ? <ShowMessage title={"Motivo de Rechazo: " + viewMessage.row.codEmpresa + "-" + viewMessage.row.numOrden} colorok="success"
                                                onClickOk={onClosedView} 
                                                onClosed={onClosedView}>
                                                <div>
                                                    {viewMessage.message}
                                                </div>
                                            </ShowMessage>: null;  

    return (
        <Fragment>
            <PageHeader title={_pageName} 
                        subtitle="Listado de Inversionistas registrados a la fecha."
                        icon="pe-7s-users"
                        breadcrumb={[
                            {url:'/gestor/dashboard',text:'Inicio', icon:'fa fa-home'},
                            {text:'Ordenes'}
                        ]}>
                <PageContent pageName= { _pageName }
                             loading={store.loading} 
                             message={store.result.message} 
                             iserror={store.result.iserror}>
                    <PanelToolbar>

                        <div style={{width: '100%'}}>
                            <Form noValidate onSubmit={ (e) => { e.preventDefault(); }}>
                            <Row form>
                                <Col>
                                    <EditInput  type="date" id="FechaInicio" readonly
                                                ref = { _refInicio }
                                                defaultValue={ _hoy }>Fecha Inicio</EditInput>
                                </Col>
                                <Col>
                                    <EditInput  type="date" id="FechaFin" readonly
                                                ref = { _refFinal }
                                                defaultValue={ _hoy }>Fecha Fin</EditInput>
                                </Col>
                                <Col>
                                    <SelectInput  id="Estado" noadditem readonly
                                        options={[
                                            {value:"02", text:"Confirmada"},
                                        ]}
                                        ref={ _refEstado }
                                        defaultValue={ "02" }>Estado</SelectInput>
                                </Col>
                                <Col>
                                    <SelectInput  id="ParticipeAutorizado" noadditem
                                        // options={[
                                        //     {value:"003", text:"Larraín Vial S.A.B. S.A"},
                                        //     {value:"004", text:"Credicorp Capital S.A.B. S.A."},
                                        //     {value:"005", text:"Inteligo S.A.B. S.A."},
                                        //     {value:"006", text:"Scotia S.A.B S. A."},
                                        //     {value:"007", text:"Seminario y Cía. S.A.B. S.A."},
                                        //     {value:"999", text:"Ver Todo"},
                                        // ]}
                                        options={store.Tables.get("empresas")}
                                        ref={ _refEmpresa }
                                        defaultValue={ "999" }>Participante Autorizado</SelectInput>
                                </Col>
                                <Col>
                                     <br></br>
                                    <Button icon='fa fa-search' info sm addClass="btn-block" onClick={ _onLoadData }> Buscar</Button> 
                                </Col>
                                <Col>
                                    <br></br>
{/*                                     <Button icon='fa fa-file-download' info sm addClass="btn-block" 
                                            onClick={ onExportar }> Exportar a Excel</Button>
 */}
                                </Col>
                                <Col></Col>
                            </Row>
                            </Form>
                        </div>
{/*                         <div className="pane-right">
                        </div> */}

                    </PanelToolbar>
                    <PanelBodyList>

                        <DataTable columns={ Columns(onConfirm, onReject, onViewMessage) } 
                                    data={store.List} className="shadow">
                        </DataTable>
                        {_confirm}
                        {_reject}
                        {_viewMessage}
                    </PanelBodyList>
                </PageContent>
            </PageHeader>
        </Fragment>
    );
};

GestorIndexRechazar.displayName = "GestorIndexRechazar";

export default GestorIndexRechazar;