const _api_server = process.env.NODE_ENV !== "production"? "http://localhost": "http://3.133.159.3";
const _api_port = process.env.NODE_ENV !== "production"? ":5000": ":5000";
const _ServerETF = {
    "SERVER" : _api_server,
    "PORT"   : _api_port,
};
//"https://3.21.154.154",
export const  ServerETF = _ServerETF;

const _ServerSeguridad = {
    "SERVER" : "https://spectrumweb.azure-api.net/common/",
    "PORT"   : ""
};
export const  ServerSeguridad = _ServerSeguridad;