import React, { Fragment, useState, useEffect, useRef } from "react";
import { PageHeader, PageContent, PanelToolbar, NavLinkAdd, NavLink,
         PanelBodyList, Search, Button, DataTable, Confirm} from '../../../../../lib/controls';
import Action   from '../../../../../lib/flux/actions/actioncanasta';
import DataStore from '../../../flux/storecarga';
import XLSX from 'xlsx';

import {Input, Row, Col, Container} from 'reactstrap';
import { EditInput, ValidateBlock, SelectInput, RowCol } from '../../../../../lib/controls';

import moment from 'moment';
import { FORMAT_INPUTDATE } from '../../../../../config';
import { FormatNumber, FormatDecimal } from '../../../../../lib/utils/formatnumber'

let filaDetalle = 0;

const getStateFromStore = () => ({
           guardado : DataStore.getGuardado(),
             result : DataStore.getResult(),
            loading : DataStore.getLoading()
});

const setCabecera = (sheet) => {
    let cab = {
        CodCanasta : '001',
        DescripCanasta: !sheet? '': sheet['A2'].v, 
        Ticker: !sheet? '': sheet['B3'].v, 
        Isin : !sheet? '': sheet['B4'].v, 
        Sedol : '',
        CodMoneda : 'USD',
        IndiceCanasta : 0,
        FechaOperacion : !sheet? '': sheet['B6'].v,
        NumUnidParticipacion : !sheet? '': sheet['B7'].v,
        CantUnidInicial : !sheet? '': sheet['B8'].v,
        ValorCuota : !sheet? '': sheet['B9'].v,
        PatrimNeto : !sheet? '': sheet['B10'].v,
        NumUnidParticXunid : !sheet? '': sheet['B11'].v,
        CantUnidCreacionDelDia : !sheet? '': sheet['B12'].v,
        CantUnidRedencionDelDia : !sheet? '': sheet['B13'].v,
        FechaCierre : !sheet? '': sheet['B16'].v,
        CantUnidFinal: 0, 
        ValorUnidCreacion: !sheet? '': sheet['B17'].v,
        EfectivoXunidad : !sheet? '': sheet['B18'].v,
        FechaProyeccion : !sheet? '': sheet['B21'].v,
        EstimEfectivoXunidad : !sheet? '': sheet['B23'].v,
        EstimCanastaXunidad : !sheet? '': sheet['B24'].v,
        MontoEfectivoOtros : !sheet? '': sheet['B25'].v,
        EstimEfectivoXunidExc : !sheet? '': sheet['B26'].v,
        UsuarioCreacion: "ADMIN",
        FechaCreación: moment().format(),
        UsuarioEdicion: null,
        FechaEdicion: null        
    };

    return cab;
};

const setDetalle = (sheet) => {
    let filaInicio = 34;
    let _out = true;
    let dets = [];
    
    let _count = 0;
    while (_out) {
        _count = _count + 1;
        filaInicio = filaInicio + 1;
        let row = filaInicio.toString();
        if (!sheet['A' + row] || (sheet['A' + row].v === "")) {
            _out = false;
        } else {
            dets.push( readRowSheetTitulo(sheet, row, _count) );
        };
    };
    _out = true;
    filaInicio = filaInicio + 3;
    
    _count = 0;
    while (_out) {
        _count = _count + 1;
        filaInicio = filaInicio + 1;
        let row = filaInicio.toString();
        
        if (sheet['A' + row]) {
            let titulo = sheet['A' + row].v;
            let fraccion = sheet['E' + row].v;

            let itemIndex = dets.findIndex( (element) => {
                return element.DescripTitulo === titulo; 
            });

            if (itemIndex>-1) {
                //actualiza fracción.
                dets[itemIndex].NumTituloFraccion =  fraccion;
            } else {
                //agrega fracción
                //dets.push( readRowSheetTitulo(sheet, row, fraccion) );
                dets.splice(_count-1,0, readRowSheetTitulo(sheet, row, _count, fraccion));
            }
        
        } else {
            _out = false;
        }

    };

    //corregir NumFila del detalle
    for (let i = 0; i < dets.length; i++) {
        dets[i].NumFila = i + 1;
    };
    return dets;
};

const readRowSheetTitulo = (sheet, row, count, fraccion) => {
    console.log("sedol -> ", sheet['D' + row]);
    let _sedol = '';
    if (sheet['D' + row].t === 'n'){
        _sedol = sheet['D' + row].v.toString();
    } else {
        _sedol = sheet['D' + row].v;
    }
    return {
        CodCanasta : '001',
        NumFila : count,
        DescripTitulo : !sheet? '': sheet['A' + row].v,
        Ticker : !sheet? '': sheet['B' + row].v,
        Isin : !sheet? '': sheet['C' + row].v,
        Sedol : !sheet? '': _sedol,
        NumTitulo : !sheet? 0: (fraccion? 0: sheet['E' + row].v),
        NumTituloFraccion : fraccion? fraccion: 0,
        CodCanastaNavigation: null,
        UsuarioCreacion: "ADMIN",
        FechaCreación: moment().format(),
        UsuarioEdicion: null,
        FechaEdicion: null        
    };
};

const CanastaCarga = (props) => {
    const [store, setStore] = useState( getStateFromStore() );
    const [cargaExcel, setCargaExcel] = useState({ 
        inicio: false,
        CanastaCabecera: setCabecera(null), 
        CanastaDetalle: []
    });
    const _refFileUpload = useRef();

    //Suscribe evento luego de montar todos los componentes.
    useEffect(() => {
       DataStore.addChangeListener( _onStoreChange );
        return () => {
            DataStore.clear();
            DataStore.removeChangeListener( _onStoreChange ) ;
        } 
    }, []);

    //Carga de clientes luego de montar todos los componentes.
    useEffect(() => {
      _onLoadData();
    }, []);    

    //Luego de montar y actualizar el componente, resetea mensaje sólo si estos cambian.
    useEffect(() => {
        store.result.iserror = false;
        store.result.message = null;
        store.loading = false;
    }, [store.result.message, store.result.iserror, store.loading]);

    const _onStoreChange = () => {
        setStore( getStateFromStore() );
    };

    const _onLoadData = () => {
        Action.search();
        Action.add();
    };

    const onUploadFile = (e) => {
        console.log("=> DATOS A CARGAR : ", cargaExcel);
        let cargaweb = {
            CanastaCabecera: cargaExcel.CanastaCabecera,
            CanastaDetalle: cargaExcel.CanastaDetalle
        };
        Action.search();
        Action.update(cargaweb);
       //setGuardado(true);
    };

    const _pageName = "Cargar Canasta";
    const _hoy = moment().format(FORMAT_INPUTDATE);
    const _style = {
     style: {fontWeight: 'bold'},
    };
    const _styleB = {
        style: {fontWeight: 'bold', borderBottom: '1px solid'},
    };
    const _styleC = {
        style: {borderBottom: '1px solid'},
    };
    const _styleD = {
        style: {fontSize: '20px', fontWeight: 'bold', borderBottom: '1px solid', textAlign:'center'},
    };
    const _styleE = {
        style: {textAlign:'right', paddingRight:"80px"},
    };
       
    const _stCol = {
     style: {width:'80'}
    };
    const _stCol2 = {
     style: {width:'20%'}
    };
    const _pending = {
        color:'red', 
        backgroundColor:'#f9f996'
    }

    if (!store.guardado) {
        _stCol2.style = {..._stCol2.style, ..._pending};
        _styleE.style = {..._styleE.style, ..._pending};
    };

    const { DescripCanasta, Ticker, Isin, Sedol,
            CodMoneda,
            FechaOperacion,
            NumUnidParticipacion,
            CantUnidInicial,
            ValorCuota,
            PatrimNeto,
            CantUnidCreacionDelDia,
            CantUnidRedencionDelDia,
            NumUnidParticXunid,
            FechaCierre,
            ValorUnidCreacion,
            EfectivoXunidad,
            FechaProyeccion,
            EstimEfectivoXunidad,
            EstimCanastaXunidad,
            MontoEfectivoOtros,
            EstimEfectivoXunidExc } = cargaExcel.CanastaCabecera;

    const getTitulo = (item, box) => <Fragment>
                                        <td>{item.DescripTitulo}</td>
                                        <td>{item.Ticker}</td>
                                        <td>{item.Isin}</td>
                                        <td>{item.Sedol}</td>
                                        <td {..._styleE}>
                                            {
                                                box==="1"?  FormatNumber(item.NumTitulo) : 
                                                            FormatDecimal(item.NumTituloFraccion,4)
                                            }
                                        </td>
                                     </Fragment>;
    const getTitulos = () => cargaExcel.CanastaDetalle.map( 
        (item, index) => <tr key={index}>{getTitulo(item, "1")}</tr> 
    );
    const getTitulosFraccion = () => cargaExcel.CanastaDetalle.map( 
        (item, index) => <tr key={index}>{getTitulo(item, "2")}</tr> 
    );

    const getCanasta = () => <PanelBodyList>
                                { !store.guardado? <span style={_pending}>VISTA PREVIA:</span>: <span>Canasta Guardada !</span>}
                                <table id="1" className="mb-0 table table-borderless table-sm table-striped" style={{width:'80%'}}>
                                    <tbody>
                                        <tr {..._styleB}><td></td><td></td></tr>
                                        <tr {..._styleD} className="table-secondary"><td {..._stCol} colSpan="2">{DescripCanasta}</td></tr>
                                        <tr><td {..._stCol}>Ticker</td><td {..._stCol2}> {Ticker} </td></tr>
                                        <tr {..._styleC}><td>ISIN</td><td {..._stCol2}> {Isin} </td></tr>
                                        <tr {..._styleB}><td></td><td></td></tr>
                                        <tr {..._styleB} className="table-secondary"><td {..._stCol}>Información del Fondo</td><td {..._stCol2}> {moment(FechaOperacion).format('DD/MM/YYYY')} </td></tr>
                                        <tr><td>Número de Unidades de Participación en circulación</td><td {..._stCol2}> {FormatNumber(NumUnidParticipacion) } </td></tr>
                                        <tr><td>Número de Unidades de Creación en circulación</td><td {..._stCol2}>{FormatNumber(CantUnidInicial) }</td></tr>
                                        <tr><td>Valor Neto de los Activos (NAV)</td><td {..._stCol2}>{CodMoneda}{' '}{ValorCuota}</td></tr>
                                        <tr><td>Patrimonio Neto del Fondo <small>(1)</small></td><td {..._stCol2}>{CodMoneda}{' '}{FormatNumber(PatrimNeto, 2)}</td></tr>
                                        <tr><td>Número de Unidades de Participación por Unidad de Creación/Redención</td><td {..._stCol2}>{FormatNumber(NumUnidParticXunid)}</td></tr>
                                        <tr><td># de Nuevas Unidades de Creación</td><td {..._stCol2}>{FormatNumber(CantUnidCreacionDelDia) }</td></tr>
                                        <tr {..._styleC}><td># de Nuevas Unidades de Redención</td><td {..._stCol2}>{FormatNumber(CantUnidRedencionDelDia) }</td></tr>

                                        <tr><td></td><td></td></tr>
                                        <tr {..._styleB}><td></td><td></td></tr>
                                        <tr {..._styleB} className="table-secondary"><td {..._stCol}>Día de Operación</td><td {..._stCol2}>{moment(FechaOperacion).format('DD/MM/YYYY')}</td></tr>
                                        <tr {..._styleB} className="table-secondary"><td {..._stCol}>Valores finales canasta - Canasta Publicada</td><td {..._stCol2}> {moment(FechaCierre).format('DD/MM/YYYY')} </td></tr>
                                        <tr><td>Valor por Unidad de Creación/Redención <small>(1)</small></td><td {..._stCol2}>{CodMoneda}{' '}{FormatNumber(ValorUnidCreacion, 2)}</td></tr>
                                        <tr {..._styleC}><td>Componente de Efectivo por Unidad de Creación/Redención</td><td {..._stCol2}> {CodMoneda}{' '}{FormatNumber(EfectivoXunidad, 2)} </td></tr>
                                        <tr><td><small><div>(1) Incluye el componente de efectivo</div></small></td><td></td></tr>

                                        <tr><td></td><td></td></tr>
                                        <tr {..._styleB}><td></td><td></td></tr>
                                        <tr {..._styleB} className="table-secondary"><td {..._stCol}>Día de Operación</td><td {..._stCol2}> {moment(FechaProyeccion).format('DD/MM/YYYY')} </td></tr>
                                        <tr {..._styleB} className="table-secondary"><td {..._stCol}>Componente de Efectivo Estimado por Unidad de Creación/Redención - Canasta publicada</td><td {..._stCol2}> {moment(FechaOperacion).format('DD/MM/YYYY')} </td></tr>
                                        <tr><td>Estimado del Componente de Efectivo por Unidad <small>(2)</small></td><td {..._stCol2}> {CodMoneda}{' '}{FormatNumber(EstimEfectivoXunidad, 2)} </td></tr>
                                        <tr><td>Estimado de los Componentes de la Canasta Excluídos por Unidad<small>(3)</small></td><td {..._stCol2}> {CodMoneda}{' '}{FormatNumber(EstimCanastaXunidad, 2)} </td></tr>
                                        <tr><td>Estimado de otros Componentes de Efectivo por Unidad<small>(4)</small></td><td {..._stCol2}> {CodMoneda}{' '}{FormatNumber(MontoEfectivoOtros, 2)} </td></tr>                            
                                        <tr {..._styleC}><td>Estimado del Componente de Efectivo por Unidad incluyendo componentes exlcuidos<small>(5)</small></td><td {..._stCol2}> {CodMoneda}{' '}{FormatNumber(EstimEfectivoXunidExc, 2)} </td></tr>
                                        <tr>
                                            <td colSpan="2">
                                                <small>
                                                    <div>(2) Incluye el componente de efectivo comformado por dividendos, derechos y obligaciones y cualquier otra cantidad proyectada para esta fecha.</div>
                                                    <div>(3) El estimado de los componentes de la canasta excluidos por unidad está calculado al cierre del día de hoy. El estimado será modificado usando el valor de mercado del cierre del día de operación.</div>
                                                    <div>(4) Otros componentes de efectivo estimado como dividendos proyectados u otros.</div>
                                                    <div>(5) El estimado de componentes de efectivo por Unidad que deberá considerarse para la creación/redención de Unidades. Será definitivo al cierre del mercado.</div>
                                                </small>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <table id='2' className="mb-0 table table-borderless table-sm table-striped" style={{width:'80%'}}>
                                    <tbody>
                                        <tr><td></td><td></td><td></td><td></td><td></td></tr>
                                        <tr {..._styleB}><td></td><td></td><td></td><td></td><td></td></tr>
                                        <tr {..._styleB} className="table-secondary"><td colSpan="4">Dia de operación</td><td {..._stCol2}> {moment(FechaProyeccion).format('DD/MM/YYYY')} </td></tr>
                                        <tr {..._styleB} className="table-secondary"><td colSpan="4">Composición de la Canasta por Unidad de Creación/Redención - Canasta publicada</td><td {..._stCol2}> {moment(FechaOperacion).format('DD/MM/YYYY')} </td></tr>

                                    </tbody>
                                </table>
                                <table id='3' className="mb-0 table table-borderless table-sm table-striped" style={{width:'80%'}}>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Ticker</th>
                                            <th>ISIN</th>
                                            <th>SEDOL</th>
                                            <th {..._styleE}># de títulos</th>
                                        </tr>
                                    </thead>                        
                                    <tbody>
                                        {getTitulos()}
                                    </tbody>
                                </table>

                                <table id='4' className="mb-0 table table-borderless table-sm table-striped" style={{width:'80%'}}>
                                    <tbody>
                                        <tr><td></td><td></td><td></td><td></td><td></td></tr>
                                        <tr {..._styleB}><td></td><td></td><td></td><td></td><td></td></tr>
                                        <tr {..._styleB} className="table-secondary"><td colSpan="4">Dia de operación</td><td> {moment(FechaProyeccion).format('DD/MM/YYYY')} </td></tr>
                                        <tr {..._styleB} className="table-secondary"><td colSpan="4">Activos Excluidos que no conforman la Unidad de Creación/Redención - Canasta publicada</td><td> {moment(FechaOperacion).format('DD/MM/YYYY')} </td></tr>

                                    </tbody>
                                </table>

                                <table id='5' className="mb-0 table table-borderless table-sm table-striped" style={{width:'80%'}}>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Ticker</th>
                                            <th>ISIN</th>
                                            <th>SEDOL</th>
                                            <th {..._styleE}># de títulos</th>
                                        </tr>
                                    </thead>                        
                                    <tbody>
                                        {getTitulosFraccion()}
                                        <tr>
                                            <td colSpan="5">
                                                <small>
                                                    <div {..._styleB}>Información Adicional</div>
                                                    <div>Los Inversionistas podrán solicitar la emisión de Unidades de Participación únicamente a través de un Participante Autorizado, sin perjuicio que pueda siempre, a través de las Sociedades Agentes de Bolsa, adquirir Unidades de Participación en el mercado secundario.</div>
                                                    <div>El Participante Autorizado realizará órdenes para la creación o redención de Unidades de Creación, sujeto a el Procedimiento de ordenes de creación y al Reglamento de Participación del Fondo, administrado por El Dorado SAF S.A.</div>
                                                    <div {..._styleB}>Para obtener mayor información contactar a:</div>
                                                    <div> Diego Flores al  (+511) 6114560 Anexo </div>
                                                    <div> Manuel Rodriguez al  (+511) 6114560 Anexo </div>
                                                    <div {..._styleB}>THE INFORMATION IS NOT FOR DISTRIBUTION FOR UNAUTHORIZED PARTIES</div>
                                                    <img src="/public/images/logo_dorado.png" alt=""></img>
                                                </small>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                             </PanelBodyList>;
    const onChangeHandler = (e) => {
        let f = e.target.files[0];
        console.log("=> Load XLSX : ", f);
        _refFileUpload.current.setValue(f.name);
        var reader = new FileReader();
        reader.onload = function(e) {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, {type: 'array', cellDates: true});
          var sheet = workbook.Sheets[workbook.SheetNames[0]];

          //console.log(sheet['B6']);

          setCargaExcel({
              inicio : true,
              CanastaCabecera: setCabecera(sheet),
              CanastaDetalle: setDetalle(sheet)
          });
          
          /* DO SOMETHING WITH workbook HERE */
        };
        reader.readAsArrayBuffer(f);            
    };

    let btnGrabarStyle = {
        icon: 'fa fa-upload',
        danger : true,
        light : false,
        sm : true
    };

    let btnBuscarExcel = {
        icon: 'fa fa-search',
        info: true,
        sm: true
    };
    let disabledControl = {disabled: true};

    if (!cargaExcel.inicio) {
        btnGrabarStyle.danger = false;
        btnGrabarStyle.light = true;
        btnGrabarStyle = {...btnGrabarStyle,  ...disabledControl};
    }
    if (store.guardado) {
        btnGrabarStyle.danger = false;
        btnGrabarStyle.light = true;
        btnBuscarExcel.info = false;
        btnBuscarExcel.light = true;

        btnGrabarStyle = {...btnGrabarStyle,  ...disabledControl};
        btnBuscarExcel = {...btnBuscarExcel, ...disabledControl};     
    }

    return (
        <Fragment>
            <PageHeader title={_pageName} 
                        subtitle="Listado de Inversionistas registrados a la fecha."
                        icon="pe-7s-users"
                        breadcrumb={[
                            {url:'/gestor/dashboard',text:'Inicio', icon:'fa fa-home'},
                            {text:'Canasta'}
                        ]}>
                <PageContent pageName= { _pageName }
                             loading={store.loading} 
                             message={store.result.message} 
                             iserror={store.result.iserror}>
                   <PanelBodyList>
                            <Row>
                                <Col md="5">
                                    <div className="form-group files color">
                                        <EditInput  id="nameFile" ref={_refFileUpload}
                                        defaultValue="" readonly
                                        >Archivo Excel para cargar  :</EditInput>
                                        <Input type='file' id='file' style={{ display: 'none' }} className='form-control' onChange={onChangeHandler}></Input>
                                        <Button {...btnBuscarExcel} onClick={() => { document.getElementById('file').click();}}> Seleccionar Archivo Excel </Button> {' '}
                                        <Button {...btnGrabarStyle}  onClick={ onUploadFile }> Guardar Canasta </Button>  {' '}
                                        <NavLink link='/gestor/orden' 
                                             icon='fas fa-times-circle' 
                                             light sm shadow
                                             data={{ id:null }}> {store.guardado? 'Cerrar': 'Cancelar'} </NavLink>                                        
                                    </div>                            
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    
                                </Col>
                            </Row>
                    </PanelBodyList>
                    {cargaExcel.inicio? getCanasta(): '' }
                </PageContent>
            </PageHeader>
        </Fragment>
    );
};

CanastaCarga.displayName = "CanastaCarga";

export default CanastaCarga;