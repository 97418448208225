
import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import PropTypes from "prop-types";
import { useState, useEffect, Fragment } from "react";

const PanelBodyList = props => {
    const { children } = props;

    return ( 
            <div className="card-body pt-0">
                { children }
            </div>
    );
};

PanelBodyList.displayName = "PanelBodyList";

export default PanelBodyList;