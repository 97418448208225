import AppDispatcher from '../dispatcher';
import { ActionTypes } from "../types/actiontypecontacto";

const AppActions = {

  search: () => {
    AppDispatcher.dispatch({
      type: ActionTypes.SEARCH
    });
  },  

  list: (dataInfo) => {
    AppDispatcher.dispatch({
      type: ActionTypes.LIST,
      data: dataInfo,
    });
  },  
  
  add: (dataInfo) => {
    AppDispatcher.dispatch({
      type: ActionTypes.ADD,
      data: dataInfo
    });
  },

  edit: (pid, pindex) => {
    AppDispatcher.dispatch({
      type: ActionTypes.EDIT,
      id: pid,
      index: pindex
    });
  },

  delete: (pid, pindex) => {
    AppDispatcher.dispatch({
      type: ActionTypes.DELETE,
      id: pid,
      index: pindex
    });
  },

  insert: (dataInfo) => {
    AppDispatcher.dispatch({
      type: ActionTypes.INSERTDB,
      data: dataInfo
    });
  },

  update: (dataInfo, pid, pindex) => {
    AppDispatcher.dispatch({
      type: ActionTypes.UPDATEDB,
      data: dataInfo,
      id: pid,
      index: pindex
    });
  },

  tables: (dataInfo) => {
    AppDispatcher.dispatch({
      type: ActionTypes.TABLES,
      data: dataInfo,
    });
  },  

};

export default AppActions;
