import React from "react";

const MainOuter = (props) => {
    return (
        <div className="app-main__outer">
            { props.children }
        </div>
    );
};

MainOuter.displayName = "MainOuter";

export default MainOuter;
