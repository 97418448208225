import React, { Fragment, useState, useEffect, useRef } from "react";
import { PageHeader, PageContent, PanelToolbar, NavLinkAdd, NavLink,
         PanelBodyList, Search, Button, DataTable, Confirm} from '../../../../lib/controls';
import Action   from '../../../../lib/flux/actions';
import DataStore from '../../flux/store';
import { ExportTableToExcel, TablesToExcel, ShortTableToExcel } from '../../../../lib/utils/exportexcel';

import { ActionTypes } from "../../../../lib/flux/types/actiontypes";
import moment from 'moment';
import { FORMAT_INPUTDATE } from '../../../../config';
import { FormatNumber, FormatDecimal } from '../../../../lib/utils/formatnumber'

const getStateFromStore = () => ({
               List : DataStore.getList(),
       SelectedItem : DataStore.getSelectedItem(),
             result : DataStore.getResult(),
            loading : DataStore.getLoading()
});

const CanastaGestorIndex = (props) => {
    const [store, setStore] = useState( getStateFromStore() );

    //Suscribe evento luego de montar todos los componentes.
    useEffect(() => {
       DataStore.addChangeListener( _onStoreChange );
        return () => {
            DataStore.clear();
            DataStore.removeChangeListener( _onStoreChange ) ;
        } 
    }, []);

    //Carga de clientes luego de montar todos los componentes.
    useEffect(() => {
      _onLoadData();
    }, []);    

    //Luego de montar y actualizar el componente, resetea mensaje sólo si estos cambian.
    useEffect(() => {
        store.result.iserror = false;
        store.result.message = null;
    }, [store.result.message, store.result.iserror]);

    const _onStoreChange = () => {
        setStore( getStateFromStore() );
    };

    const _onLoadData = () => {
        Action.search();
        Action.canasta("001");
    };

    const onDownload = (e) => {
        console.log('onDownload');
        TablesToExcel([1, 2, 3, 4, 5], ['1th', '2th'], 'canasta.xlsx');
        //ShortTableToExcel('1', 'etfperu.XLS');
        //e.preventDefault();
    };

    const _pageName = "Información de Canasta";
    const _hoy = moment().format(FORMAT_INPUTDATE);
    const _style = {
     style: {fontWeight: 'bold'},
    };
    const _styleB = {
        style: {fontWeight: 'bold', borderBottom: '1px solid'},
    };
    const _styleC = {
        style: {borderBottom: '1px solid'},
    };
    const _styleD = {
        style: {fontSize: '20px', fontWeight: 'bold', borderBottom: '1px solid', textAlign:'center'},
    };
    const _styleE = {
        style: {textAlign:'right', paddingRight:"80px"},
    };
       
    const _stCol = {
     style: {width:'80'}
    };
    const _stCol2 = {
     style: {width:'20%'}
    };

    if (store.SelectedItem) {
        console.log(store.SelectedItem);
        console.log(store.List);
    } else {
        store.SelectedItem = {};
        store.List = [];
    }
    const { descripCanasta, ticker, isin, sedol,
            codMoneda,
            fechaOperacion,
            numUnidParticipacion,
            cantUnidInicial,
            valorCuota,
            patrimNeto,
            cantUnidCreacionDelDia,
            cantUnidRedencionDelDia,
            numUnidParticXunid,
            fechaCierre,
            valorUnidCreacion,
            efectivoXunidad,
            fechaProyeccion,
            estimEfectivoXunidad,
            estimCanastaXunidad,
            montoEfectivoOtros,
            estimEfectivoXunidExc } = store.SelectedItem;

    const getTitulo = (item, box) => <Fragment>
                                        <td>{item.descripTitulo}</td>
                                        <td>{item.ticker}</td>
                                        <td>{item.isin}</td>
                                        <td>{item.sedol}</td>
                                        <td {..._styleE}>
                                            {
                                                box==="1"?  FormatNumber(item.numTitulo) : 
                                                            FormatDecimal(item.numTituloFraccion,4)
                                            }
                                        </td>
                                     </Fragment>;
    const getTitulos = () => store.List.map( 
        (item, index) => <tr key={index}>{getTitulo(item, "1")}</tr> 
    );
    const getTitulosFraccion = () => store.List.map( 
        (item, index) => <tr key={index}>{getTitulo(item, "2")}</tr> 
    );

    return (
        <Fragment>
            <PageHeader title={_pageName} 
                        subtitle="Listado de Inversionistas registrados a la fecha."
                        icon="pe-7s-users"
                        breadcrumb={[
                            {url:'/gestor/dashboard',text:'Inicio', icon:'fa fa-home'},
                            {text:'Canasta'}
                        ]}>
                <PageContent pageName= { _pageName }
                             loading={store.loading} 
                             message={store.result.message} 
                             iserror={store.result.iserror}>
                    <PanelToolbar>
                        <div className="pane-left">
                            <Button icon='fa fa-download' info sm onClick={ onDownload }> Descargar Excel</Button> 
                        </div>
                        <div className="pane-right">
                        </div>

                    </PanelToolbar>
                    <PanelBodyList>
                    <table id="1" className="mb-0 table table-borderless table-sm table-striped" style={{width:'80%'}}>
                        <tbody>
                            <tr {..._styleB}><td></td><td></td></tr>
                            <tr {..._styleD} className="table-secondary"><td {..._stCol} colSpan="2">{descripCanasta}</td></tr>
                            <tr><td {..._stCol}>Ticker</td><td {..._stCol2}> {ticker} </td></tr>
                            <tr {..._styleC}><td>ISIN</td><td> {isin} </td></tr>
                            <tr {..._styleB}><td></td><td></td></tr>
                            <tr {..._styleB} className="table-secondary"><td {..._stCol}>Información del Fondo</td><td> {moment(fechaOperacion).format('DD/MM/YYYY')} </td></tr>
                            <tr><td>Número de Unidades de Participación en circulación</td><td> {FormatNumber(numUnidParticipacion) } </td></tr>
                            <tr><td>Número de Unidades de Creación en circulación</td><td>{FormatNumber(cantUnidInicial) }</td></tr>
                            <tr><td>Valor Neto de los Activos (NAV)</td><td >{codMoneda}{' '}{valorCuota}</td></tr>
                            <tr><td>Patrimonio Neto del Fondo <small>(1)</small></td><td>{codMoneda}{' '}{FormatNumber(patrimNeto, 2)}</td></tr>
                            <tr><td>Número de Unidades de Participación por Unidad de Creación/Redención</td><td>{FormatNumber(numUnidParticXunid)}</td></tr>
                            <tr><td># de Nuevas Unidades de Creación</td><td>{FormatNumber(cantUnidCreacionDelDia) }</td></tr>
                            <tr {..._styleC}><td># de Nuevas Unidades de Redención</td><td>{FormatNumber(cantUnidRedencionDelDia) }</td></tr>

                            <tr><td></td><td></td></tr>
                            <tr {..._styleB}><td></td><td></td></tr>
                            <tr {..._styleB} className="table-secondary"><td {..._stCol}>Día de Operación</td><td>{moment(fechaOperacion).format('DD/MM/YYYY')}</td></tr>
                            <tr {..._styleB} className="table-secondary"><td {..._stCol}>Valores finales canasta - Canasta Publicada</td><td> {moment(fechaCierre).format('DD/MM/YYYY')} </td></tr>
                            <tr><td>Valor por Unidad de Creación/Redención <small>(1)</small></td><td>{codMoneda}{' '}{FormatNumber(valorUnidCreacion, 2)}</td></tr>
                            <tr {..._styleC}><td>Componente de Efectivo por Unidad de Creación/Redención</td><td> {codMoneda}{' '}{FormatNumber(efectivoXunidad, 2)} </td></tr>
                            <tr><td><small><div>(1) Incluye el componente de efectivo</div></small></td><td></td></tr>

                            <tr><td></td><td></td></tr>
                            <tr {..._styleB}><td></td><td></td></tr>
                            <tr {..._styleB} className="table-secondary"><td {..._stCol}>Día de Operación</td><td> {moment(fechaProyeccion).format('DD/MM/YYYY')} </td></tr>
                            <tr {..._styleB} className="table-secondary"><td {..._stCol}>Componente de Efectivo Estimado por Unidad de Creación/Redención - Canasta publicada</td><td> {moment(fechaOperacion).format('DD/MM/YYYY')} </td></tr>
                            <tr><td>Estimado del Componente de Efectivo por Unidad <small>(2)</small></td><td> {codMoneda}{' '}{FormatNumber(estimEfectivoXunidad, 2)} </td></tr>
                            <tr><td>Estimado de los Componentes de la Canasta Excluídos por Unidad<small>(3)</small></td><td> {codMoneda}{' '}{FormatNumber(estimCanastaXunidad, 2)} </td></tr>
                            <tr><td>Estimado de otros Componentes de Efectivo por Unidad<small>(4)</small></td><td> {codMoneda}{' '}{FormatNumber(montoEfectivoOtros, 2)} </td></tr>                            
                            <tr {..._styleC}><td>Estimado del Componente de Efectivo por Unidad incluyendo componentes exlcuidos<small>(5)</small></td><td> {codMoneda}{' '}{FormatNumber(estimEfectivoXunidExc, 2)} </td></tr>
                            <tr>
                                <td colSpan="2">
                                    <small>
                                        <div>(2) Incluye el componente de efectivo comformado por dividendos, derechos y obligaciones y cualquier otra cantidad proyectada para esta fecha.</div>
                                        <div>(3) El estimado de los componentes de la canasta excluidos por unidad está calculado al cierre del día de hoy. El estimado será modificado usando el valor de mercado del cierre del día de operación.</div>
                                        <div>(4) Otros componentes de efectivo estimado como dividendos proyectados u otros.</div>
                                        <div>(5) El estimado de componentes de efectivo por Unidad que deberá considerarse para la creación/redención de Unidades. Será definitivo al cierre del mercado.</div>
                                    </small>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <table id='2' className="mb-0 table table-borderless table-sm table-striped" style={{width:'80%'}}>
                        <tbody>
                            <tr><td></td><td></td><td></td><td></td><td></td></tr>
                            <tr {..._styleB}><td></td><td></td><td></td><td></td><td></td></tr>
                            <tr {..._styleB} className="table-secondary"><td colSpan="4">Dia de operación</td><td> {moment(fechaProyeccion).format('DD/MM/YYYY')} </td></tr>
                            <tr {..._styleB} className="table-secondary"><td colSpan="4">Composición de la Canasta por Unidad de Creación/Redención - Canasta publicada</td><td> {moment(fechaOperacion).format('DD/MM/YYYY')} </td></tr>

                        </tbody>
                    </table>
                    <table id='3' className="mb-0 table table-borderless table-sm table-striped" style={{width:'80%'}}>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Ticker</th>
                                <th>ISIN</th>
                                <th>SEDOL</th>
                                <th {..._styleE}># de títulos</th>
                            </tr>
                        </thead>                        
                        <tbody>
                            {getTitulos()}
                        </tbody>
                    </table>
 
                    <table id='4' className="mb-0 table table-borderless table-sm table-striped" style={{width:'80%'}}>
                        <tbody>
                            <tr><td></td><td></td><td></td><td></td><td></td></tr>
                            <tr {..._styleB}><td></td><td></td><td></td><td></td><td></td></tr>
                            <tr {..._styleB} className="table-secondary"><td colSpan="4">Dia de operación</td><td> {moment(fechaProyeccion).format('DD/MM/YYYY')} </td></tr>
                            <tr {..._styleB} className="table-secondary"><td colSpan="4">Activos Excluidos que no conforman la Unidad de Creación/Redención - Canasta publicada</td><td> {moment(fechaOperacion).format('DD/MM/YYYY')} </td></tr>

                        </tbody>
                    </table>

                    <table id='5' className="mb-0 table table-borderless table-sm table-striped" style={{width:'80%'}}>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Ticker</th>
                                <th>ISIN</th>
                                <th>SEDOL</th>
                                <th {..._styleE}># de títulos</th>
                            </tr>
                        </thead>                        
                        <tbody>
                            {getTitulosFraccion()}
                            <tr>
                                <td colSpan="5">
                                    <small>
                                        <div {..._styleB}>Información Adicional</div>
                                        <div>Los Inversionistas podrán solicitar la emisión de Unidades de Participación únicamente a través de un Participante Autorizado, sin perjuicio que pueda siempre, a través de las Sociedades Agentes de Bolsa, adquirir Unidades de Participación en el mercado secundario.</div>
                                        <div>El Participante Autorizado realizará órdenes para la creación o redención de Unidades de Creación, sujeto a el Procedimiento de ordenes de creación y al Reglamento de Participación del Fondo, administrado por El Dorado SAF S.A.</div>
                                        <div {..._styleB}>Para obtener mayor información contactar a:</div>
                                        <div>José Luis Cabrera, jcabrera@eldorado-am.com, +51 949 651 235</div>
                                        <div>Manuel Rodriguez, mrodriguez@eldorado-am.com, +51 999 430 648</div>
                                        <div>Diego Flores, contabilidad@eldorado-am.com, +51 987 530 880</div>
                                        <div {..._styleB}>THE INFORMATION IS NOT FOR DISTRIBUTION FOR UNAUTHORIZED PARTIES</div>
                                        <img src="/public/images/logo_dorado.png" alt=""></img>
                                    </small>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    </PanelBodyList>
                </PageContent>
            </PageHeader>
        </Fragment>
    );
};

CanastaGestorIndex.displayName = "CanastaGestorIndex";

export default CanastaGestorIndex;