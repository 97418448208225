import React from "react";
import { Redirect } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";
import MenuGrid from '../controls/menugrid/menugrid';
import MenuGridItem from '../controls/menugrid/menugriditem';
import { getAuthInfo } from '../storage';

const NavHeader = forwardRef((props, ref) => {
    const [active, setActive] = useState(false);
    const [headerContent, setHeaderContent] = useState(false);
    const [shutdown, setShutDown] = useState(false);
    const { isAuthenticated, nameUser, nomempresa } = getAuthInfo();

    useImperativeHandle(ref, () => {
        return {
            setActiveOff() {
                setActive(false);
            },
            setHeaderContent(active) {
                setHeaderContent(active)
            }
    
        }
    });

    const _onClick = () => {
        setActive(true);
        if (props.onClick) {
            props.onClick();
        }
    };

    const _onClickShutdown = () => {
        localStorage.clear();
        location.href='/';
        setShutDown(true);
    };

    if (shutdown) {
        localStorage.clear();
        return <Redirect to={"/"} />;
    };
    
    const selected = {text:""};
    const _onClickM = (_link, _text) => {
    };
    const _onClickContinue = () => {
    };


    let _class = "hamburger hamburger--elastic open-right-drawer" + (active? " is-active": "");
    let _classHeader = "app-header__content" + (headerContent? " header-mobile-open":"");
    return (
            <div className={ _classHeader }>
                <div className="app-header-left" id="nav_header_left">

                    <ul className="header-megamenu nav">

                    </ul>

                </div>
                <div className="app-header-right">
                    
                    <div className="header-btn-lg pr-0">
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                                <div className="widget-content-left">
                                    <div className="btn-group">
                                        <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="p-0 btn">
                                            <img width="42" className="rounded-circle" src="/public/images/avatars/user.png" alt=""></img>
                                            <i className="fa fa-angle-down ml-2 opacity-8"></i>
                                        </a>
                                        <div tabIndex="-1" role="menu" aria-hidden="true"
                                            className="dropdown-menu-rounded rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right">
                                            <div className="dropdown-menu-header">
                                                <div className="dropdown-menu-header-inner bg-primary">
                                                    <div className="menu-header-image opacity-2"
                                                        style={{backgroundImage: "url('/public/images/dropdown-header/city3.jpg')"}}>
                                                    </div>
                                                    <div className="menu-header-content text-left">
                                                        <div className="widget-content p-0">
                                                            <div className="widget-content-wrapper">
                                                                <div className="widget-content-left mr-3">
                                                                    <img width="42" className="rounded-circle"
                                                                        src="/public/images/avatars/user.png" alt=""></img>
                                                                </div>
                                                                <div className="widget-content-left">
                                                                    <div className="widget-heading">{ nameUser }
                                                                    </div>
                                                                    <div className="widget-subheading opacity-8">{ nomempresa }
                                                                    </div>
                                                                </div>
                                                                <div className="widget-content-right mr-2">
                                                                    <button onClick={ _onClickShutdown }
                                                                        className="btn-pill btn-shadow btn-shine btn btn-focus">Desconectarse
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" tabIndex="0" className="dropdown-item"><i className="dropdown-icon pe-7s-config"> </i>Mi Perfil</button>
                                            <button type="button" tabIndex="0" className="dropdown-item"><i className="dropdown-icon pe-7s-key"> </i>Cambiar contraseña</button>
                                            <button type="button" tabIndex="0" className="dropdown-item"><i className="dropdown-icon pe-7s-news-paper"> </i>Ver Logs</button>

                                        </div>
                                    </div>
                                </div>
                                <div className="widget-content-left  ml-3 header-user-info">
                                    <div className="widget-heading">
                                        { nameUser }
                                    </div>
                                    <div className="widget-subheading">
                                        { nomempresa }
                                    </div>
                                </div>
                                <div className="widget-content-right header-user-info ml-3">
                                    <button type="button" 
                                            className="btn-shadow p-1 btn btn-primary btn-sm"
                                            onClick={ _onClickShutdown }>
                                        <i className="fa text-white fa-power-off pr-1 pl-1"></i>
                                    </button>
                                </div>                    
                            </div>
                        </div>
                    </div>

                    <div className="header-btn-lg">
                        <button type = "button" 
                                className = { _class } 
                                onClick = { _onClick }>
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
    );
});

NavHeader.displayName = "NavHeader";

export default NavHeader;
