import React from "react";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const SidebarContainer = (props) => {
    return (
        <div className="app-sidebar sidebar-shadow">
            { props.children }
        </div>
    );
};

SidebarContainer.displayName = "SidebarContainer";

export default SidebarContainer;


//<div className="app-sidebar sidebar-shadow">
//{ props.children }
//</div>


{/* <ReactCSSTransitionGroup
component="div"
className="app-sidebar sidebar-shadow"
transitionName="SidebarAnimation"
transitionAppear={true}
transitionAppearTimeout={1500}
transitionEnter={false}
transitionLeave={false}>
    { props.children }

</ReactCSSTransitionGroup>
 */}