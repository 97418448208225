import React, { Fragment, useState, useEffect, useRef } from "react";
import { PageHeader, PageContent, PanelToolbar, NavLinkAdd, NavLink,
         PanelBodyList, Search, Button, DataTable, ShowMessage} from '../../../../lib/controls';
import Action   from '../../../../lib/flux/actions';
import DataStore from '../../flux/store';
import Columns from './columns';
import moment from 'moment';
import { FORMAT_INPUTDATE } from '../../../../config';
import { getAuthInfo } from '../../../../lib/storage';

import { Col, Row, Form } from 'reactstrap';
import { EditInput, ValidateBlock, SelectInput, RowCol } from '../../../../lib/controls';

import exportExcell from '../../../../lib/utils/excelexport';

const getStateFromStore = () => ({
               List : DataStore.getList(),
             result : DataStore.getResult(),
            loading : DataStore.getLoading()
});

const InversionistaIndex = (props) => {
    const [store, setStore] = useState( getStateFromStore() );
    const [viewMessage, setViewMessage] = useState({
        show: false,
        message: ""
    });
    const _refInicio = useRef();
    const _refFinal = useRef();
    const _refEstado = useRef();
    const {perfil, perfil_web, codempresa, nomempresa} = getAuthInfo();

    //Suscribe evento luego de montar todos los componentes.
    useEffect(() => {
       DataStore.addChangeListener( _onStoreChange );
        return () => {
            DataStore.clear();
            DataStore.removeChangeListener( _onStoreChange ) ;
        } 
    }, []);

    //Carga de clientes luego de montar todos los componentes.
    useEffect(() => {
      _onLoadData();
    }, []);    

    //Luego de montar y actualizar el componente, resetea mensaje sólo si estos cambian.
    useEffect(() => {
        store.result.iserror = false;
        store.result.message = null;
    }, [store.result.message, store.result.iserror]);

    const _onStoreChange = () => {
        setStore( getStateFromStore() );
    };

    const _onLoadData = () => {
        Action.search();
        let _estado = _refEstado.current.getValue();
        let dataSearch = {
            FechaInicio: _refInicio.current.getValue(),
            FechaFin: _refFinal.current.getValue(),
            CodEstado: _estado === '05'? "": _estado,
            CodEmpresa: codempresa
        };
        Action.list(dataSearch);
    };

    const onViewMessage = (id, row) => {
        setViewMessage({
            show: true,
            message: row.comentEstado
        });
        console.log('onViewMessage', id);
    };

    const onExportar = () => {
        let toExport = store.List.map( (item) => {
            return {
                NumeroDeOrden: "[" + item.codEmpresa + "-" + item.numOrden + "]",
                DiaDeOperacion: moment(item.fechaOrden).format('DD/MM/YYYY'),
                HoraRegistrada: item.horaOrden,
                TipoDeOrden: item.tipoOrden === "C"? "Creación": "Redención",
                HoraDeConfirmacionMaxima: item.horaConfirmacion,
                Estado: item.descEstado,
                NumeroDeUnidadesDeCreacion: item.numUnidades,
                ValorEstimado: item.valorEstimado,
                ValorFinal: item.valorFinal,
                RutInversionista: item.rutInversionista,
                ParticipanteAutorizado: item.descEmpresa,
                RegistradoPor: item.descUsuario
            }
        });
        var xls = new exportExcell(toExport);
        xls.exportToXLS('export2017.xls');
    };

    const onClosedConfirm = () => {
        setViewMessage({
            show: false,
            message: ""
        });
    };

    const _viewMessage = viewMessage.show ? <ShowMessage title="Motivo de Rechazo " colorok="success"
                                                onClickOk={onClosedConfirm} 
                                                onClosed={onClosedConfirm}>
                                                <div>
                                                    {viewMessage.message}
                                                </div>
                                            </ShowMessage>: null;  

    const _pageName = "Órdenes";
    const _hoy = moment().format(FORMAT_INPUTDATE);
    return (
        <Fragment>
            <PageHeader title={_pageName} 
                        subtitle="Listado de Inversionistas registrados a la fecha."
                        icon="pe-7s-users"
                        breadcrumb={[
                            {url:'/paraut/dashboard',text:'Inicio', icon:'fa fa-home'},
                            {text:'Órdenes'}
                        ]}>
                <PageContent pageName= { _pageName }
                             loading={store.loading} 
                             message={store.result.message} 
                             iserror={store.result.iserror}>
                    <PanelToolbar>

                        <div style={{width: '100%'}}>
                            <Row form>
                                <Col>
                                    <EditInput  type="date" id="FechaInicio"
                                                ref = { _refInicio }
                                                defaultValue={ _hoy }>Fecha Inicio</EditInput>
                                </Col>
                                <Col>
                                    <EditInput  type="date" id="FechaFin"
                                                ref = { _refFinal }
                                                defaultValue={ _hoy }>Fecha Fin</EditInput>
                                </Col>
                                <Col>
                                    <SelectInput  id="Estado" noadditem
                                        options={[
                                            {value:"01", text:"Registrada"},
                                            {value:"02", text:"Confirmada"},
                                            {value:"03", text:"Rechazada"},
                                            {value:"04", text:"Vencidas"},
                                            {value:"05", text:"Ver todas"},
                                        ]}
                                        ref={ _refEstado }
                                        defaultValue={ "05" }>Estado</SelectInput>
                                </Col>
                                <Col>
                                     <br></br>
                                    <Button icon='fa fa-search' info sm addClass="btn-block" 
                                            onClick={ _onLoadData }> Buscar</Button>
                                        
                                </Col>
                                <Col>
                                    <br></br>
                                    {perfil==='02'? '': <NavLink link='/paraut/orden/editor' icon='fa fa-plus' info sm addClass="btn-block"
                                            data={{ id:null }}> Nueva Orden</NavLink>}
                                </Col>
                                <Col>
                                    <br></br>
                                    <Button icon='fa fa-file-download' info sm addClass="btn-block" 
                                            onClick={ onExportar }> Exportar a Excel</Button>

                                </Col>
                                <Col></Col>
                            </Row>
                        </div>

                    </PanelToolbar>
                    <PanelBodyList>

                        <DataTable columns={Columns(onViewMessage)} 
                                    data={store.List} className="shadow">
                        </DataTable>
                        { _viewMessage }
                    </PanelBodyList>
                </PageContent>
            </PageHeader>
        </Fragment>
    );
};

InversionistaIndex.displayName = "InversionistaIndex";

export default InversionistaIndex;