import React, { Fragment, useState, useEffect, useRef } from "react";
import { FormFeedback ,FormText, FormGroup, Label, Input } from 'reactstrap';
import { PageHeader, PageContent, PanelToolbar, NavLinkAdd, NavLink,
         PanelBodyList, Search, Button, DataTable, Confirm} from '../../../../lib/controls';
import { Redirect } from "react-router-dom";
import Action   from '../../../../lib/flux/actions';
import DataStore from '../../flux/store';
import Columns from './columns';
import { ActionTypes } from "../../../../lib/flux/types/actiontypes";
import { getAuthInfo } from '../../../../lib/storage';
import moment from 'moment';

import { Col, Row, Form } from 'reactstrap';
import { EditInput, ValidateBlock, SelectInput, RowCol } from '../../../../lib/controls';
import { InputFormat, DBFormat } from '../../../../config';
import { CreateRef, GetAllValueRefs, GetValueRef, GetValue } from '../../../../lib/utils/arraysrefs';

const getStateFromStore = () => ({
               List : DataStore.getList(),
       SelectedItem : DataStore.getSelectedItem(),
             result : DataStore.getResult(),
            loading : DataStore.getLoading(),
               exit : DataStore.getExit(),
       FechaProceso : DataStore.getFechaProceso(),
   FechaLiquidacion : DataStore.getFechaLiquidacion()
});

const InversionistaIndex = (props) => {
    const [store, setStore] = useState( getStateFromStore() );
    const [confirm, setConfirm] = useState(false);
    //const [register, setRegister] = useState(false);
    const { codempresa, nomempresa, nameUser, iduser, correo, perfil } = getAuthInfo();
    const _refTipoOrden = useRef();
    const _refnumUnidades = useRef();
    const _refValorEstimado = useRef();
    const _reffechaOperacion = useRef();
    const _reffechaLiquidacion = useRef();
    const _indInversionista = useRef();
    const _rutInversionista = useRef();


    //Suscribe evento luego de montar todos los componentes.
    useEffect(() => {
       DataStore.addChangeListener( _onStoreChange );
        return () => {
            DataStore.clear();
            DataStore.removeChangeListener( _onStoreChange ) ;
        } 
    }, []);

    //Carga de clientes luego de montar todos los componentes.
    useEffect(() => {
      _onLoadData();
      _refnumUnidades.current.setFocus();   
    }, []);    

    //Luego de montar y actualizar el componente, resetea mensaje sólo si estos cambian.
    useEffect(() => {
        store.result.iserror = false;
        store.result.message = null;
    }, [store.result.message, store.result.iserror]);

    const _onStoreChange = () => {
        setStore( getStateFromStore() );
    };

    const _onLoadData = () => {
        Action.search();
        Action.add();
    };

    const onAgregar = () => {
        console.log('onAgregar');
    };

    const onEditar = (id) => {
        console.log('onEditar', id);
    };

    const onEliminar = (id) => {
        console.log('onEliminar', id);
    };

    const enviarOrden = () => {
        if (_refnumUnidades.current.Validate() && _rutInversionista.current.Validate()) {
            setConfirm(true);
        }
        
    };

    const onClickCancel = () => {
        //setConfirm(false);
    };

    const onClickOk = () => {
        //Registrar la Orden
        //...
        let _indInv = _indInversionista.current.getValue();
        const _newOrden = {
            CodOrden: "",
            TipoOrden: _refTipoOrden.current.getValue(), //"C",
            FechaOrden: moment().format('YYYY-MM-DD'),
            HoraOrden: moment().format('h:mm:ss'),
            HoraConfirmacion: moment().add(2,'hours').format('h:mm:ss'),
            CodEstado: "01",
            CodUsuario: iduser,
            CodMoneda: store.SelectedItem.codMoneda,
            CodEmpresa: codempresa,
            NumOrden: 0,
            ComentEstado: "",
            FechaLiquidacion: moment(store.FechaLiquidacion,"DD/MM/YYYY").format('YYYY-MM-DD'),
            NumUnidades: _refnumUnidades.current.getValue() * 1,
            ValorEstimado: _refnumUnidades.current.getValue() * store.SelectedItem.valorUnidCreacion,
            UsuarioCreacion: iduser,
            FechaCreacion: moment().format(),
            IndInversionistaFinal: _indInv,
            RutInversionista: _indInv=="1" ? _rutInversionista.current.getValue(): null
        };
        console.log(_newOrden);
        //retornar a Bandeja de Ordenes
        Action.search();
        Action.insert(_newOrden);
        //setRegister(true);
    };

    const onClosed = () => {
        setConfirm(false);
    };

    const formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    
    if (store.exit) {
        return <Redirect push to={"/paraut/orden"} />;
    }

    const getFechaLiquidación = () => {
        var fechaLiquidación = moment().add(2, 'days').format('DD/MM/YYYY');

        return fechaLiquidación;
    }

    const _pageName = "Nueva Órden";
    const _confirm = confirm? <Confirm title="Confirmar Envío"
                                       onClickOk={onClickOk} onClosed={onClosed}
                                       onClickCancel={onClickCancel}>
                                    <div>
                                        <div>Por favor confirme la información a enviar:</div><br></br>
                                        <div>Tipo Órden: <span style={{fontWeight: 'bold', color:'red'}}> {
                                            _refTipoOrden.current.getValue() === 'C'? 'Creación': 'Redención'
                                        } </span></div>
                                        <div>Número de Unidades: <span style={{fontWeight: 'bold', color:'red'}}> {_refnumUnidades.current.getValue()} </span></div>
                                        <div>Valor Estimado: <span style={{fontWeight: 'bold'}}> {_refValorEstimado.current.getValue()} </span></div>
                                        <div>Fecha de Operación: <span style={{fontWeight: 'bold'}}> {_reffechaOperacion.current.getValue()} </span></div>
                                        <div>Fecha de Liquidación: <span style={{fontWeight: 'bold'}}> {_reffechaLiquidacion.current.getValue()} </span></div>
                                    </div>
                              </Confirm>: null;
    return (
        <Fragment>
            <PageHeader title={_pageName} 
                        subtitle="Listado de Inversionistas registrados a la fecha."
                        icon="pe-7s-users"
                        breadcrumb={[
                            {url:'/paraut/dashboard',text:'Inicio', icon:'fa fa-home'},
                            {url:'/paraut/orden',text:'Orden'},
                            {text:'Enviar'}
                        ]}>
                <PageContent pageName= { _pageName }
                             loading={store.loading} 
                             message={store.result.message} 
                             iserror={store.result.iserror}>
                    <PanelToolbar>

                        <div className="pane-left">

                        </div>
                        <div className="pane-right">
                        </div>

                    </PanelToolbar>
                    <PanelBodyList>
                        <Row>
                            <Col xs="4">
                                <EditInput  id="participe" 
                                        defaultValue={ nomempresa } readonly
                                        >Partícipe Autorizado</EditInput>

                            </Col>
                            <Col xs="2">
                                <EditInput  id="usuario" 
                                        defaultValue={ nameUser } readonly
                                        >Orden Registrada por:</EditInput>
                            </Col>
                            <Col xs="2">
                                <EditInput  id="correo" 
                                        defaultValue={ correo } readonly
                                        >Correo Eletrónico:</EditInput>

                            </Col>
                            <Col xs="2">
                                <SelectInput  id="INVERSIONISTA" 
                                        options={[
                                            {value:"0", text:"No"},
                                            {value:"1", text:"Sí"},
                                        ]}
                                        defaultValue={ "0" } noadditem
                                        ref= { _indInversionista }
                                        onSelectedChange={ (newValue, id) => {
                                            if (newValue == "1"){
                                                _rutInversionista.current.setReadonly(false);
                                                _rutInversionista.current.setRequired(true);
                                                _rutInversionista.current.setFocus();
                                            } else {
                                                _rutInversionista.current.setReadonly(true);
                                                _rutInversionista.current.setRequired(false);
                                                _rutInversionista.current.setValue("");
                                            }
                                        }}
                                        required>Existe Inversionista Final:</SelectInput>
                            </Col>
                            <Col xs="2">
                                <EditInput  id="RUT" 
                                        maxLength= {15} minLength= {1}
                                        ref = { _rutInversionista }
                                        defaultValue={ "" }
                                        >RUT Inversionista:</EditInput>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="2">
                                <SelectInput  id="TIPO" 
                                        options={[
                                            {value:"C", text:"Creación"},
                                            {value:"R", text:"Redención"},
                                        ]}
                                        defaultValue={ "C" } noadditem
                                        ref= { _refTipoOrden }
                                        onSelectedChange={ (newValue, id) => {
                                            _refnumUnidades.current.Validate();
                                            if (newValue == "R") {
                                                _indInversionista.current.setReadonly(true);
                                                _indInversionista.current.setValue("0");
                                                _rutInversionista.current.setValue("");
                                                _rutInversionista.current.setRequired(false);
                                                _rutInversionista.current.setReadonly(true);
                                                _rutInversionista.current.InValidate();
                                            } else {
                                                _indInversionista.current.setReadonly(false);
                                            }
                                        }}
                                        required>Orden</SelectInput>

                            </Col>
                            <Col xs="2">
                                <EditInput type="integer" id="numero" 
                                        defaultValue="0" maxLength="6"
                                        validate = { [
                                            { 
                                                validate : (val) => {
                                                    console.log("TIPO DE ORDEN: ", _refTipoOrden.current.getValue(), val);
                                                    let _tipoOrden = _refTipoOrden.current.getValue();

                                                    if (_tipoOrden === "R") {
                                                        let stock = store.SelectedItem.cantUnidInicial + 
                                                                    store.SelectedItem.cantUnidCreacionDelDia -
                                                                    store.SelectedItem.cantUnidRedencionDelDia;

                                                        if (val > stock) {
                                                            return { ok: false, msg: "ERROR: No puede redimir más de " + stock + " unidad(es)."}
                                                        } else {
                                                            return { ok: true }
                                                        }
                                                    }
                                                    return { ok : true }
                                                }
                                            },
                                            {
                                                validate : (val) => {
                                                    const _number = parseInt(val);
                                                    if (_number === 0) {
                                                        return { ok: false, msg: "Ingrese las unidades de creación."}
                                                    } else {
                                                        return { ok: true }
                                                    }
                                                }
                                            }
                                        ]}
                                        ref={ _refnumUnidades }
                                        onChange={ (newValue) => { 
                                            console.log(newValue, store.SelectedItem); 
                                            let _val = newValue * store.SelectedItem.valorUnidCreacion;
                                            let _valRound = Math.round(_val * 100) / 100
                                            _refValorEstimado.current.setValue( store.SelectedItem.codMoneda + ' ' + formatNumber(_valRound) );
                                        } }
                                        >Unidades de Creación (Cu)</EditInput>

                            </Col>
                            <Col xs="4">
                                <EditInput  id="valor" 
                                        defaultValue='0' readonly
                                        ref= { _refValorEstimado }
                                        >Valor Estimado:</EditInput>

                            </Col>                            
                            <Col xs="2">
                                <EditInput  id="oepracion" 
                                        //defaultValue={ moment().format('DD/MM/YYYY')} 
                                        defaultValue={ store.FechaProceso } 
                                        readonly
                                        ref={ _reffechaOperacion }
                                        >Fecha Operación:</EditInput>

                            </Col>                            
                            <Col xs="2">
                                <EditInput  id="liquidacion" 
                                        //defaultValue={ getFechaLiquidación() } 
                                        defaultValue={ store.FechaLiquidacion} 
                                        readonly
                                        ref={ _reffechaLiquidacion}
                                        >Fecha Liquidación:</EditInput>

                            </Col>                            
                        </Row>
                        <Row>
                            <Col style={{fontSize: "11px"}}>
                                *Las Órdenes de Creación que sean recibidas por la Sociedad Administradora dentro de los horarios de negociación establecidos para acciones y valores representativos de derechos sobre acciones: correspondiente al segmento de valores de alta liquidez según lo publicado por la BVL en su página web (www.bvl.com.pe).                            
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br></br>
                                {perfil==='02'? '': <Button icon='fa fa-check' info sm onClick={ enviarOrden }> Enviar Órden</Button>}
                                
                            </Col>
                        </Row>
                        { _confirm }
                    </PanelBodyList>
                </PageContent>
            </PageHeader>
        </Fragment>
    );
};

InversionistaIndex.displayName = "InversionistaIndex";

export default InversionistaIndex;