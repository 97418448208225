import React, { Fragment, forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormFeedback ,FormText, FormGroup, Label, Input } from 'reactstrap';
import { InitError, IsRequired, IsMinLength, IsMaxLength, SetError } from './validator';

const EditInput = forwardRef((props, ref) => {
    const { id, type, placeholder, sm, lg, texthelp, texterror, 
            required, minLength, maxLength, 
            defaultValue, validate, children, readonly, onChange } = props;
    let control = useRef();
    const [validForm, setValidForm] = useState(InitError());
    const [value, setValue] = useState(defaultValue);
    const [forceReadOnly, setForceReadOnly] = useState(false);
    const [forceRequired, setForceRequired] = useState(false);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return _getValueControl();
        },
        setValue: (newVal) => {
            control.current.value = newVal;
        },
        Validate: (forceNewVal) => {
            console.log("Validate....")
            return _onValidate(forceNewVal);
        },
        setFocus: () => {
            control.current.select();
            control.current.focus();
        },
        setReadonly: (force) => {
            _forceReadOnly(force);
        },
        setRequired: (force) => {
            _forceRequired(force)
        },
        InValidate: () => {
            _inValidate();
        },
    }));

    // useEffect(() => {
    //     setValue(defaultValue);
    //     console.log(defaultValue, "-->", id);
    //     _setValueControl(defaultValue);
    // }, [defaultValue])

    // const _setValueControl = (newValue) => {
    //     console.log("newValue: ", newValue);
    //     control.current.value = newValue;
    // };
    const _forceRequired = (force) => {
        setForceRequired(force)
    }
    const _forceReadOnly = (force) => {
        setForceReadOnly(force);
    }
    const _getValueControl = () => {
        return control.current.value;
    };

    const _inValidate = () => {
        setValidForm(InitError());
    };

    const _onValidate = (forceNewVal) => {
        let value = _getValueControl();

        console.log(required, forceRequired, readonly, forceReadOnly);
        if (forceNewVal) {
            value = forceNewVal;
        } 

        if (required || forceRequired) {
            console.log("Alerta1");
            let _res = IsRequired(value)
            if (_res.isError){
                setValidForm(_res);
                return false;
            }
        }

        if (minLength && (!readonly && !forceReadOnly) ){
            console.log("Alerta2");
            let _res = IsMinLength(value, minLength)
            if (_res.isError){
                setValidForm(_res);
                return false;
            }
        }

        if (maxLength){
            console.log("Alerta3");
            let _res = IsMaxLength(value, maxLength)
            if (_res.isError){
                setValidForm(_res);
                return false;
            }
        }

        //validación personalizada
        //Debe ser un arreglo de objeto validate [ {validate: ...}, {validate: ...}]
        if (validate){
            let _validationOk = true;
            validate.forEach( (item) => {
                let result = item.validate(value);
                if (!result.ok){
                    setValidForm(SetError(true, result.msg));
                    _validationOk = false;
                }
            });
            
            if (!_validationOk) {
                return false;
            }
        }

        setValidForm(InitError());
        return true;
    };

    const _onBlur = (e) => {
            _onValidate(); 
    };

    const _onChange = (e) => {
        if (type == "integer"){
            e.target.value = e.target.value.replace(/\D/,'')
        }

        if (onChange) {
            onChange(e.target.value);
        }
    };

    let _newType = type;
    if (type === "integer"){
        _newType = "text";
    };

    // const onChangeInteger = (obj) => {
    //     console.log(obj.target.validity.valid, obj.target.value, control.current.value);
    //     const _newValue = (obj.target.validity.valid) ? obj.target.value : control.current.value;
    
    //     control.current.value = _newValue;
    // };

    const allprops = {
        type:_newType,
        name:_newType,
        id:id,
        defaultValue:defaultValue,
        placeholder:placeholder,
        onBlur: _onBlur,
        maxLength : maxLength,
        onChange : _onChange,
        invalid : validForm.isError,
        autoComplete:"off",
        bsSize: sm? "sm": (lg? "lg": "sm")
    }

    let readOnlyProps = {
        readOnly : readonly? true: false
    }

    let requiredProps = {
        required : required
    }

    if (forceReadOnly) {
        console.log("force readonly");
        readOnlyProps = {
            readOnly : true
        }
    }

    if (forceRequired) {
        requiredProps = {
            required : true
        }        
    }

    return (
        <FormGroup>
            <Label for={ id }>{ children }</Label>
            <Input {...allprops} {...readOnlyProps} {...requiredProps} innerRef={control} />
            {texthelp? <FormText>{texthelp}</FormText>:''}
            {validForm.textError? <FormFeedback invalid="true">{validForm.textError}</FormFeedback>: ''}
        </FormGroup>
    );
});

EditInput.propTypes = {
    id         : PropTypes.string,
    type       : PropTypes.string,
    texthelp   : PropTypes.string,
    texterror  : PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    sm         : PropTypes.bool,
    lg         : PropTypes.bool,
    required   : PropTypes.bool,
};

EditInput.defaultProps = {
    id         : '',
    type       : 'text',
    texthelp   : '',
    texterror  : '',
    defaultValue: '',
    placeholder: null,
    sm         : false,
    lg         : false,
    required   : false
};

EditInput.displayName = "EditInput";

export default EditInput;
