import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from '../button'

const PageSidebar = props => {
    const { pageName, returnText, title, subtitle, children, urlHome, onSave } = props;

    return ( 
    <div className="app-inner-layout__sidebar card shadow">
            <ul className="nav flex-column">
                <li className="nav-item-headerb nav-item d-lg-none d-xl-none">
                    { pageName }
                </li>
                <li className="p-4 nav-item">
                    <Button addClass='btn-block' icon="lnr-upload" onClick={ onSave }> Guardar</Button>        
                </li>                    
                <li className="pl-1 nav-item">
                    <Link className="nav-link"
                        to={urlHome}>
                        <i className="nav-link-icon fa fa-angle-double-left"></i>
                        <span>{ returnText }</span>
                        <div className="ml-auto badge badge-pill ">
                            <i className="fa fa-star text-warning"></i>
                        </div>
                    </Link>
                </li>
                {/* <li className="nav-item-divider nav-item"></li> */}
                <li className="nav-item-header nav-item">{ title }
                    <small className="ml-1">{subtitle}</small>
                </li>
                <li className="nav-item">
                   { children }
                </li>
            </ul>

    </div>
    );
};

PageSidebar.propTypes = {
    returnText   : PropTypes.string,
    title: PropTypes.string,
    urlHome: PropTypes.string
};

PageSidebar.defaultProps = {
    returnText: 'Regresar al listado',
    title: '',
    urlHome: ''
};

PageSidebar.displayName = "PageSidebar";

export default PageSidebar;