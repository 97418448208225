import React from "react";
import { useState, useEffect, Fragment } from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";

const NavLogo = (props) => {
    const [active, setActive] = useState(false);

    const _onClick = () => {
        let _active = !active;
        setActive(_active);
        if(props.onClick) {
            props.onClick(_active);
        }
    }

    let _class = "hamburger close-sidebar-btn hamburger--elastic" + (active? " is-active": "");

    return (
        <div className="app-header__logo">
            <div className="logo-src"></div>
            <div className="header__pane ml-auto">
                <div>
                    <button type="button" 
                            className={ _class } 
                            onClick={ _onClick }
                            data-class="closed-sidebar">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

NavLogo.displayName = "NavLogo";

export default NavLogo;
