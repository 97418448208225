import moment from 'moment';

export const FORMAT_FULLDATE = 'DD/MM/YYYY HH:mm:ss';
export const FORMAT_DATE = 'DD/MM/YYYY';
export const FORMAT_TIME = 'HH:mm:ss';

export const FORMAT_INPUTDATE = 'YYYY-MM-DD';
export const FORMAT_DBDATE = 'YYYY-MM-DDTHH:mm:ss';

//get string date
const _inputFormat = (date) => {
    //let _date = moment(date).format(FORMAT_INPUTDATE);
    let _date = date.substring(0,10);
    if (_date === "1900-01-01") {
        _date = "";
    }
    return _date;
};
export const InputFormat = _inputFormat;

export const DBFormat = (date) => {
    return (moment(date).format(FORMAT_DBDATE));
};

