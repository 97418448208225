import React from "react";
import PropTypes from "prop-types";
import { useState, useEffect, Fragment } from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";

const SideDrawer = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        setOpenDrawer() {
            setOpen(true);
        },
        addItems(params) {
            console.log(params);
        }
    }));

    const _onClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    }

    let _classWrapper = "app-drawer-wrapper" + (open? " drawer-open": "");
    let _classOverlay = "app-drawer-overlay animated fadeIn" + (open? "": " d-none");
    return (
        <Fragment>
            <div className={ _classWrapper }>
                <div className="drawer-nav-btn" onClick={ _onClose }>
                    <button type="button" className="hamburger hamburger--elastic is-active">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
                <div className="drawer-content-wrapper">
                    <div className="scrollbar-container">
                        <h3 className="drawer-heading">Events Log</h3>
                        <div className="drawer-section">
                        <div className="row">
                    <div className="col">
                        <div className="progress-box"><h4>Server Load 1</h4>
                            <div className="circle-progress circle-progress-gradient-xl mx-auto">
                                <small></small>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="progress-box"><h4>Server Load 2</h4>
                            <div className="circle-progress circle-progress-success-xl mx-auto">
                                <small></small>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="progress-box"><h4>Server Load 3</h4>
                            <div className="circle-progress circle-progress-danger-xl mx-auto">
                                <small></small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="mt-3"><h5 className="text-center card-title">Live Statistics</h5>
                    <div id="sparkline-carousel-3"></div>
                    <div className="row">
                        <div className="col">
                            <div className="widget-chart p-0">
                                <div className="widget-chart-content">
                                    <div className="widget-numbers text-warning fsize-3">43</div>
                                    <div className="widget-subheading pt-1">Packages</div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="widget-chart p-0">
                                <div className="widget-chart-content">
                                    <div className="widget-numbers text-danger fsize-3">65</div>
                                    <div className="widget-subheading pt-1">Dropped</div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="widget-chart p-0">
                                <div className="widget-chart-content">
                                    <div className="widget-numbers text-success fsize-3">18</div>
                                    <div className="widget-subheading pt-1">Invalid</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="text-center mt-2 d-block">
                        <button className="mr-2 border-0 btn-transition btn btn-outline-danger">Escalate Issue</button>
                        <button className="border-0 btn-transition btn btn-outline-success">Support Center</button>
                    </div>
                </div>                            

                        </div>

                    </div>
                </div>
            </div>
            <div className={ _classOverlay } onClick={ _onClose }></div>
        </Fragment>
    );
});

SideDrawer.displayName = "SideDrawer";

export default SideDrawer;


//<div className="notifications-box">
//    <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
//
//    </div>
//</div>
