import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Verify24Hour } from '../../lib/utils/verify24hour';
import { getAuthInfo, getModuleSelected } from '../../lib/storage';
import Login from "./login"; 


const LoginContainer = (props) => {
  const { isAuthenticated, date, perfil } = getAuthInfo();
  const { selectedUrl } = getModuleSelected();

  // if (isAuthenticated === false) {
  //   //if (!Verify24Hour(date)) {
  //     return <Redirect to={"/"} />;      
  //   //}
  // }

  return (
    <Fragment>
      <Login />
    </Fragment>
  );
};

LoginContainer.displayName = "LoginContainer";

export default LoginContainer;