import React from "react";
import PropTypes from "prop-types";

import { Route, Redirect } from "react-router";
import { getAuthInfo } from "../lib/storage";

const PrivateRoute = ({ component: Component,
                        ...rest
                      }) => { 
                        const { isAuthenticated, token } = getAuthInfo();
                        return (<Route {...rest} render = { props => <Component {...props} />
                                                  }
                        />)
                      };

PrivateRoute.propTypes = {
};

export default PrivateRoute;

// Para habilitar la seguridad
// return (<Route {...rest} render = { props => !isAuthenticated && !token ? 
//   <Redirect to="/" /> : 
//   <Component {...props} />
// }
// />)
