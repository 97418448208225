import axios from 'axios';
import { getAuthInfo } from '../storage';

const headersToken = () => {
    const { token } = getAuthInfo();
    const headersToken = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return headersToken;
};
const instance = (server, port) => { 
    return (
        axios.create({
            baseURL: server + port,
            timeout: 0
        })
    );
};
export const Instance = instance;

const _put = (server, url, data, funcOk, funcError, funcAlways) => {
    server.put(url, data, headersToken())
    .then( (response) => {
        if (funcOk) {
            funcOk(response.data);
        }
    })
    .catch( (error) => {
        if (funcError) {
            funcError(error.message);
        }
    })
    .then(() => {
        if (funcAlways){
            funcAlways();
        }
    });
};
export const Put = _put;

const _post = (server, url, data, funcOk, funcError, funcAlways) => {
    server.post(url, data, headersToken())
    .then( (response) => {
        if (funcOk) {
            funcOk(response.data);
        }
    })
    .catch( (error) => {
        if (funcError) {
            funcError(error.message);
        }
    })
    .then(() => {
        if (funcAlways){
            funcAlways();
        }
    });
};
export const Post = _post;

const _get = (server, url, data, funcOk, funcError, funcAlways) => {
    server.get(url, headersToken())
    .then(function (response) {
        if (funcOk) {
            funcOk(response.data);
        }
    })
    .catch(function (error) {
        if (funcError) {
            let _msg = "";
            if (error.response.data){
                _msg = error.response.data.errorCode + " - " + error.response.data.errorMessage;
            } else {
                _msg = error.message;
            }

            funcError(_msg, error);
        }
    })
    .then(() => {
        if (funcAlways){
            funcAlways();
        }
    });
};
export const Get = _get;