import React from "react";
import PropTypes from "prop-types";

const DropdownItem = props => {
    const { onClick, text, id} = props;
    const _onClick = () => {
        if (onClick) {
            onClick(id, text);
        }
    };
    return (
        <button type="button" 
                tabIndex="0" 
                className="dropdown-item" 
                onClick={ _onClick }>
                    { props.text }
        </button>
    );
};


DropdownItem.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.string, 
    text: PropTypes.string
};

DropdownItem.defaultProps = {
    onClick: () => { console.log("onClick no definido."); },
    id: "", 
    text: ""
};

DropdownItem.displayName = "DropdownItem";

export default DropdownItem;