import React from "react";

const HeaderContainer = (props) => {
    return (
        <div className="app-header header-shadow bg-night-sky header-text-light">
            { props.children }
        </div>
    );
};

HeaderContainer.displayName = "HeaderContainer";

export default HeaderContainer;
