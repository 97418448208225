const btnsquare = props => {
    return props.square? 'btn-square': "";
}
export const BtnSquare = btnsquare

const btntype = (props) => {
    return ( (props.noshine? '': 'btn-hover-shine') + (props.noshadow? '': ' btn-shadow') );
}
export const BtnType = btntype

const btnstyle = (props) => {
    return 'btn ' + (props.primary? 'btn-primary': 
                    (props.secondary? 'btn-secondary': 
                    (props.success? 'btn-success': 
                    (props.info? 'btn-info': 
                    (props.warning? 'btn-warning': 
                    (props.danger? 'btn-danger': 
                    (props.focus? 'btn-focus': 
                    (props.alternate? 'btn-alternate': 
                    (props.light? 'btn-light': 
                    (props.dark? 'btn-dark': 
                    (props.link? 'btn-link': 'btn-info')))))))))));
}
export const BtnStyle = btnstyle

const btnsize = (props) => {
    return ((props.lg) ? 'btn-lg' : 
           ((props.sm)? 'btn-sm' : ''));
}
export const BtnSize = btnsize

const btndisabled = (props) => {
    return ( props.disabled? 'disabled': '' );
}
export const BtnDisabled = btndisabled

const btnsetting = (props) => {
    return ( props.addClass + " " + btntype(props) + " " + btnstyle(props) + " " + btnsize(props) + " " + btndisabled(props) + " " + btnsquare(props) );
}
export const BtnSetting = btnsetting
