
import React from "react";
import PropTypes from "prop-types";
import { useState, useEffect, Fragment } from "react";

const PanelToolbar = props => {
    const { children } = props;

    return ( 
        <Fragment>
            <div className="app-inner-layout__top-pane mt-2 mb-2">
                { children }
            </div>
            {/* <div className="divider"></div> */}
        </Fragment>
    );
};

PanelToolbar.displayName = "PanelToolbar";

export default PanelToolbar;