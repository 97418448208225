export const MenuModuloParticipe = [
    // {
    //     icon: 'pe-7s-home',
    //     label: 'Inicio',
    //     to: '/paraut/dashboard',
    // },    
    {
        icon: 'pe-7s-portfolio',
        label: 'Canasta',
        content: [
            {
                label: 'Ver Canasta',
                to: '/paraut/canasta',
            }
       ]
    },
    {
        icon: 'pe-7s-note2',
        label: 'Órdenes',
        content: [
            {
                label: 'Bandeja de Órdenes',
                to: '/paraut/orden',
            },
        ],
    },
    {
        icon: 'pe-7s-note2',
        label: 'Contacto',
        content: [
            {
                label: 'Datos de Contacto',
                to: '/paraut/contacto',
            },
        ],
    },
];
