import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";

const Dashboard = (props) => {

    return <Redirect to={"/paraut/orden"} />;

    // return (
    //     <Fragment>
    //         <h4>Dashboard</h4>
    //     </Fragment>
    // );
};

Dashboard.displayName = "Dashboard";

export default Dashboard;

