const _actionTypes = {
    SEARCH  : 'SEARCH_EMPRESA',   //Search button
    LIST    : 'LIST_EMPRESA',     //load list
    ADD     : 'ADD_EMPRESA',      //button add
    EDIT    : 'EDIT_EMPRESA',     //button edit
    DELETE  : 'DELETE_EMPRESA',   //button delete
    INSERTDB: 'INSERT_EMPRESA',   //insert db
    UPDATEDB: 'UPDATE_EMPRESA',   //update db
    TABLES  : 'TABLES_EMPRESA',   //load configuration tables
};
export const ActionTypes = _actionTypes;
