import TrxEtf from '../../../lib/axios/etf'; 
//i0mport TrxCommon    from '../../../../../lib/axios/common'; 
//import Combos from '../editar/combos';

const endPoint = {
    ORDENES_POR_FILTRO  : 'api/orden/filter',      // PUT
    ORDENES_POR_EMPRESA : 'api/orden/codemp/',     // GET
    CREAR_ORDEN         : 'api/orden',             // POST
    CANASTA_POR_ID      : 'api/canasta/id/',       // get
    CANASTATITULO_POR_ID: 'api/canasta/titulos/',  // get
    ACTUALIZAR_ESTADO   : 'api/orden/est/',        // PUT
    EMPRESAS            : 'api/empresa',           //GET
    USUARIOS            : 'api/usuario',           //GET
    USUARIO_POR_ID      : 'api/usuario/id/',       //GET
    CREAR_USUARIO       : 'api/usuario',           // POST
    EDITA_USUARIO       : 'api/usuario/id/',       // PUT
    PERFILWEB           : 'api/perfilweb',         //GET
    EMPRESA_POR_ID      : 'api/empresa/id/',       //GET
    CREAR_EMPRESA       : 'api/empresa',           // POST
    EDITA_EMPRESA       : 'api/empresa/id/',       // PUT
    CONTACTO_POR_ID     : 'api/contacto/id/',      //GET
    EDITA_CONTACTO      : 'api/contacto/id/',      // PUT
    CANASTA_CARGALOCAL  : 'api/canasta/cargacanastalocal',  //POST
};

const apiEtf = {

    //reemplazado por getFilterAllTables()
    getByFilter : (filtro, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Put( endPoint.ORDENES_POR_FILTRO, filtro, 
            storefunc, errorfunc, alwaysfunc);
    },

    getByEmp : (CodEmpresa, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Get( endPoint.ORDENES_POR_EMPRESA + CodEmpresa, null, 
            storefunc, errorfunc, alwaysfunc);
    },

    saveOrder : (orden, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Post( endPoint.CREAR_ORDEN, orden, 
            storefunc, errorfunc, alwaysfunc);
    },

    updateOrder : (id, orden, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Put( endPoint.ACTUALIZAR_ESTADO + id, orden, 
            storefunc, errorfunc, alwaysfunc);
    },

    getCanastaById : (CodCanasta, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Get( endPoint.CANASTA_POR_ID + CodCanasta, null, 
            storefunc, errorfunc, alwaysfunc);
    },

    getFilterAllTables : (filtro, storefunc, errorfunc, alwaysfunc) => {
        const promises = [
            TrxEtf.PromisePut( endPoint.ORDENES_POR_FILTRO, filtro, "ordenes").catch( e => console.log(e) ),
            TrxEtf.PromiseGet( endPoint.EMPRESAS, null, "empresas").catch( e => console.log(e) )
        ];

        Promise.all(promises)
        .then(storefunc)   //result ok
        .catch(errorfunc)  //error
        .then(alwaysfunc); //always
    },

    getCanastaTitulo : (CodCanasta, storefunc, errorfunc, alwaysfunc) => {
        const promises = [
            TrxEtf.PromiseGet(endPoint.CANASTA_POR_ID + CodCanasta, null, "canasta").catch( e => console.log(e) ),
            TrxEtf.PromiseGet(endPoint.CANASTATITULO_POR_ID + CodCanasta, null, "canastatitulos").catch( e => console.log(e) )
        ];

        Promise.all(promises)
        .then(storefunc)   //result ok
        .catch(errorfunc)  //error
        .then(alwaysfunc); //always
    },

    getAllEmpresa : (storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Get( endPoint.EMPRESAS, null, 
            storefunc, errorfunc, alwaysfunc);
    },

    getAllUsuarios : (storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Get( endPoint.USUARIOS, null, 
            storefunc, errorfunc, alwaysfunc);
    },

    getUsuarioById :  (Codusuario, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Get( endPoint.USUARIO_POR_ID + Codusuario, null, 
            storefunc, errorfunc, alwaysfunc);
    },
    
    getUsuarioEditor : (Codusuario, storefunc, errorfunc, alwaysfunc) => {
        const promises = [
            TrxEtf.PromiseGet(endPoint.USUARIO_POR_ID + Codusuario, null, "usuario").catch( e => {console.log(e)} ),
            TrxEtf.PromiseGet(endPoint.EMPRESAS, null, "empresas").catch( e => console.log(e) )
        ];

        Promise.all(promises)
        .then(storefunc)   //result ok
        .catch(errorfunc)  //error
        .then(alwaysfunc); //always

    },

    addUsuario : (usuario, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Post( endPoint.CREAR_USUARIO, usuario, 
            storefunc, errorfunc, alwaysfunc);
    },

    editUsuario : (id, usuario, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Put( endPoint.EDITA_USUARIO + id, usuario, 
            storefunc, errorfunc, alwaysfunc);
    },

    getAllPerfilWeb : (storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Get( endPoint.PERFILWEB, null, 
            storefunc, errorfunc, alwaysfunc);
    },

    getEmpresaById :  (CodEmpresa, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Get( endPoint.EMPRESA_POR_ID + CodEmpresa, null, 
            storefunc, errorfunc, alwaysfunc);
    },

    getEmpresaEditor : (CodEmpresa, storefunc, errorfunc, alwaysfunc) => {
        const promises = [
            TrxEtf.PromiseGet(endPoint.EMPRESA_POR_ID + CodEmpresa, null, "empresa").catch( e => console.log(e) ),
            TrxEtf.PromiseGet(endPoint.PERFILWEB, null, "Perfilweb").catch( e => console.log(e) )
        ];

        Promise.all(promises)
        .then(storefunc)   //result ok
        .catch(errorfunc)  //error
        .then(alwaysfunc); //always

    },

    addEmpresa : (empresa, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Post( endPoint.CREAR_EMPRESA, empresa, 
            storefunc, errorfunc, alwaysfunc);
    },

    editEmpresa : (id, empresa, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Put( endPoint.EDITA_EMPRESA + id, empresa, 
            storefunc, errorfunc, alwaysfunc);
    },

    getContactoById :  (CodContacto, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Get( endPoint.CONTACTO_POR_ID + CodContacto, null, 
            storefunc, errorfunc, alwaysfunc);
    },

    editContacto : (id, contacto, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Put( endPoint.EDITA_CONTACTO + id, contacto, 
            storefunc, errorfunc, alwaysfunc);
    },

    cargaWeb : (canastaInfo, storefunc, errorfunc, alwaysfunc) => {
        TrxEtf.Post( endPoint.CANASTA_CARGALOCAL, canastaInfo, 
            storefunc, errorfunc, alwaysfunc);
    },

    // getAllEdit: (id, storefunc, errorfunc, alwaysfunc) => {
    //     const promises = Combos.map( (table) => {
    //         return TrxCommon.PromiseGet( endPoint.TABLAS_COMBO + table, null, table).catch( e => console.log(e) );
    //     });

    //     if (id) {
    //         promises.push(TrxParticipe.PromisePost( endPoint.INVERSIONISTA,{
    //             CodUnico : id
    //         }).catch( e => console.log(e) ));
    //     }

    //     Promise.all(promises)
    //     .then(storefunc)   //result ok
    //     .catch(errorfunc)  //error
    //     .then(alwaysfunc); //always
    // }

};

export default apiEtf;