import moment from 'moment';
import { FORMAT_FULLDATE } from '../../../src/config';
import { Get, Set } from './local';

const AUTH_IS_AUTH = "isAuth";
const AUTH_TOKEN   = "token";
const AUTH_DATE = "fecha_auth";
const AUTH_LASTDATE = "fecha_lastauth";
const AUTH_LASTDATECHANGE = "fecha_lastchange";
const AUTH_NUMBER_WRONG = "number_trywrong";
const AUTH_IDUSER = "iduser";
const AUTH_USER = "user";
const AUTH_NAME_USER = "name_user";
const SELECTED_MODULE = "selectedModule";
const SELECTED_URL = "selectedUrl";
const LIST_ALL_FONDO = "listAllFondo";
const SELECTED_FONDO_NAME = "selectedFondoName";
const SELECTED_FONDO_ID = "selectedFondoId";
const AUTH_PERFIL = "perfil";
const AUTH_CORREO = "correo";
const AUTH_PERFILWEB = "perfilweb";
const AUTH_CODEMPRESA = "codempresa";
const AUTH_NOMEMPRESA = "nomempresa";

/*
* Información para AUTENTICACIÓN.
*/
const _getAuthInfo = () => {
    return ({ 
                isAuthenticated: (Get(AUTH_IS_AUTH) === "1")? true: false,
                token: Get(AUTH_TOKEN),
                date: Get(AUTH_DATE),
                lastdate: Get(AUTH_LASTDATE),
                lastdate_change: Get(AUTH_LASTDATECHANGE),
                number_wrong: Get(AUTH_NUMBER_WRONG),
                iduser: Get(AUTH_IDUSER),
                user: Get(AUTH_USER),
                nameUser: Get(AUTH_NAME_USER),
                correo: Get(AUTH_CORREO),
                perfil: Get(AUTH_PERFIL),
                perfil_web: Get(AUTH_PERFILWEB),
                codempresa: Get(AUTH_CODEMPRESA),
                nomempresa: Get(AUTH_NOMEMPRESA)
            });
}
export const getAuthInfo = _getAuthInfo;

const _setAuthInfo = (iduser, user, nameuser, isauth, token, lastdate, lastchange, numberwrong, perfil,
                      correo, perfilweb, codempresa, nomempresa) => {
    const _date = moment().format(FORMAT_FULLDATE);
    //_date.slice(0,10) get date
    //_date.slice(11,19) get hours
    Set(AUTH_IS_AUTH, isauth? "1": "0");
    Set(AUTH_TOKEN, token); 
    Set(AUTH_DATE, _date); 
    Set(AUTH_LASTDATE, lastdate); 
    Set(AUTH_LASTDATECHANGE, lastchange); 
    Set(AUTH_NUMBER_WRONG, numberwrong); 
    Set(AUTH_IDUSER, iduser);
    Set(AUTH_USER, user); 
    Set(AUTH_NAME_USER, nameuser); 
    Set(AUTH_CORREO, correo);
    Set(AUTH_PERFIL, perfil);
    Set(AUTH_PERFILWEB, perfilweb);
    Set(AUTH_CODEMPRESA, codempresa);
    Set(AUTH_NOMEMPRESA, nomempresa);
}
export const setAuthInfo = _setAuthInfo;

/*
* Información de Módulo seleccionadO
*/
const _getModuleSelected = () => {
    return ({ 
                selectedModule: Get(SELECTED_MODULE),
                selectedUrl: Get(SELECTED_URL)
            });
}
export const getModuleSelected = _getModuleSelected;

const _setModuleSelected = (moduleName, url) => {
    Set(SELECTED_MODULE, moduleName);
    Set(SELECTED_URL, url);
}
export const setModuleSelected = _setModuleSelected;

/*
* Información de Fondo Seleccionado
*/
const _getFondoSelected = () => {
    return ({ 
                idFondo: Get(SELECTED_FONDO_ID),
                nameFondo : Get(SELECTED_FONDO_NAME)
            });
}
export const getFondoSelected = _getFondoSelected;

const _setFondoSelected = (id, name) => {
    Set(SELECTED_FONDO_NAME, name);
    Set(SELECTED_FONDO_ID, id);
}
export const setFondoSelected = _setFondoSelected;

/*
* Lista todos los fondos para seleccionar
*/
const _getAllFondos = () => {
    return ( JSON.parse(Get(LIST_ALL_FONDO)) );
}
export const getAllFondos = _getAllFondos;

const _setAllFondos = (list) => {
    Set(LIST_ALL_FONDO, JSON.stringify(list));
}
export const setAllFondos = _setAllFondos;
