import React from "react";
import { Col, Row, Form } from 'reactstrap';

const RowCol = (props) => {
    const { children, divider } = props;
    if (!children) {
        return <Row form></Row>;
    };

    if (!Array.isArray(children)) {
        return <Row form>{children}</Row>;
    };

    const [left, right] = props.children;

    const leftProp = {
        className: divider? "pr-2": null,
        md: 6
    };
    const rightProp = {
        className: divider? "pl-2": null,
        md: 6,
        style: divider? {borderLeft: "1px solid #e9ecef"}: null
    };

    return (
        <Row form>
            <Col  {...leftProp}>
                { left }
            </Col>
            <Col {...rightProp}>
                { right }
            </Col>
        </Row>
    );
};

RowCol.displayName = "RowCol";

export default RowCol;