import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useState, useLayoutEffect, useRef, Fragment } from "react";

const getSubMenu = ({label, content}) => {
    const _menu = content.map( (item, index) => {
        return ( item.content ? <li key={index}>
                                    <a className="dropdown-item dropdown-toggle" href="#">{ label }</a>
                                    { getSubMenu(item) }
                                </li>: 
                                <li key={index}>
                                    <Link className="dropdown-item" to={ item.to }>{ item.label }</Link>
                                </li> );
    });
    return (
        <ul className="dropdown-menu">
            { _menu }
        </ul>
    );
};

const getButton = () => {
    return (
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHover" aria-controls="navbarDD" aria-expanded="false" aria-label="Navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
    );
};

const HorizontalMenu = (props) => {
    const {title, home, menu} = props;

    //render Horizontal Bar
    const _menu = menu.map( (item, index) => {
        if (item.content) {
            //render Menu
            return (
                <li className="nav-item dropdown" key={index}>
                    <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        { item.label }
                    </a>
                    { getSubMenu(item) }
                </li>                
            );
        } else {
            //render item
            return (
                <li className="nav-item " key={index}>
                    <Link className="nav-link" to={ item.to }>{ item.label }</Link>
                </li>                
            );
        }
    });

    return (
            <nav className="navbar navbar-expand-md shadow-sm navbar-light bg-white navbar-hover sticky-top">
                <Link className="navbar-brand text-primary" to={ home }>{ title }</Link>
                { getButton() }
                <div className="collapse navbar-collapse" id="navbarHover">
                    <ul className="navbar-nav">
                        { _menu }
                    </ul>
                </div>
            </nav>
        );
};

HorizontalMenu.displayName = "HorizontalMenu";

export default HorizontalMenu;