import React, {useState, useEffect} from "react";
import LoadingOverlay from 'react-loading-overlay';
import { FloatMessage } from '../panel/panelmessage';
import LoadingStyle from '../panel/loadingstyle';
import PropTypes from "prop-types";

const PageContent = props => {
    const { pageName, children, loading, iserror, message } = props;

    const _message = "";
    if (message) {
        FloatMessage(iserror, message);
    }

    return ( 
    <div className="app-inner-layout__content card">
        <div className="app-inner-layout__top-pane d-lg-none d-xl-none">
            <div className="pane-left">    
                <h4 className="mb-0">{pageName}</h4>
            </div>
        </div>
        <LoadingOverlay active={loading}
                        spinner
                        styles={LoadingStyle}>
            { _message }
            { children }
        </LoadingOverlay>
    </div>
    );
};

PageContent.displayName = "PageContent";

export default PageContent;