import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router";
import { LoginContainer, Dialog, Header, Footer } from './logincontainer';
import Action   from '../../lib/flux/actions/actionlogin';
import Store from './flux/store';

const getStateFromStore = () => ({
      Authenticated : Store.getAuthenticated(),
             PerfilWeb : Store.getPerfilWeb(), 
             result : Store.getResult(),
            loading : Store.getLoading()
});

const Login = () => {
    const [state, setState] = useState( getStateFromStore() );
    const [changePwd, setChangePwd] = useState(false);
    const _username = useRef();
    const _password = useRef();

    useEffect(() => {
        Store.addChangeListener( _onStoreChange );
 
         return () => {
             Store.removeChangeListener( _onStoreChange ) ;
         } 
     }, []);    

    const _onStoreChange = () => {
        setState( getStateFromStore() );
    };

    const _onClickChangePwd = () => {
        Action.clear();
        setChangePwd(true);
    };

    const _onClickLogin = (e) => {
        Action.search();
        let dataLogin = {
            username: _username.current.value,
            password: _password.current.value
        }
        Action.login(dataLogin);
    };

    if (state.Authenticated) {
        if (state.PerfilWeb === 'PA'){
            return <Redirect to={'/paraut/orden'} />;
        }
        if (state.PerfilWeb === 'ED') {
            return <Redirect to={"/gestor/orden"} />;
        }
    };

    if (changePwd) {
        return <Redirect to={"/changepwd"} />;
    }

    return (  
        <LoginContainer>
                <Dialog loading={state.loading}
                        iserror={state.result.iserror}
                        message={state.result.message}>
                    <div className="modal-body mx-4">
                        <Header></Header>

                        <div className="form-row pt-3">
                            <div className="col-md-12">
                                <div className="position-relative form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="btn btn-light"><i className="fa fa-user"></i></span>
                                        </div>
                                        <input ref={_username} name="user" id="user" placeholder="usuario..." type="text" 
                                            className="form-control" maxLength="15" autoComplete="off"></input>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="position-relative form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="btn btn-light"><i className="fa fa-lock"></i></span>
                                        </div>
                                        <input ref={_password} name="password" id="password" placeholder="contraseña..." type="password" 
                                            className="form-control" maxLength="15" autoComplete="off"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="divider row"></div>
                        <div className="position-relative form-check">
                            {/* <input name="check" id="check" type="checkbox" className="form-check-input"></input>
                            <label className="form-check-label"><small>Mantenerme conectado</small></label> */}
                        </div>
                        
                    </div>
                    <Footer onClickChangePwd={ _onClickChangePwd } 
                            onClickLogin={ _onClickLogin }>
                    </Footer>
                </Dialog>

        </LoginContainer>
  );
};

export default Login;