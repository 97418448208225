import React, { Fragment, useState, useEffect, useRef } from "react";
import { PageHeader, PageContent, PanelToolbar, NavLinkAdd, NavLink,
         PanelBodyList, Search, Button, DataTable, Confirm} from '../../../../../lib/controls';
import { Redirect } from "react-router-dom";
import Action   from '../../../../../lib/flux/actions/actioncontacto';
import DataStore from '../../../flux/storecontacto';
import { getAuthInfo } from '../../../../../lib/storage';
import moment from 'moment';

import { Col, Row, Form } from 'reactstrap';
import { EditInput, ValidateBlock, SelectInput, RowCol } from '../../../../../lib/controls';
// Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const getStateFromStore = () => ({
       SelectedItem : DataStore.getSelectedItem(),
             Tables : DataStore.getTables(),
             result : DataStore.getResult(),
            loading : DataStore.getLoading(),
               exit : DataStore.getExit()
});

const ContactoEditor = (props) => {
    const [store, setStore] = useState( getStateFromStore() );
    const [confirm, setConfirm] = useState(false);
    const { location } = props;
    const { codempresa, nomempresa, nameUser, iduser, correo } = getAuthInfo();

    const _refDescripEmpresa = useRef();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
      };

    //Suscribe evento luego de montar todos los componentes.
    useEffect(() => {
       DataStore.addChangeListener( _onStoreChange );
        return () => {
            DataStore.clear();
            DataStore.removeChangeListener( _onStoreChange ) ;
        } 
    }, []);

    //Carga data luego de montar todos los componentes.
    useEffect(() => {
        _onLoadEdit("00001");
        //_refDescripEmpresa.current.setFocus();
    }, []);    

    //Luego de montar y actualizar el componente, resetea mensaje sólo si estos cambian.
    useEffect(() => {
        store.result.iserror = false;
        store.result.message = null;
    }, [store.result.message, store.result.iserror]);

    const _onStoreChange = () => {
        setStore( getStateFromStore() );
    };

    const _onLoadEdit = (id) => {
        Action.search();
        if (id) {
            Action.edit(id);
        }
    };

    const enviarContacto = () => {
        setConfirm(true);
    };

    const onClickCancel = () => {
        //setConfirm(false);
    };

    const onClickOk = () => {
        //Registrar la Orden
        //...
        const _newdescrip = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const _contacto = {
            CodContacto: "00001",
            DescripContacto: _newdescrip,
        };

        _contacto.UsuarioEdicion = iduser;
        Action.update(_contacto, "00001");
    };

    const onClosed = () => {
        setConfirm(false);
    };

    if (store.exit) {
        return <Redirect to={"/gestor/orden"} />;
    }

    if (store.SelectedItem) {
        console.log("store.SelectedItem.descripContacto",store.SelectedItem.descripContacto);
        const contentBlock = htmlToDraft(store.SelectedItem.descripContacto);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const _editorState = EditorState.createWithContent(contentState);
        setEditorState(_editorState);
        store.SelectedItem = null;
        //const [editorState, setEditorState] = useState(_editorState);
    }

    const _pageName = "Contactos El Dorado";
    const action = "MODIFICAR";
    const _confirm = confirm? <Confirm title="Confirmar Guardar"
                                       onClickOk={onClickOk} onClosed={onClosed}
                                       onClickCancel={onClickCancel}>
                                    <div>
                                        <div>Confirma guardar la información de Contactos ?</div><br></br>
                                    </div>
                              </Confirm>: null;

    const _toolbar = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
          bold: { className: 'bordered-option-classname' },
          italic: { className: 'bordered-option-classname' },
          underline: { className: 'bordered-option-classname' },
          strikethrough: { className: 'bordered-option-classname' },
          code: { className: 'bordered-option-classname' },
        },
        blockType: {
          className: 'bordered-option-classname',
        },
        fontSize: {
          className: 'bordered-option-classname',
        },
        fontFamily: {
          className: 'bordered-option-classname',
        },
      };    
    return (
        <Fragment>
            <PageHeader title={_pageName} 
                        subtitle=""
                        icon="pe-7s-users"
                        breadcrumb={[
                            {url:'/gestor/dashboard',text:'Inicio', icon:'fa fa-home'},
                            {url:'/gestor/cfg/contacto',text:'Contactos'},
                            {text:'Modificar'}
                        ]}>
                <PageContent pageName= { _pageName }
                             loading={store.loading} 
                             message={store.result.message} 
                             iserror={store.result.iserror}>
                    <PanelToolbar>

                        <div className="pane-left">
                            <div className="ml-2 badge badge-warning animated fadeIn">{ action }</div>
                        </div>
                        <div className="pane-right">
                        </div>

                    </PanelToolbar>
                    <PanelBodyList>
                        <Row>
                            <Col sm="12" md="6" lg="6">
                                {/* <EditInput  id="descripempresa" required
                                        defaultValue={ store.SelectedItem.descripEmpresa }
                                        ref = { _refDescripEmpresa }
                                        >Información de Contacto </EditInput> */}
                                <Editor
                                    //initialEditorState={editorState}
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"                                    
                                    onEditorStateChange={onEditorStateChange}
                                    toolbar={_toolbar}
                                    localization={{
                                        locale: 'es',
                                      }}
                                   />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br></br>
                                <Button icon='fa fa-check' info sm onClick={ enviarContacto }> Guardar</Button>{' '}
                                <NavLink link='/gestor/orden' 
                                             icon='fas fa-times-circle' 
                                             danger sm
                                             data={{ id:null }}> Cancelar</NavLink>

                            </Col>
                        </Row>
                        { _confirm }
                    </PanelBodyList>
                </PageContent>
            </PageHeader>
        </Fragment>
    );
};

ContactoEditor.displayName = "ContactoEditor";

export default ContactoEditor;