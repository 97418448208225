import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import PrivateRoute from "../../routes/PrivateRoute";

import NotFound from "./notfound";

import Participe from "./participe";
import Gestor    from "./gestor";
import Externo   from "./gestor";

const Modulos = (props) => {
    let { path, url } = useRouteMatch();
    return (
        <Fragment>
            <Switch>
                <PrivateRoute path="/paraut"  component={Participe} />
                <PrivateRoute path="/gestor"  component={Gestor} />
                <PrivateRoute path="/gesext"  component={Externo} />
                <PrivateRoute path="*"        component={NotFound} />
            </Switch>            
        </Fragment>
    );
};

Modulos.displayName = "Modulos";

export default Modulos;

