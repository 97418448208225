import {Instance, Post, Put, Get } from './axiosapi';

const template = (Server) =>({
    Post: (url, data, funcOk, funcError, funcalways) => {
        Post(Server, url, data, funcOk, funcError, funcalways);
    },
    Put: (url, data, funcOk, funcError, funcalways) => {
        Put(Server, url, data, funcOk, funcError, funcalways);
    },
    Get: (url, data, funcOk, funcError, funcalways) => {
        Get(Server, url, data, funcOk, funcError, funcalways);
    },
    PromiseGet: (url, data, table) => {
        return new Promise( (resolve, reject) => {
            Get(Server, url, data, 
            (reponse) => {
                reponse["table"] = table;
                resolve(reponse);
            }, (error) =>{
                reject({
                    table: table,
                    error: error
                });
            });    
        });
    },
    PromisePut: (url, data, table) => {
        return new Promise( (resolve, reject) => {
            Put(Server, url, data, 
            (reponse) => {
                reponse["table"] = table;
                resolve(reponse);
            }, (error) =>{
                reject({
                    table: table,
                    error: error
                });
            });    
        });
    },
    PromisePost: (url, data, table) => {
        return new Promise( (resolve, reject) => {
            Post(Server, url, data, 
            (reponse) => {
                reponse["table"] = table;
                resolve(reponse);
            }, (error) =>{
                reject({
                    table: table,
                    error: error
                });
            });    
        });
    }    
});
export const Template = template;

const config = ({SERVER, PORT}) => Instance(SERVER, PORT);
export const Config = config;
