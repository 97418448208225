import TrxETF from '../../../lib/axios/etf'; 

const apiETF = {

    getAuthorization : (usuario, password, storefunc, errorfunc, alwaysfunc) => {
        TrxETF.Post('api/Usuario/validate',{
            Username: usuario,
            PasswordHash: password,
            PasswordSalt: password
        }, storefunc, errorfunc, alwaysfunc);
    },

    setChangePwd : (usuario, password, storefunc, errorfunc, alwaysfunc) => {
        TrxETF.Post('api/Usuario/changepwd',{
            Username: usuario,
            PasswordHash: password,
            PasswordSalt: password
        }, storefunc, errorfunc, alwaysfunc);
    },

};

export default apiETF;