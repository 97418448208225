import AppDispatcher from '../dispatcher';
import { ActionTypes } from "../types/actiontypes_login";

const AppActions = {

  clear: () => {
    AppDispatcher.dispatch({
      type: ActionTypes.CLEAR
    });
  },  

  search: () => {
    AppDispatcher.dispatch({
      type: ActionTypes.SEARCH
    });
  },  

  call: () => {
    AppDispatcher.dispatch({
      type: ActionTypes.CALL
    });
  },  

  list: (dataInfo) => {
    AppDispatcher.dispatch({
      type: ActionTypes.LIST,
      data: dataInfo,
    });
  },  
  
  tables: (dataInfo) => {
    AppDispatcher.dispatch({
      type: ActionTypes.TABLES,
      data: dataInfo,
    });
  },  

  login: (dataInfo) => {
    AppDispatcher.dispatch({
      type: ActionTypes.LOGIN,
      data: dataInfo
    });
  },  

  changepwd: (dataInfo) => {
    AppDispatcher.dispatch({
      type: ActionTypes.CHNGPWD,
      data: dataInfo
    });
  },  

  edit: (pid, pindex) => {
    AppDispatcher.dispatch({
      type: ActionTypes.EDIT,
      id: pid,
      index: pindex
    });
  },

  add: (dataInfo) => {
    AppDispatcher.dispatch({
      type: ActionTypes.ADD,
      data: dataInfo
    });
  },

  insert: (dataInfo) => {
    AppDispatcher.dispatch({
      type: ActionTypes.INSERTDB,
      data: dataInfo
    });
  },

  update: (dataInfo, pid, pindex) => {
    AppDispatcher.dispatch({
      type: ActionTypes.UPDATEDB,
      data: dataInfo,
      id: pid,
      index: pindex
    });
  },

  delete: (pid, pindex) => {
    AppDispatcher.dispatch({
      type: ActionTypes.DELETE,
      id: pid,
      index: pindex
    });
  },

  undelete: (pid, pindex) => {
    AppDispatcher.dispatch({
      type: ActionTypes.UNDELETE,
      id: pid,
      index: pindex
    });
  },

  lock: (pid, pindex) => {
    AppDispatcher.dispatch({
      type: ActionTypes.LOCK,
      id: pid,
      index: pindex
    });
  },

  unlock: (pid, pindex) => {
    AppDispatcher.dispatch({
      type: ActionTypes.UNLOCK,
      id: pid,
      index: pindex
    });
  },

  canasta: (pid) => {
    AppDispatcher.dispatch({
      type: ActionTypes.CANASTA,
      data: pid,
    });
  }

};

export default AppActions;
