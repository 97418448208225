import React, { useEffect, forwardRef, useImperativeHandle, useState, useRef } from "react";
import PropTypes from "prop-types";
import { FormFeedback ,FormText, FormGroup, Label, Input } from 'reactstrap';
import { InitError, IsRequired, SetError } from './validator';

const Options = (options, value, noadditem) => {
    if (!noadditem) {
        options.unshift({"value": '', "text": '[ No Seleccionado ]'});
    };
    return options.map( (item, index) => {
        let _props = {
            key   : index,
            value:  item.value,
//            selected: value === item.value? "selected": null,
        };
        return <option {..._props}>{item.text}</option>;
    });    
};

const SelectInput = forwardRef((props, ref) => {
    const { id, placeholder, sm, lg, texthelp, noadditem, readonly,
            required, defaultValue, options, children, onSelectedChange } = props;
    const _select = useRef();
    const [value, setValue] = useState(defaultValue);
    const [validForm, setValidForm] = useState(InitError());
    const [forceReadOnly, setForceReadOnly] = useState(false);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return _getValueControl();
        },
        setValue: (value) => {
            _setValueControl(value);
        },
        Validate: () => {
            return _onValidate();
        },
        setFocus: () => {
            _select.current.focus();
        },
        clear: () => {
            _clear();
        },
        setReadonly: (force) => {
            _forceReadOnly(force);
        },        
    }));    

    useEffect(() => {
        setValue(defaultValue);
        //Para emular _onChange en la carga inicial
        if ( onSelectedChange ) {
            onSelectedChange(defaultValue, id);
        }
    }, [defaultValue])

    const _forceReadOnly = (force) => {
        setForceReadOnly(force);
    };
    const _setValueControl= (value) => {
        setValue(value);
    };
    const _getValueControl = () => {
        return _select.current.value;
    };

    const _onValidate = () => {
        const _value = _getValueControl();

        if (required) {
            let _res = IsRequired(_value)
            if (_res.isError){
                setValidForm(_res);
                return false;
            }
        }

        setValidForm(InitError());
        return true;        
    };

    const _onBlur = (e) => {
        _onValidate(); 
    };

    const _onChange = (e) => {
        const _value = e.target.value;
        setValue(_value);
        if ( onSelectedChange ) {
            onSelectedChange(_value, id);
        }
    };

    const _clear = () => {
        setValue("");
    };

    let readOnlyProps = {
        disabled : readonly? true: false
    }

    if (forceReadOnly) {
        console.log("force readonly");
        readOnlyProps = {
            disabled : true
        }
    }

    const _options = options.slice();
    const opt = {
        id: id, 
        placeholder: placeholder, 
        bsSize: sm? "sm": (lg? "lg": "sm"), //default sm
        required: required,
        onBlur: _onBlur,
        type: "select",
        name: id,
        invalid: validForm.isError
    };
    
    //console.log("InputSelect: ", id , " defaultValue: " + defaultValue, " value: " + value);

    return (
        <FormGroup>
            <Label for={ id }>{ children }</Label>
            <Input innerRef={ _select } {...opt} {...readOnlyProps} value={value} onChange={ _onChange }>
                { Options( _options, value, noadditem ) }
            </Input>
            {texthelp? <FormText>{texthelp}</FormText>:''}
            {validForm.textError? <FormFeedback invalid="true">{validForm.textError}</FormFeedback>: ''}
        </FormGroup>
    );
});

SelectInput.propTypes = {
    options : PropTypes.array,
    validate: PropTypes.func,
    noadditem: PropTypes.bool,
    onSelectedChange: PropTypes.func,
    readonly: PropTypes.bool
};

SelectInput.defaultProps = {
    options: [],
    validate: null,
    noadditem: false,
    onSelectedChange: null,
    readonly: false
};

SelectInput.displayName = "SelectInput";

export default SelectInput;