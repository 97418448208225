import React from "react";

const Footer = (props) => {
    const { nofooter } = props;
    const _content = nofooter ? "": <div className="app-footer">
                                        <div className="app-footer__inner">
                                            <div className="app-footer-left">
                                                <div className="footer-dots">
                                                </div>
                                            </div>
                                            <div className="app-footer-right">
                                            </div>
                                        </div>
                                    </div>;
    return (
            <div className="app-wrapper-footer">
                { _content }
            </div>
    );
};

Footer.displayName = "Footer";

export default Footer;


