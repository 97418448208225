import React from "react";
import PropTypes from "prop-types";
import { useState, useLayoutEffect, useRef, Fragment } from "react";
import SideDrawer from "./sidedrawer";
import HeaderContainer from "./headercontainer";
import NavLogo from "./navlogo";
import NavMobile from "./navmobile";
import NavHeaderMenu from "./navheadermenu";
import NavHeader from "./navheader";
import SidebarContainer from "./sidebarcontainer";
import Sidebar from "./sidebar";
import MainContainer from "./maincontainer";
import MainOuter from "./mainouter";
import Footer from "./footer";
import { ToastContainer } from 'react-toastify';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from "react-tooltip";
import HorizontalMenu from './horizontalmenu';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
  } from 'reactstrap';

const prb2 = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);    
return (
      <Navbar color="white" light expand="md" sticky="top" className="shadow-sm">
        <NavbarBrand href="#">reactstrap</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/components/">Components</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Options
              </DropdownToggle>
              <DropdownMenu left>
                <DropdownItem>
                  Option 1
                </DropdownItem>
                <DropdownItem>
                  Option 2
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  Reset
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <NavbarText>Simple Text</NavbarText>
        </Collapse>
      </Navbar>
);
};

const prb3 = () => {
    return (
<nav className="navbar navbar-expand-md shadow-sm navbar-light bg-white navbar-hover sticky-top">
    <a className="navbar-brand text-primary" href="#">Partícipes</a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHover" aria-controls="navbarDD" aria-expanded="false" aria-label="Navigation">
        <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarHover">
        <ul className="navbar-nav">
            <li className="nav-item active">
                <a className="nav-link" href="#">Inicio <span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Participación
                </a>
                <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Solicitudes</a></li>
                    <li><a className="dropdown-item" href="#">Transferencias</a></li>
                    <li><a className="dropdown-item" href="#">Estado de Cuenta por Partícipe</a></li>
                </ul>
            </li>
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Mantenimiento
                </a>
                <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Inversionistas</a></li>
                    <li><a className="dropdown-item" href="#">Contratos</a></li>
                    <li><a className="dropdown-item" href="#">Emisión de Contratos por Fondo</a></li>
                    <li><a className="dropdown-item" href="#">Activos - Partícipe</a></li>
                </ul>
            </li>
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Certificados
                </a>
                <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Emisión y Control de Certificados de Participación</a></li>
                    <li><a className="dropdown-item" href="#">Emisión de Certificados de Retención</a></li>
                </ul>
            </li>
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Informes
                </a>
                <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Operaciones LAFT</a></li>
                    <li><a className="dropdown-item" href="#">Control Diario</a></li>
                </ul>
            </li>
        </ul>
    </div>
</nav>
    );
};

const prb = () => {
    return (
<nav className="navbar shadow-sm navbar-expand-lg navbar-light bg-white">
    <button className="navbar-toggler mr-3" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
    </button>
    <a className="navbar-brand" href="#">Navbar</a>

    <div className="ml-auto order-lg-last">
        <ul className="navbar-nav flex-row">
            <li className="nav-item pr-3 pr-lg-0">
                <a className="nav-link" href="#">
                    <i className="fa fa-bell" aria-hidden="true"></i>
                    <span className="badge badge-pill badge-danger">5</span>
                </a>
            </li>
            <li className="nav-item pr-3 pr-lg-0">
                <a className="nav-link" href="#">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <span className="badge badge-pill badge-warning">6</span>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#">
                    <i className="fa fa-telegram" aria-hidden="true"></i>
                    <span className="badge badge-pill badge-primary">8</span>
                </a>
            </li>
        </ul>
    </div>

    <button className="navbar-toggler ml-3" type="button" data-toggle="collapse" data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent2" aria-expanded="false" aria-label="Toggle navigation2">
        <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
                <a className="nav-link" href="#">Left Home <span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#">Left Link 1</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#">Left Link 2</a>
            </li>
        </ul>
    </div>

    <div className="collapse navbar-collapse" id="navbarSupportedContent2">
        <ul className="navbar-nav mr-auto d-lg-none">
            <li className="nav-item active">
                <a className="nav-link" href="#">Right Home <span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#">Right Link 1</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#">Right Link 2</a>
            </li>
        </ul>
    </div>
</nav>                    

    );
}
function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};

const Workspace = (props) => {
    const [width, height] = useWindowSize();;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [navLogoActive, setNavLogoActive] = useState(false);
    const _sideDrawer = useRef();
    const _navHeader = useRef();
    const _Horizontal = true;

    const _onClickNavLogo = (active) => {
        setNavLogoActive(active);    
    }

    const _onClickNavHeader = () => {
        _sideDrawer.current.setOpenDrawer();
    }

    const _onCloseDrawer = () => {
        _navHeader.current.setActiveOff();
    }

    const _onClickNavMobile = (active) => {
        setMobileOpen(active);
    }

    const _onClickNavHeaderMenu = (active) => {
        _navHeader.current.setHeaderContent(active);
    }

    let _resize = false;
    if (width < 1250) {
        _resize = true;
    }

    const _fixed = props.fixed? " fixed-header fixed-sidebar": "";
    let _class = "app-container app-theme-white body-tabs-shadow" + _fixed + 
                 (mobileOpen? " sidebar-mobile-open": "") +
                 (navLogoActive? " closed-sidebar": "") +
                 (_resize? " closed-sidebar-mobile closed-sidebar": "");

    const _footer = !props.prehome ? <Footer nofooter></Footer>: null;
    const _sidebar = !props.prehome && !_Horizontal ? (
                        <SidebarContainer>
                            <NavLogo onClick={ _onClickNavLogo }></NavLogo>
                            <NavMobile onClick={ _onClickNavMobile }></NavMobile>
                            <NavHeaderMenu onClick={ _onClickNavHeaderMenu }></NavHeaderMenu>
                            <Sidebar title={props.titlemenu} menu={props.menu}></Sidebar>
                        </SidebarContainer>
                        ) : null;
    console.log(props.menu);
    return (
        <Fragment>
            <div className={ _class }>
                <ReactTooltip place="top" effect="solid"/>
                <ToastContainer position="bottom-right"
                                autoClose={4000}
                                transition={Flip}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable
                                pauseOnHover>
                </ToastContainer>
                <HeaderContainer>
                    <NavLogo onClick={ _onClickNavLogo }></NavLogo>
                    <NavMobile onClick={ _onClickNavMobile }></NavMobile>
                    <NavHeaderMenu onClick={ _onClickNavHeaderMenu }></NavHeaderMenu>
                    <NavHeader ref={ _navHeader } onClick={ _onClickNavHeader }></NavHeader>
                </HeaderContainer>   
                <div className="app-headersecond">
                    <div className="">
                        <HorizontalMenu title={props.titlemenu} home={'#'} menu={props.menu}></HorizontalMenu>
                        <MainContainer>
                            { _sidebar }
                            <MainOuter>
                                { props.children }
                                { _footer }
                            </MainOuter>
                            
                        </MainContainer>
                    </div>             
                </div>   

            </div>
            <SideDrawer ref={ _sideDrawer } onClose={ _onCloseDrawer }></SideDrawer>
        </Fragment>
    );
};

Workspace.propTypes = {
    prehome  : PropTypes.bool,
    titlemenu: PropTypes.string,
    menu     : PropTypes.array,
    fixed    : PropTypes.bool,
    sidebar  : PropTypes.bool
};

Workspace.defaultProps = {
    prehome  : false,
    titlemenu: "Menu",
    menu     : [],
    fixed    : false,
    sidebar  : false
};

Workspace.displayName = "Workspace";

export default Workspace;