const _actionTypes = {
    SEARCH  : 'SEARCH_LOGIN',   //Search button
    CALL    : 'CALL_LOGIN',   //Call - pre event - activate loading icon
    LOGIN   : 'LOGIN_LOGIN',    //Login
    CHNGPWD : 'CHANGE_PWD',     //Change Password
    ADD     : 'ADD_LOGIN',      //button add
    EDIT    : 'EDIT_LOGIN',     //button edit
    LIST    : 'LIST_LOGIN',     //load list
    TABLES  : 'TABLES_LOGIN',    //load configuration tables
    DELETE  : 'DELETE_LOGIN',   //button delete
    UNDELETE: 'UNDELETE_LOGIN', //button undelete
    LOCK    : 'LOCK_LOGIN',     //button block
    UNLOCK  : 'UNLOCK_LOGIN',   //button unblock
    INSERTDB: 'INSERT_LOGIN',   //insert db
    UPDATEDB: 'UPDATE_LOGIN',    //update db
    CLEAR   : 'CLEAR_LOGIN'
};
export const ActionTypes = _actionTypes;

const _actionText = {
  ADD     : 'AGREGAR',      
  EDIT    : 'MODIFICAR',     
  DELETE  : 'ELIMINAR',   
  UNDELETE: 'RECUPERAR', 
  LOCK    : 'BLOQUEAR',     
  UNLOCK  : 'DESBLOQUEAR',   
};

export const ActionText = _actionText;