let _mlist = {};

_mlist.data = [];

_mlist.init = function() {
    this.data = [];
    return this;
};

_mlist.add = function(id, list) {
    this.data.push({
        id  : id,
        list: list
    });
};

_mlist.get = function(id) {
    var _array = [];

    for (var i = 0; i < this.data.length; i++) {
      if (this.data[i].id === id) {
        _array = this.data[i].list;
        break;
      }
    };
    return _array;
};

export const ArrayMng = _mlist;