import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const GetBreadcrumb = (objectPath) => {
    return objectPath.map( (item, index) => {
        const {url, text, icon} = item;
        const _icon = icon? <i aria-hidden="true" className={icon}></i>: '';
        let _link;
        if (url) {
            _link = <li key={index} className="breadcrumb-item">
                        <Link to={url}>
                            {_icon}{' '}{text}
                        </Link>
                    </li>
        } else {
            _link = <li key={index} className="active breadcrumb-item" aria-current="page">
                        {_icon}{text}
                    </li>
        }
        return  _link;
    });
};

const Breadcrumb = props => {
    const { icon, title,  path} = props;

    return ( 
        <div>
            <div className="page-title-head center-elem">
                <span className="d-inline-block pr-2">
                    <i className={icon + " opacity-6"}></i>
                </span>
                <span className="d-inline-block">{ title }</span>
            </div>     
            <div className="page-title-subheading opacity-10">
                <nav className="" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {GetBreadcrumb(path)}
                    </ol>
                </nav>
            </div>                   
        </div>
    );
};

Breadcrumb.propTypes = {
    icon  : PropTypes.string,
    title : PropTypes.string,
    path  : PropTypes.array
};

Breadcrumb.defaultProps = {
    icon : "lnr-apartment",
    title: "",
    path: [],
};

Breadcrumb.displayName = "Breadcrumb";

export default Breadcrumb;