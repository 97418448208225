import React, { Fragment } from "react";
import MyDataTable, { defaultThemes } from 'react-data-table-component';

const DataTable = (props) => {
    const { columns, data } = props;
    const paginationOptions = { 
        rowsPerPageText: 'Filas por página', 
        rangeSeparatorText: 'de' 
    };

    return (
        <MyDataTable columns={ columns } 
                     data={ data }
                     noDataComponent={<div className="m-3 p-3"><small>No hay registros.</small></div>}
                     highlightOnHover={true}
                     pagination={true} className="shadow"
                     noHeader={true}
                     paginationComponentOptions={paginationOptions}
                     persistTableHead={true}
                     responsive={true}
                     dense={false}>
        </MyDataTable>
    );
};

export default DataTable;