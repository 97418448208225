const LoadingStyle = {
    overlay: (base) => ({
      ...base,
      background: 'rgba(0, 0, 0, 0.3)'
    }),
    spinner: (base) => ({
        ...base,
        width: '50px',
        '& svg circle': {
          stroke: 'rgba(0, 0, 0, 0.8)'
        }
    })
  };

  export default LoadingStyle;