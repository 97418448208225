const _actionTypes = {
    SEARCH  : 'SEARCH',   //Search button
    CALL    : 'CALL',   //Call - pre event - activate loading icon
    LOGIN   : 'LOGIN',    //Login
    ADD     : 'ADD',      //button add
    EDIT    : 'EDIT',     //button edit
    LIST    : 'LIST',     //load list
    TABLES  : 'TABLES',    //load configuration tables
    DELETE  : 'DELETE',   //button delete
    UNDELETE: 'UNDELETE', //button undelete
    LOCK    : 'LOCK',     //button block
    UNLOCK  : 'UNLOCK',   //button unblock
    INSERTDB: 'INSERT',   //insert db
    UPDATEDB: 'UPDATE',    //update db
    CANASTA : 'CANASTA'
};
export const ActionTypes = _actionTypes;

const _actionText = {
  ADD     : 'AGREGAR',      
  EDIT    : 'MODIFICAR',     
  DELETE  : 'ELIMINAR',   
  UNDELETE: 'RECUPERAR', 
  LOCK    : 'BLOQUEAR',     
  UNLOCK  : 'DESBLOQUEAR',   
};

export const ActionText = _actionText;