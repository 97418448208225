import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch, useRouteMatch} from "react-router-dom";
import { Redirect } from "react-router";
import PrivateRoute from "../../../routes/PrivateRoute";
import Workspace from '../../../lib/layout/workspace';

import NotFound from "../notfound";

import Dashboard from "./dashboard";
import Orden from "./orden";
import Rorden from "./rorden";
import Canasta from "./canasta";
import CanastaCarga from "./canasta/carga";
import CfgParticipe from "./configuracion/participes";
import CfgParticipeEdit from "./configuracion/participes/editor";
import CfgUsuario from "./configuracion/usuarios";
import CfgUsuarioEdit from './configuracion/usuarios/editor';
import CfgContacto from './configuracion/contactos';

import { MenuModuloGestor }  from "./menuitems";

const Modulos = (props) => {
    const { path, url } = useRouteMatch();

    return (
        <Fragment>
            <Workspace menu={ MenuModuloGestor } titlemenu="ED">
                <Switch>
                    <Route exact path='/gestor' render = { () => <Redirect to="/gestor/dashboard" /> } />
                    <PrivateRoute exact path='/gestor/dashboard'          component={Dashboard} />
                    <PrivateRoute exact path='/gestor/orden'              component={Orden} />
                    <PrivateRoute exact path='/gestor/rorden'             component={Rorden} />
                    <PrivateRoute exact path='/gestor/canasta'            component={Canasta} />
                    <PrivateRoute exact path='/gestor/canasta/carga'      component={CanastaCarga} />
                    <PrivateRoute exact path='/gestor/cfg/pa'             component={CfgParticipe} />
                    <PrivateRoute exact path='/gestor/cfg/pa/editor'      component={CfgParticipeEdit} />
                    <PrivateRoute exact path='/gestor/cfg/usuario'        component={CfgUsuario} />
                    <PrivateRoute exact path='/gestor/cfg/usuario/editor' component={CfgUsuarioEdit} />
                    <PrivateRoute exact path='/gestor/cfg/notificados'    component={CfgParticipe} />
                    <PrivateRoute exact path='/gestor/cfg/template'       component={CfgParticipe} />
                    <PrivateRoute exact path='/gestor/cfg/contacto'       component={CfgContacto} />

                    <PrivateRoute path="*"                          component={NotFound} />
                </Switch>            

            </Workspace>
        </Fragment>
    );
};

Modulos.displayName = "Modulos";

export default Modulos;
