const initError = () => ({
        isError: false,
        textError: null
});
export const InitError = initError;

const setError = (error, text) => ({
    isError: error,
    textError: text
});
export const SetError = setError;

const isRequired = (value) => {

    if (value===''){
        return setError(true, 'El campo es obligatorio.');
    }

    return initError();
};
export const IsRequired = isRequired;

const isMinLength = (value, len) => {

    if (value.length < len){
        return setError(true, 'Debe ingresar al menos: ' + len + ' caracteres.');
    }

    return initError();
};
export const IsMinLength = isMinLength;

const isMaxLength = (value, len) => {

    if (value.length > len){
        return setError(true, 'Debe ingresar hasta: ' + len + ' caracteres.');
    }

    return initError();
};
export const IsMaxLength = isMaxLength;
