import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const PageSidebarItem = props => {
    const { id, title, name, selectedTab, onSelectedTab, validateTabs, validated} = props;
    const selectedPage = (id === selectedTab);
    const classSelected = " alert-warning font-weight-bold"; //" btn-primary";

    const onClickLink = (e) => {
        if(selectedPage){
            e.preventDefault();
            return;
        }

        if(onSelectedTab){
            console.log("change tab:", id);
            onSelectedTab(id);
        }
    };

    const postIcon = (!validateTabs.validated ? <div className="ml-auto p-1 badge badge-pill badge-danger">
                                                        {validateTabs.numErrors}
                                                </div>: 
                                                (validated? <div className="ml-auto p-1 badge badge-pill badge-success">
                                                             <i className="fa fa-check text-white"></i>
                                                            </div> :''));
    let marginLeft = "1";
    let marginRight = "4";
    let sizeDot = "md";
    let badgeColor = "info";

    if (selectedPage){
        marginLeft = "0";
        marginRight = "3";
        sizeDot = "xl";
        badgeColor = "info";
    };

    return ( 
            <a className={"d-flex align-items-center dropdown-item" + (selectedPage? classSelected: "")}
                onClick={ onClickLink }>
                <div className={"badge ml-" + marginLeft + 
                                " mr-" + marginRight + 
                                " badge-dot badge-dot-" + sizeDot + 
                                " badge-" + badgeColor }>Dark</div>
                { title }
                { postIcon }
            </a>        
    );
};

PageSidebarItem.propTypes = {
    selected: PropTypes.bool,
    color: PropTypes.string,
    url: PropTypes.string,
    title: PropTypes.string,
    validateTabs: PropTypes.object
};

PageSidebarItem.defaultProps = {
    selected: false,
    color: "primary",
    url: "",
    title: "",
    validateTabs: {}
};

PageSidebarItem.displayName = "PageSidebarItem";

export default PageSidebarItem;